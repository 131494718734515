import React, { useEffect, useState } from "react";
import NestedModal from "../../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../../components/buttons/saveContinue/ButtonSave";
import usePostApi from "../../../../../components/api/UsePutApi";
import { useGetApi } from "../../../../../components/api/UseGetApi";
// import ButtonLoc from "../../../../../Components/buttons/IndexAdd/IndexAddBt";
import "./TenantLoc.css";

// const SelectField = ({ options, ...rest }) => (
//   <select {...rest} id={rest.id} style={{ width: "70%" }}>
//     {options.map((option) => (
//       <option key={option.id} value={option.value}>
//         {option.label}
//       </option>
//     ))}
//   </select>
// );

const User = (props) => {
  const [isError, setIsError] = useState(""); // State to track API errors
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [tenantLocData, setTenantLocData] = useState({
    organization_id: "",
    created_by: "",
    location_id: "",
    tenant_id: "",
    tenant_name: "",
    location_name: "",
  });

  // TenantLoc section   -----------------------------------------------

  // get all locations and tenants

  const { getData: locationData } = useGetApi("location/getAll");
  const { getData: tenantData } = useGetApi("tenants/getAll");

  // console.log("LocData", locationData);
  // console.log("error", errors);
  // console.log("isError", isError);
  // console.log("apiGetError", apiGetError);
  // console.log(
  //   "tenantData",
  //   tenantData?.map((item) => item.tenant_name)
  // );
  // console.log("apiGetTenantError", apiGetTenantError);

  // set tenantLocData using options id

  const handleId = (e) => {
    const { name, value } = e.target;
    const idKey = name === "location" ? "location_id" : "tenant_id";
    setTenantLocData((prevTenant) => ({
      ...prevTenant,
      [name]: value,
      [idKey]: e.target.options[e.target.selectedIndex].getAttribute("data-id"),
    }));
  };

  // submit function

  const onSubmit = async (data) => {
    try {
      await tenantLocSubmit();
    } catch (error) {
      console.error("Error deleting data:", error);
    }

    setTenantLocData({
      organization_id: "",
      created_by: "",
      location_id: "",
      tenant_id: "",
      tenant_name: "",
      location_name: "",
    });

    console.log("Data submitted successfully!", data);
  };
  console.log("tenantLocData", tenantLocData);
  // custom hook
  const UserDetails = JSON.parse(localStorage.getItem("user"));
  const { PostData: tenantLocSubmit, error: apiError } = usePostApi(
    "assign_tenants_to_location",
    {
      tenant_id: tenantLocData.tenant_id,
      location_id: tenantLocData.location_id,
      created_by: UserDetails.success.userId,
      organization_id: "2",

      message: "Location Assigned to Tenant Successfully",
      fun: () => props.closeModal(),
    }
  );

  // for error

  useEffect(() => {
    if (apiError) {
      console.log("Error:", apiError.response);
      setIsError(apiError?.response?.data?.message);
    }
  }, [apiError]);

  // console.log("tenantLocData", tenantLocData);
  // console.log("isError", isError);
  // console.log("error", errors);
  console.log("tenantData", tenantData);

  return (
    <div>
      <NestedModal
        modalSize="lg"
        className="location-modal_xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5 id="dropdown-modal-title" style={{ marginLeft: "20px",padding: "15px" }}>
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "TENANT + LOCATION" && (
            <>
              <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    // minHeight: "40vh",
                  }}
                >
                  <select
                    {...register("location", {
                      validate: (value) =>
                        value !== "" || "Location name is required",
                    })}
                    id="zoomed-input"
                    onChange={handleId}
                    name="location"
                    value={tenantLocData?.location || ""}
                  >
                    <option value="" disabled selected>
                      Location Name*
                    </option>
                    {locationData?.map((item) => (
                      <option
                        key={item.id}
                        value={item.location_name}
                        data-id={item.location_id}
                      >
                        {item.location_name}
                      </option>
                    ))}
                  </select>
                  {errors.location && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      {errors.location.message || isError}
                    </p>
                  )}

                  <select
                    {...register("tenantName", {
                      validate: (value) =>
                        value !== "" || "tenant name is required",
                    })}
                    id="zoomed-input"
                    onChange={handleId}
                    name="tenantName"
                    value={tenantLocData?.tenantName || ""}
                  >
                    <option value="" disabled selected>
                      Tenant Name*
                    </option>
                    {tenantData?.map((item) => (
                      <option
                        key={item.id}
                        value={item.tenant_name}
                        data-id={item.id}
                      >
                        {item.tenant_name}
                      </option>
                    ))}
                  </select>
                  {errors.tenantName && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      {errors.tenantName.message}
                    </p>
                  )}
                  {isError && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                      {isError}
                    </p>
                  )}
                </div>
              </form>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "TENANT + LOCATION" && (
                <>
                  <BtnSave
                    form="my-form"
                    name="SAVE"
                    onClickFunction={handleSubmit(onSubmit)}
                  />
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default User;
