import React, { useMemo, useCallback, useState } from "react";
import { Card, Container } from "react-bootstrap";
import redCircle from "../../../assets/images/redCircle.svg";
import warningCircle from "../../../assets/images/warningCircle.svg";
import GreenCircle from "../../../assets/images/greenCircle.svg";
import AllCircle from "../../../assets/images/mixed.svg";
import "./Status.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import HeadingUtils  from "../../../components/utils/headingUtils";

const { statusCardUtils } = HeadingUtils();


const statusData = [
  { image: AllCircle, title: statusCardUtils?.TOTAL, filter: "All" },
  {
    image: redCircle,
    title: statusCardUtils?.URGENT,
    filter: "Needs Urgent Attention",
  },
  {
    image: warningCircle,
    title: statusCardUtils?.ATTENTION,
    filter: "Needs Attention",
  },
  { image: GreenCircle, title: statusCardUtils?.GOOD, filter: "All Good" },
];

const ReadingCards = ({ filterMarkers, markers, style, onMouseDown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // count markers by status
  const markersCount = useMemo(() => {
    return markers?.reduce(
      (acc, { currentStatus }) => {
        const status = currentStatus?.toLowerCase();
        if (status === statusCardUtils?.URGENT.toLowerCase()) acc.urgent += 1;
        else if (status === statusCardUtils?.ATTENTION.toLowerCase())
          acc.attention += 1;
        else if (status === statusCardUtils?.GOOD.toLowerCase()) acc.good += 1;
        acc.all += 1;
        return acc;
      },
      { urgent: 0, attention: 0, good: 0, all: 0 }
    );
  }, [markers]);

  // handle card click
  const handleCardClick = useCallback(
    (filter) => {
      filterMarkers(filter);
    },
    [filterMarkers]
  );

  // toggle dropdown menu
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // render card component
  const renderCard = (data, index) => {
    const { image, title, filter } = data;
    const valueLookup = {
      [statusCardUtils?.URGENT]: markersCount?.urgent,
      [statusCardUtils?.ATTENTION]: markersCount?.attention,
      [statusCardUtils?.GOOD]: markersCount?.good,
      [statusCardUtils?.TOTAL]: markersCount?.all,
    };
    return (
      <Card
        onMouseDown={onMouseDown}
        style={style}
        key={index}
        body
        className={isDropdownOpen ? "statusCard_m1" : "statusCard"}
        onClick={() => handleCardClick(filter)}
      >
        <img src={image} alt="" className="card-circle-status" />
        <h6 className="card-status-title">{title}</h6>
        <h5 className="card-status-value">{`${valueLookup[title]} ${statusCardUtils?.SUB_TOTAL}`}</h5>
      </Card>
    );
  };

  return (
    <Container>
      <div className="statusCard_m1_pro">{statusData.map(renderCard)}</div>

      {isDropdownOpen && (
        <div className="statusCard_m1">{statusData.map(renderCard)}</div>
      )}
      <button className="statusCard_m2_button" onClick={toggleDropdown}>
        <FontAwesomeIcon
          className="text-dark"
          icon={isDropdownOpen ? faChevronUp : faChevronDown}
        />
      </button>
    </Container>
  );
};

export default ReadingCards;
