import React, { useEffect, useMemo, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MainsMain from "./files/section-1-cards/mains/Mains";
import Cycle from "./files/section-1-cards/cycles&soh/CycleMain";
import BessBms from "./files/section-1-cards/bessBms/BessBmsMain";
import Cabinet from "./files/section-1-cards/cabinet/CabinetMain";
import Converters from "./files/section-1-cards/converters/ConverterMain";
import Client from "./files/section-2-cards/client/Client";
import Consumption from "./files/section-2-cards/consumption/Consumption";
import Power from "./files/section-2-cards/power/Power";
import Gauge from "./files/section-2-cards/guage/Guage";
import { useDrag } from "../../contexts/drag/Button";

// Add your component card map here
const componentMap = {
  mains: MainsMain,
  cycles: Cycle,
  bess: BessBms,
  cabinet: Cabinet,
  converters: Converters,
  client: Client,
  consumption: Consumption,
  power: Power,
  gauge: Gauge,
};

// Add your default component here
const DefaultComponent = ({ id }) => {
  const style = {
    // Add your default component styles here
  };

  return <div style={style}>{id}</div>;
};

export function Item({ id, locId }) {
  const Component = useMemo(() => componentMap[id] || DefaultComponent, [id]);

  // passing locId to the component
  return <Component locId={locId} />;
}

// This is the DnD item component for each card
export default function SortableItem({ id, markerId }) {
  const { drag } = useDrag();

  const { attributes, setNodeRef, transform, transition, listeners } =
    useSortable({
      id,
    });

  const itemKey = `component_${id}_data`;

  // Load component order from local storage on initial render
  const [componentData, setComponentData] = useState(() => {
    const initialData = localStorage.getItem(itemKey);
    return initialData ? JSON.parse(initialData) : {};
  });

  // Update component order in local storage whenever it changes
  useEffect(() => {
    if (id && transform) {
      setComponentData((prevData) => ({
        ...prevData,
        [id]: { order: prevData[id]?.order || 0, transform },
      }));
    }
  }, [id, transform]);

  // Update local storage whenever component data changes
  useEffect(() => {
    localStorage.setItem(itemKey, JSON.stringify(componentData));
  }, [itemKey, componentData]);

  const componentIndex = componentData[id]?.order || 0;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    order: componentIndex,
  };

  return (
    // Add your component styles here
    <div ref={setNodeRef} style={style} {...attributes}>
      <div {...(drag ? listeners : {})}>
        <Item id={id} locId={markerId} />
      </div>
    </div>
  );
}
