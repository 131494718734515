import React, { useState, useEffect, useRef } from "react";
import "./Notification.css";
import { ReactComponent as NotificationIcon } from "../../assets/images/notification.svg";
import Warning from "../../assets/images/warning-icon-transparent-free-png.webp";
import TicketIcon from "../../assets/images/ticket.png";
import { Link, useNavigate } from "react-router-dom";
import usePutAPi from "../../components/api/UsePutApi";
// import { BarLoader } from "react-spinners";
import NeoLoader from "../../assets/images/NeoLoader.gif";
import { Badge } from "@mui/material";
import { useGetApi } from "../../components/api/UseGetApi";

const NotificationCard = ({
  mainMsg,
  subMsg,
  time,
  handleCardClick,
  is_read,
  NotificationImage,
}) => (
  <div
    className={
      is_read === 0 ? "notification-card-menu" : "notification-card-menu_unread"
    }
    onClick={handleCardClick}
  >
    <div id="noti-img">
      <img src={NotificationImage} style={{ width: "40px" }} alt="warning" />
    </div>
    <div id="noti-message">
      <h6>{mainMsg}</h6>
      <p>{subMsg}</p>
    </div>
    <div id="noti-time">{time}</div>
  </div>
);

const App = ({ isOpen, setIsOpen }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // console.log("isOpen", isOpen);
  // console.log("open", open);

  const menuRef = useRef();

  const { getData: isData, isLoading: loading } = useGetApi(
    "support/getAllByOrganization/2"
  );

  useEffect(() => {
    setData(isData?.support);
  }, [isData]);

  // console.log("data", data);
  // console.log("data", data);

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth()
    ) {
      // Format the time only
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      // Format the full date
      return date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
    }
  };

  const { PostData: notificationStatus } = usePutAPi(
    "support/updateReadStatus"
  );
  // console.log("apiError", apiError);

  // Function to sort notifications
  const sortNotifications = (data) => {
    return data
      .slice()
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  };

  // Functional component

  useEffect(() => {
    if (data?.length) {
      setSortedData(sortNotifications(data));
    }
  }, [data]);

  // Function to handle click on a notification card
  const handleCardClick = async (item) => {
    try {
      if (item.is_read === 0) {
        await notificationStatus({
          id: item.id,
          showSuccessAlert: "false",
        });
      }
      navigate("/viewAll", { state: { id: item } });
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  return (
    <div className="App">
      <div className="menu-container" ref={menuRef}>
        <div
          className="menu-trigger "
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Badge
            color="error"
            badgeContent={data?.filter((x) => x.is_read === 0).length}
            invisible={data?.filter((x) => x.is_read === 0).length === 0}
          >
            <NotificationIcon
              className="notification-icon"
              // style={{ width: "50px", cursor: "pointer" }}
            />
          </Badge>
        </div>

        <div
          className={`notification-drop-menu ${open ? "active" : "inactive"}`}
        >
          <>
            <header className="notification-header">
              <p >Notifications</p>
            </header>

            <div className="notification-display-container">
              {loading ? (
                <div className="msg-loading">
                  {/* <BarLoader color="#36d7b7" /> */}
                  <img style={{ width: "20%" }} src={NeoLoader} alt="" />
                </div>
              ) : (
                <div>
                  {sortedData.length ? (
                    sortedData.map((item, id) => (
                      <NotificationCard
                        key={id}
                        NotificationImage={
                          item.type === "notification" ? Warning : TicketIcon
                        }
                        mainMsg={
                          item.type === "notification"
                            ? item.status
                            : item.user_name
                        }
                        is_read={item.is_read}
                        subMsg={item.messages}
                        time={formatTimestamp(item?.created_at)}
                        handleCardClick={() => handleCardClick(item)}
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        height: "30vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>No notifications</p>
                    </div>
                  )}
                </div>
              )}
            </div>

            <footer style={{ textAlign: "center", margin: "7px" }}>
              <Link
                to="/viewAll"
                style={{ textDecoration: "none", color: "black" }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  View All
                </p>
              </Link>
            </footer>
          </>
        </div>
      </div>
    </div>
  );
};

export default App;
