import React, { useEffect, useState } from "react";
import ButtonW from "../../../../../components/buttons/indexAdd/IndexAddBt";
import NestedModal from "../../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../../components/buttons/saveContinue/ButtonSave";
import usePostApi from "../../../../../components/api/UsepostApi";
// import ButtonLoc from "../../../../../components/buttons/indexAdd/IndexAddBt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./user.css";
import { numberValidation } from "../../../../../components/validations/utils";
import { characterValidation } from "../../../../../components/validations/utils";

const InputField = ({
  id,
  label,
  register,
  error,
  errorMessage,

  ...rest
}) => (
  <>
    <input
      //   id="zoomed-input-user"

      id={id ? "zoomed-input-user" : ""}
      style={{ width: "70%" }}
      {...register}
      aria-invalid={error ? "true" : "false"}
      {...rest}
      placeholder={`${rest.placeholder}*`}
    />
    {error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {errorMessage}
      </p>
    )}
  </>
);

const User = (props) => {
  const [showAdminInputs, setShowAdminInputs] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [showSiteInputs, setShowSiteInputs] = useState(false);
  const [isError, setIsError] = useState(""); // State to track API errors
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [adminData, setAdminData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    user_type: "admin",
  });
  const [managerData, setManagerData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phone: "",
    user_type: "site_manager",
  });

  // admin part in user section   -----------------------------------------------

  // for admin and sitemanager options
  const handleAdminInputs = () => {
    setShowAdminInputs(true);
    setShowSiteInputs(false);
    setShowButtons(false);
    reset();
    setIsError("");
  };

  const handleSiteInputs = () => {
    setShowAdminInputs(false);
    setShowSiteInputs(true);
    setShowButtons(false);
    reset();
    setIsError("");
  };

  const handleButtons = () => {
    setShowAdminInputs(false);
    setShowSiteInputs(false);
    setShowButtons(true);
    reset();
    setIsError("");
  };

  // input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleManagerChange = (e) => {
    const { name, value } = e.target;
    setManagerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log("managerData", managerData);

  // submit function

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await userSubmit();

      if (success) {
        setAdminData({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          phone: "",
          user_type: "",
        });
        setManagerData({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          phone: "",
          user_type: "",
        });
        reset();
      } else {
        // Adding canceled
        console.log("Data canceled");
      }
    } catch (error) {
      console.error("Error Adding data:", error);
    }
  };

  // custom hook

  const { PostData: userSubmit, error: apiError } = usePostApi("users/create", {
    firstname: adminData.firstname || managerData.firstname,
    lastname: adminData.lastname || managerData.lastname,
    email: adminData.email || managerData.email,
    password: adminData.password || managerData.password,
    phone: adminData.phone || managerData.phone,
    organization_id: "2",
    swal: true,
    // user_type: (adminData.phone || managerData.phone) && adminData.user_type,
    user_type: showSiteInputs === true ? "site manager" : "admin",
    message: "User Added Successfully",
    fun: () => props.closeModal(),
  });

  // for error

  useEffect(() => {
    if (apiError) {
      console.log("Error:", apiError?.response?.data?.error);
      setIsError(apiError?.response?.data?.error);
    }
  }, [apiError]);

  console.log("adminData", adminData);
  console.log("isError", isError);

  return (
    <div>
      <NestedModal
        modalSize="lg"
        className="location-modal_xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5 id="dropdown-modal-title" style={{ marginLeft: "20px",padding: "15px" }}>
              {/* {props.selectedModal.title} */}
              {showButtons
                ? "SELECT USER TYPE"
                : showAdminInputs
                ? "ADD ADMIN"
                : "ADD SITE MANAGER"}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "USER" && (
            <>
              {showButtons && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "45vh",
                  }}
                >
                  <div onClick={handleAdminInputs}>
                    <ButtonW name="ADMIN" />
                  </div>
                  <div onClick={handleSiteInputs}>
                    <ButtonW name=" ADD SITE MANAGER" />
                  </div>
                </div>
              )}
              {showAdminInputs && (
                <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                  <button
                    onClick={handleButtons}
                    className="user-back-btn"
                    style={{ position: "absolute", top: "20px", left: "35px" }}
                  >
                    <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                  </button>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      minHeight: "40vh",
                    }}
                  >
                    <InputField
                      id="firstname"
                      label="First Name"
                      register={register("firstname", {
                        required: "First name is required",
                      })}
                      error={errors.firstname || isError?.firstname}
                      errorMessage={
                        errors.firstname?.message || isError?.firstname
                      }
                      onChange={handleInputChange}
                      placeholder="First Name"
                      value={adminData.firstname}
                      onKeyDown={characterValidation}
                    />
                    <InputField
                      id="lastname"
                      label="Last Name"
                      register={register("lastname", {
                        required: "Last name is required",
                      })}
                      error={errors.lastname || isError?.lastname}
                      errorMessage={
                        errors.lastname?.message || isError?.lastname
                      }
                      onChange={handleInputChange}
                      placeholder="Last Name"
                      value={adminData.lastname}
                      onKeyDown={characterValidation}
                    />
                    <InputField
                      id="email"
                      label="Email"
                      register={register("email", {
                        required: "Email Address is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email format",
                        },
                      })}
                      error={errors?.email || isError?.email}
                      errorMessage={errors.email?.message || isError?.email}
                      onChange={handleInputChange}
                      placeholder="Email Id"
                      value={adminData.email}
                    />
                    <InputField
                      id="phone"
                      label="Phone"
                      type="text"
                      maxLength={10}
                      minLength={10}
                      register={register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Phone number must be exactly 10 digits",
                        },
                      })}
                      error={errors.phone || isError.phone}
                      errorMessage={errors.phone?.message || isError?.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      value={adminData.phone}
                      onKeyDown={numberValidation}
                    />

                    <InputField
                      id="password"
                      label="Password"
                      register={register("password", {
                        required: "Password is required",
                      })}
                      error={errors.password || isError?.password}
                      errorMessage={
                        errors.password?.message || isError?.password
                      }
                      onChange={handleInputChange}
                      placeholder="Password"
                      value={adminData.password}
                    />
                  </div>
                </form>
              )}
              {/* site Manager Details */}
              {showSiteInputs && (
                <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                  <button onClick={handleButtons} className="user-back-btn">
                    <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                  </button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      minHeight: "40vh",
                    }}
                  >
                    <InputField
                      id="firstname"
                      label="First Name"
                      register={register("firstname", {
                        required: "First name is required",
                      })}
                      error={errors.firstname || isError?.firstname}
                      errorMessage={
                        errors?.firstname?.message || isError?.firstname
                      }
                      onChange={handleManagerChange}
                      placeholder="First Name"
                      value={managerData.firstname}
                      onKeyDown={characterValidation}
                    />
                    <InputField
                      id="lastname"
                      label="Last Name"
                      register={register("lastname", {
                        required: "Last name is required",
                      })}
                      error={errors.lastname || isError?.lastname}
                      errorMessage={
                        errors.lastname?.message || isError?.lastname
                      }
                      onChange={handleManagerChange}
                      placeholder="Last Name"
                      value={managerData.lastname}
                      onKeyDown={characterValidation}
                    />
                    <InputField
                      id="email"
                      label="Email"
                      register={register("email", {
                        required: "Email Address is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email format",
                        },
                      })}
                      error={errors?.email || isError?.email}
                      errorMessage={errors.email?.message || isError?.email}
                      onChange={handleManagerChange}
                      placeholder="Email Id"
                      value={managerData.email}
                    />
                    <InputField
                      id="phone"
                      label="Phone"
                      type="text"
                      maxLength={10}
                      minLength={10}
                      register={register("phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: "Phone number must be exactly 10 digits",
                        },
                      })}
                      error={errors.phone || isError.phone}
                      errorMessage={errors.phone?.message || isError?.phone}
                      onChange={handleManagerChange}
                      placeholder="Phone Number"
                      value={managerData.phone}
                      onKeyDown={numberValidation}
                    />

                    <InputField
                      id="password"
                      label="Password"
                      register={register("password", {
                        required: "Password is required",
                      })}
                      error={errors.password || isError?.password}
                      errorMessage={
                        errors.password?.message || isError?.password
                      }
                      onChange={handleManagerChange}
                      placeholder="Password"
                      value={managerData.password}
                    />
                  </div>
                </form>
              )}
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "USER" && !showButtons && (
                <>
                  <BtnSave
                    form="my-form"
                    name="SAVE"
                    onClickFunction={handleSubmit(onSubmit)}
                  />
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default User;
