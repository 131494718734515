// multi select component
import React, { useEffect, useState } from "react";
import { useGetApi } from "../api/UseGetApi";
import Select from "react-select";
import "./MultiSelect.css";

const MultiSelect = (props) => {
  const { getData: userData } = useGetApi("users/getAllByOrganization/2");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const UserList =
    userData?.map((item) => ({
      value: item.email,
      label: item.email,
    })) || [];

  // State to store the input text for filtering
  const [inputText, setInputText] = useState("");
  // State to control whether to show options
  const [showOptions, setShowOptions] = useState(false);
  // State to store selected value(s)
  const [selectedValues, setSelectedValues] = useState([]);

  // Function to filter options based on input text
  const filterOptions = (option, rawInput) => {
    return (
      option.label.toLowerCase().includes(rawInput.toLowerCase()) ||
      option.value.toLowerCase().includes(rawInput.toLowerCase())
    );
  };

  // Function to handle input change
  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
    setShowOptions(inputValue.length >= 3 && UserList.length > 0);
  };

  useEffect(() => {
    const isMatching =
      inputText.length >= 3 &&
      UserList.some((option) =>
        option.value.toLowerCase().startsWith(inputText.toLowerCase())
      );
    setShowOptions(isMatching);
  }, [inputText, UserList]);

  // Function to handle selection change
  const handleSelectionChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
    const selectedOptionValues = selectedOptions?.map((option) => option.value);
    props.setTicket({ ...props.ticket, selectedOptionValues });
  };


  return (
    <div>
      <Select
        className="mb-2"
        defaultValue={[UserList[0], UserList[1]]}
        isMulti
        // name="colors"
        {...(props?.register ? props.register : {})}
        {...props}
        options={UserList}
        id="multiSelect_input"
        filterOption={filterOptions}
        onInputChange={handleInputChange}
        inputValue={inputText}
        menuIsOpen={showOptions} // Control whether to show options
        onChange={handleSelectionChange} // Handle selection change
        value={selectedValues} // Set selected value(s)
      />
    </div>
  );
};

export default MultiSelect;
