import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import SearchPage from "../pages/searchPage/Search";
import Billing from "../pages/billing/BillingNew";
import Home from "../pages/home/MainHome";
import NoPage from "../pages/noPage/404";
import HomePage from "../pages/homePage/HomePage";
import ViewAll from "../pages/notification/ViewAll";
import { DragProvider } from "../contexts/drag/Button";
import { DataProvider } from "../contexts/data/Context";
import { collection, onSnapshot } from "firebase/firestore";
import { database } from "../firebase";
import Test from "../Tests/Test";
import Mqtt from "../Tests/Mqtt/mqtt";
// import Cms from "../Tests/cms";
import StrapiCMS from "../Tests/Strapi/StrapiCms";
import StepForm from "../Tests/StepForm";
import Logout from "../routes/Logout";
import TestNew from "../Tests/NewTest";
// import useInactivityLogout from "./useInactivityLogout";
// import Breadcrumbs from "../components/breadCrumbs/BreadCrumbs";

const Router = () => {
  // notification part
  const userDetails = JSON.parse(localStorage.getItem("user"));

  // console.log("userDetails",userDetails);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(database, "messagesAuth"),
      (querySnapshot) => {
        const messagesArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const storedMessages =
          JSON.parse(localStorage.getItem("messages")) || [];
        const newMessages = messagesArray.filter(
          (message) =>
            !storedMessages.find(
              (storedMessage) => storedMessage.id === message.id
            ) && message.sender !== userDetails.success.email
        );

        newMessages.forEach((message) => {
          // Check if Notifications are supported and allowed
          if (
            typeof window !== "undefined" &&
            typeof Notification !== "undefined" &&
            Notification.permission === "granted"
          ) {
            new Notification(`${message.sender} sent a message`, {
              body: message.text,
            });
          } else {
            // Fallback for browsers that don't support Notifications or permission not granted
            // console.log(`${message.sender} sent a message: ${message.text}`);
          }
          storedMessages.push(message);
        });

        localStorage.setItem("messages", JSON.stringify(storedMessages));
      }
    );

    return unsubscribe;
  }, [userDetails.success.email]);

  // logout function

  // const logout = () => {
  //   // Your logout logic here (e.g., clearing user session and redirecting to login page)
  //   console.log("User has been logged out due to inactivity");
  // };

  // useInactivityLogout(logout, 3600000, "loggedIn", 86400000);

  return (
    <>
      {/* <Breadcrumbs /> */}
      <Logout />
      <Routes>
        <Route
          path="/*"
          element={
            <DataProvider>
              <SearchPage />
            </DataProvider>
          }
        />
        <Route
          path="/dashboard"
          element={
            <DataProvider>
              <DragProvider>
                <Home />
              </DragProvider>
            </DataProvider>
          }
        />
        <Route
          path="/viewAll"
          element={
            <DataProvider>
              <ViewAll />
            </DataProvider>
          }
        />
        <Route path="/homePage" element={<HomePage />} />
        <Route path="/billing" element={<Billing />} />
        ,
        <Route path="/test" element={<Test />} />
        {/* <Route path="/cms" element={<Cms />} /> */}
        <Route path="/StrapiCMS" element={<StrapiCMS />} />
        <Route path="/StepForm" element={<StepForm />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/testNew" element={<TestNew />} />
        <Route path="/mqtt" element={<Mqtt />} />
      </Routes>
    </>
  );
};

export default Router;
