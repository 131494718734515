import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import "./Profile.css";
import FetchData from "../../../components/api/getApi";
import useFetchData from "../../../components/api/UsePutApi";
import usePostData from "../../../components/api/UsepostApi";
import usePutData from "../../../components/api/UsePutApi";
import { useForm } from "react-hook-form";
import BtnSave from "../../../components/buttons/saveContinue/ButtonSave";
import EditableInput from "./EditableInput";
import PasswordInput from "./PasswordInput";

const ManagePart = ({ showModal, closeModal }) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [activeButton, setActiveButton] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [isPhotoChanged, setIsPhotoChanged] = useState(false);
  const [isError, setIsError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { register, handleSubmit } = useForm();

  const handleActive = () => {
    setActiveButton((prev) => !prev);
  };

  // fetch data from api
  const fetchDataFromApi = useCallback(async () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));

    try {
      setLoading(true);
      const apiData = await FetchData({
        apiPara: `users/getUserById/${userDetails.success.userId}`,
      });
      setLoading(false);
      setData(apiData);
      setFormData({
        firstname: (apiData && apiData.firstname) || "",
        lastname: (apiData && apiData.lastname) || "",
        phone: (apiData && apiData.phone) || "",
        email: (apiData && apiData.email) || "",
      });
      setPhoto(apiData.image);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  }, []);

// update user details
  useEffect(() => {
    fetchDataFromApi();
  }, [fetchDataFromApi]);

// profile update
  const { firstname, lastname, email, phone } = formData;
  // const userDetails = JSON.parse(localStorage.getItem("user"));
  const userDetailsUrl = `users/updateuserdetails/${userDetails.success.userId}`;
  const updateDetails = {
    firstname: firstname,
    lastname: lastname,
    email,
    phone: phone,
    showSuccessAlert: "false",
  };

  // useFetchData 
  const { error: updateError, PostData: updatePData } = useFetchData(
    userDetailsUrl,
    updateDetails
  );

  // error handling
  useEffect(() => {
    if (updateError) {
      setIsError(updateError?.response?.data?.errors);
    } else {
      setIsError(false);
    }
  }, [updateError]);

  // image posting

  const HandlePhoto = (e) => {
    setPhoto(e.target.files[0]);
    setPhotoUrl(URL?.createObjectURL(e.target.files[0]));
    setIsPhotoChanged(true);
  };

  // usePostData for image
  const { PostData: updateProfile } = usePostData(
    `users/updateuserimage/${userDetails.success.userId}`,
    {
      image: photo,
      message: "Profile photo updated successfully",
      contentType: "multipart/form-data",
      toast: true,
      swal: false,
    }
  );

  // image update
  useEffect(() => {
    if (isPhotoChanged) {
      updateProfile();
      setIsPhotoChanged(false); // Resetting the state after update
    }
  }, [isPhotoChanged, updateProfile]); // Only trigger when isPhotoChanged changes

  // Optionally, you can also reset the selected photo state when needed
  useEffect(() => {
    setPhoto(null);
  }, [userDetails.success.userId]); // Reset selected photo when user ID changes

  //   onSubmit
  const onSubmit = async (data) => {
    try {
      // Call the Profile function from the hook
      const success = await updatePData();

      if (success) {
        setIsError("");
        // const updatedUser = { ...data, image: photoUrl };
        // setData(updatedUser);
      } else {
        // Profile canceled
        console.log("Profile update canceled");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  // password change function
  const {
    PostData: passwordChange,
    error: apiError,
    isLoading: passwordLoading,
    update: passwordUpdate,
  } = usePutData(`users/updatepassword/${userDetails.success.userId}`, {
    password: password.newPassword,
    old_password: password.oldPassword,
    confirm_password: password.confirmPassword,
    showSuccessAlert: "false",
    toast: true,
  });

  // error handling
  useEffect(() => {
    if (apiError) {
      setPassError(apiError?.response?.data);
    } else {
      setPassError(false);
    }
  }, [apiError]);

  //   password submit
  const handlePasswordSubmit = async (data) => {
    try {
      const success = await passwordChange();
      if (success) {
        console.log("Password change successful");
        setPassword({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setPassError("");
      } else {
        setPassword({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setPassError("");
        console.log("Password change failed");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  // update form state
  const handleForm = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


// update password state
  const HandlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // toggle password visibility
  const togglePassword = (field) =>
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });

  console.log("passwordUpdate", passwordUpdate);
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        animation={true}
        dialogClassName="custom-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="drop-down-header"
          id="drop-modal-header"
        >
          <p id="dropdown-modal-title" style={{ color: "white" }}>
            MY PROFILE
          </p>
        </Modal.Header>
        <Modal.Body className="drop-down-body">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#00a79d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="profilePage_card" style={{ color: "white" }}>
                <div className="profilePage_photo_main">
                  <div className="profilePage_photo">
                    <div id="profile-card__avatar_N">
                      <div className="profile-img-wrapper">
                        <img
                          width={"100px"}
                          style={{ borderRadius: "50%" }}
                          // src={ProfileData?.profilePhoto}
                          // src={photo || isPhoto}
                          src={photoUrl ? photoUrl : photo}
                          alt="profile-pic"
                          className="image-cover"
                        />
                        <div className="hover-button-profile">
                          <button className="profile-edit__btn">
                            <label htmlFor="file-input">
                              <FontAwesomeIcon
                                style={{
                                  backgroundColor: "#23b8a9",
                                  padding: "10px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                icon={faPencil}
                                type="file"
                              />
                            </label>
                            <input
                              onChange={HandlePhoto}
                              id="file-input"
                              type="file"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mb-0">
                    {data?.firstname + " " + data?.lastname}
                  </p>
                </div>
                <hr />
                <div className="d-flex justify-content-center align-items-center w-100">
                  <button
                    onClick={handleActive}
                    className={
                      activeButton
                        ? "profile-select_btn"
                        : "profile-select_btn_inactive"
                    }
                  >
                    User Details
                  </button>
                  <button
                    onClick={handleActive}
                    className={
                      activeButton
                        ? "profile-select_btn_inactive"
                        : "profile-select_btn"
                    }
                  >
                    Change Password
                  </button>
                </div>
                {activeButton === true ? (
                  <>
                    <div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <EditableInput
                          label="First Name"
                          initialValue={data?.firstname}
                          setFormData={setFormData}
                          formData={formData.firstname}
                          name="firstname"
                          onSubmit={onSubmit}
                          error={isError?.firstname}
                          defaultValue={formData?.firstname}
                          register={register}
                          errors={isError?.firstname}
                          onChange={handleForm}
                          required
                          pattern={/^[A-Za-z]+$/}
                          placeholder="Enter your First Name"
                          onKeyDown={(e) => {
                            if (/\d/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                        />
                        <EditableInput
                          label="Last Name"
                          initialValue={data?.lastname}
                          setFormData={setFormData}
                          formData={formData.lastname}
                          name="lastname"
                          onSubmit={onSubmit}
                          error={isError?.lastname}
                          defaultValue={formData?.lastname}
                          register={register}
                          errors={isError?.lastname}
                          onChange={handleForm}
                          required
                          pattern={/^[A-Za-z]+$/}
                          placeholder="Enter your First Name"
                          onKeyDown={(e) => {
                            if (/\d/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                        />
                        <EditableInput
                          label="Email"
                          initialValue={data?.email}
                          setFormData={setFormData}
                          formData={formData.email}
                          name="email"
                          onSubmit={onSubmit}
                        />
                        <EditableInput
                          label="Phone"
                          type="text"
                          maxLength="10"
                          initialValue={data?.phone}
                          setFormData={setFormData}
                          formData={formData.phone}
                          name="phone"
                          onSubmit={onSubmit}
                          error={isError?.phone}
                          defaultValue={formData?.phone}
                          register={register}
                          errors={isError?.phone}
                          onChange={handleForm}
                          required
                          pattern={/^[A-Za-z]+$/}
                          placeholder="Enter your Phone Number"
                          onKeyDown={(e) => {
                            const charCode = e.keyCode || e.which;
                            const char = String.fromCharCode(charCode);
                            if (/[a-zA-Z]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </form>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <form onSubmit={handleSubmit(handlePasswordSubmit)}>
                        <p style={{ margin: "0px", position: "relative" }}>
                          {/* <EditableInput
                            label="Old Password"
                            initialValue=""
                            inputRef={oldInputRef}
                            activeButton={activeButton}
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            name="oldPassword"
                            //   value={password?.oldPassword || ""}
                            // defaultValue=""
                            register={register}
                            errors={
                              apiError?.response?.data?.error?.password ||
                              apiError?.response?.data?.message
                            }
                            defaultValue={formData.oldPassword || ""}
                            onChange={HandlePassword}
                            required
                            autoComplete={false}
                            placeholder="Old password"
                            onPaste={(e) => e.preventDefault()}
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value
                                .replace(/<script>/gi, "")
                                .replace(/<\/script>/gi, "");
                            }}
                          /> */}
                          <PasswordInput
                            label="Old Password"
                            value={password.oldPassword}
                            onChange={HandlePasswordChange}
                            name="oldPassword"
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            register={register}
                            error={
                              passError?.error?.old_password ||
                              passError?.message
                            }
                          />
                          <FontAwesomeIcon
                            icon={showPassword.oldPassword ? faEye : faEyeSlash}
                            onClick={() => togglePassword("oldPassword")}
                            className="profile-form-show__icon_New"
                          />
                        </p>
                        <p style={{ margin: "0px", position: "relative" }}>
                          <PasswordInput
                            label="New Password"
                            value={password.newPassword}
                            onChange={HandlePasswordChange}
                            name="newPassword"
                            type={
                              showPassword.newPassword ? "text" : "password"
                            }
                            register={register}
                            error={passError?.error?.password}
                          />
                          <FontAwesomeIcon
                            icon={showPassword.newPassword ? faEye : faEyeSlash}
                            onClick={() => togglePassword("newPassword")}
                            className="profile-form-show__icon_New"
                          />
                        </p>
                        <p style={{ margin: "0px", position: "relative" }}>
                          <PasswordInput
                            label="Confirm Password"
                            value={password.confirmPassword}
                            onChange={HandlePasswordChange}
                            name="confirmPassword"
                            type={
                              showPassword.confirmPassword ? "text" : "password"
                            }
                            register={register}
                            error={passError?.error?.confirm_password}
                          />

                          <FontAwesomeIcon
                            icon={
                              showPassword.confirmPassword ? faEye : faEyeSlash
                            }
                            onClick={() => togglePassword("confirmPassword")}
                            className="profile-form-show__icon_New"
                          />
                        </p>
                        <BtnSave
                          style={{ float: "right" }}
                          // form="my-form"
                          name={
                            passwordLoading ? "Updating..." : "Update Password"
                          }
                          className={"btn-save_password"}
                          // onClickFunction={() => {
                          //   handleSubmit(handlePasswordSubmit)();
                          // }}
                        />
                      </form>
                    </div>
                  </>
                )}

                {/* <hr /> */}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManagePart;
