// home page google maps and other components
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/sidebar/Sidebar";
import StatusCard from "../../components/cards/statusCard/StatusCards";
import BottomCard from "../../components/cards/FooterReadings";
import Noti from "../notification/Notification";
import "./Search.css";
import Info from "./Info";
// import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Greenmark from "../../assets/images/mark1.png";
import Redmark from "../../assets/images/Mark-Red.png";
import Orangemark from "../../assets/images/mark1-orange-copy.png";
import { useGetApi } from "../../components/api/UseGetApi";
import usePostApi from "../../components/api/UsepostApi";

const SearchCard = (props) => (
  <>
    <div
      className="search-card"
      onClick={() => props.handleNavigateToDashboard()}
    >
      <>
        <p>{props?.location_name}</p>
        <p id="search-address">{props?.address}</p>
        <p>{props?.pincode}</p>
      </>
    </div>
  </>
);

const containerStyle = {
  width: "100%",
  height: "100vh",
};

// Constants
const defaultCenter = {
  lat: 21.7679,
  lng: 78.8718,
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; //API key

function MyComponent() {
  const [mapZoom, setMapZoom] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markers, setMarkers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [locResult, setLocResult] = useState([]);
  const [noData, setNoData] = useState(false);
  const [filteredMarkers, setFilteredMarkers] = useState(markers);
  const [activeInfoWindow, setActiveInfoWindow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setError] = useState(false);
  const cardRef = useRef();

  // Hooks for API calls(Get)
  const { getData: LocData } = useGetApi("location/getAll");

  // Hooks for API calls(Get, Post)
  const {
    PostData,
    mainData,
    error: apiError,
  } = usePostApi(`search/getAllLocations`, {
    search: searchInput,
    swal: false,
  });

  // error handling
  useEffect(() => {
    if (apiError) {
      setError(apiError.response.data.message);
    } else {
      setError(""); // Clear error when data is fetched successfully
    }
  }, [apiError]);

  // marker handling
  useEffect(() => {
    if (LocData) {
      const sortedMarkers = [...LocData].sort((a, b) => a.id - b.id); // Create a copy of LocData and sort it
      setMarkers(sortedMarkers);
      setFilteredMarkers(sortedMarkers);
    }
  }, [LocData]);

  // search handling
  useEffect(() => {
    // If search input is empty, reset states
    if (!searchInput) {
      setLocResult([]);
      setNoData(false);
      setError(""); // Clear error when new search is initiated
      return;
    }

    // If mainData is not null and result is empty, set noData state to true
    if (mainData && mainData.result.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }

    // If mainData is not null, update locResult
    if (mainData) {
      setLocResult(mainData.result);
      setError("");
    }
  }, [mainData, searchInput]);

  // input values
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // search handling
  useEffect(() => {
    if (searchInput.length >= 3) {
      setIsOpen(true);
      PostData();
    } else {
      setIsOpen(false);
      // Reset locResult when searchInput length is less than 3
      setLocResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]); // Trigger the effect whenever searchInput changes

  // clear search
  const clearSearch = useCallback(() => {
    setLocResult([]);
    setIsOpen(false);
    setError(false);
  }, []);

  useEffect(() => {
    if (searchInput === "") {
      clearSearch();
      setError("");
    }
  }, [searchInput, clearSearch]);

  // clearing the session storage
  useEffect(() => {
    if (window.location.pathname === "/") {
      sessionStorage.removeItem("markerId");
    }
  }, []);

  // click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();

  // Navigate to dashboard
  const handleNavigateToDashboard = (markerId) => {
    // console.log("markerIdSachin", markerId);
    navigate("/dashboard", { state: { markerId } });
  };

  // Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // Google Maps Options
  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggableCursor: "pointer",
    draggingOptions: {
      draggableCursor: "pointer",
      touchDraggable: true,
      touchDragging: true,
    },
  };

  if (!isLoaded) return <></>;

  // Active marker
  const handleActiveMarker = (markerId) => {
    if (activeInfoWindow === markerId) {
      setActiveInfoWindow(null);
    } else {
      setActiveInfoWindow(markerId);
      const selectedMarker = markers.find((marker) => marker.id === markerId);
      if (selectedMarker) {
        setMapZoom(9);
        const position = {
          lng: parseFloat(selectedMarker.longitude),
          lat: parseFloat(selectedMarker.latitude),
        };
        setMapCenter(position);
      }
    }
  };

  // console.log("markers", markers.length);

  // filter by status
  const filterMarkers = (filter) => {
    if (filter === "All") {
      setFilteredMarkers(markers);
    } else {
      const filtered = markers?.filter(
        (marker) => marker.currentStatus === filter
      );
      setFilteredMarkers(filtered);
    }
  };

  // Sidebar
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const homeCenter = () => {
    setMapCenter(defaultCenter);
    setMapZoom(5); // adjust the default zoom level as needed
  };

  // handle close and zoomout
  const handleClose = () => {
    setActiveInfoWindow(null);
    // setMapCenter(defaultCenter);
    setMapZoom(5);
  };

  return (
    <div>
      <Sidebar
        isExpanded={isExpanded}
        buttonfn={toggleSidebar}
        sidebar="sidebar"
      />
      <FontAwesomeIcon
        className="expand-btn"
        icon={faBars}
        onClick={toggleSidebar}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={mapZoom}
        options={options}
      >
        <div className="marker-container">
          {filteredMarkers.map(({ id, latitude, longitude, currentStatus }) => (
            <MarkerF
              key={id}
              position={{
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              }}
              animation={window.google.maps.Animation.DROP}
              onClick={() => handleActiveMarker(id)}
              icon={{
                scaledSize: new window.google.maps.Size(40, 40),
                url:
                  currentStatus === "All Good"
                    ? Greenmark
                    : currentStatus === "Needs Attention"
                    ? Orangemark
                    : Redmark,
              }}
            >
              {activeInfoWindow === id && (
                <InfoWindowF
                  className="infoWindow"
                  onCloseClick={() => setActiveInfoWindow(null)}
                >
                  <>
                    <Info
                      markerId={filteredMarkers.find(
                        (marker) => marker.id === id
                      )}
                      selectedMarker={filteredMarkers.find(
                        (marker) => marker.id === id
                      )}
                      closeButton={handleClose}
                    />
                  </>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}
        </div>
      </GoogleMap>
      <div id="searchbar-card">
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search Places..."
            value={searchInput}
            onChange={handleInputChange}
            className="location-search-input"
          />

          <div id="search-notification">
            <Noti />
          </div>
          {isOpen && (
            <div className="search-result-card" ref={cardRef}>
              <>
                {isError && <div style={{ color: "red" }}>{isError}</div>}
                {!isError && noData && (
                  <div style={{ color: "red" }}>
                    No data found for "{searchInput}"
                  </div>
                )}
                {!isError &&
                  !noData &&
                  searchInput.length >= 3 &&
                  locResult.map((item, index) => (
                    <div key={index} style={{ color: "red" }}>
                      <SearchCard
                        key={index}
                        location_name={item.location_name}
                        pincode={item.pincode}
                        address={item.address}
                        firstname={item.firstname}
                        lastname={item.lastname}
                        isLast={index === locResult.length - 1} // Check if it's the last item
                        handleNavigateToDashboard={() =>
                          handleNavigateToDashboard(item)
                        }
                        isError={isError}
                      />
                    </div>
                  ))}
              </>
            </div>
          )}
        </div>
      </div>
      {/* <TestCard/> */}
      <div className="status-card-overlays">
        <StatusCard filterMarkers={filterMarkers} markers={markers} />
      </div>
      <div id="bottom-readings">
        <BottomCard
          displayBottomReadings="displayBottomReadings"
          homeCenter={homeCenter}
        />
      </div>
    </div>
  );
}

export default MyComponent;
