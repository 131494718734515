import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "./IndexManage.css";
const IndexManage = ({ name,NestedMenu }) => {
  return (
    <div>
      <button id="addButton" onClick={NestedMenu}>
        <FontAwesomeIcon id="dropdown-body-button-plus" icon={faEdit} />
        <span>{name}</span>
      </button>
    </div>
  );
};

export default IndexManage;
