import React, { useEffect, useState } from "react";
import NestedModal from "../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../components/buttons/saveContinue/ButtonSave";
import usePutApi from "../../../../components/api/UsePutApi";
import "./Location.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../components/api/UseGetApi";
import useFetchData from "../../../../components/api/UseDeleteApi";
import CustomTable from "../../../../components/table/CustomTable";

const TenantLocColumns = [
  { label: "Tenant ID", key: "sno" },
  {
    id: "location_id",
    label: "Location ID",
    key: "location_id",
  },
  {
    id: "tenant_name",
    label: "Tenant Name",
    key: "tenant_name",
  },
  {
    id: "contact_name",
    label: "Contact Name",
    key: "contact_name",
  },
  {
    id: "location_name",
    label: "Location Name",
    key: "location_name",
  },
];

const Location = (props) => {
  const [tenLocData, setTenLocData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectValues, setSelectValues] = useState({
    tenant_id: "",
    location_id: "",
    tenant_name: "",
    location_name: "",
    contact_name: "",
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 4,
    // tenantData: [],
    userData: [],
    tenantLocData: [],
    userName: [],
    locData: [],
    activeId: null,
    isOpen: false,
    isBtnSaveVisible: false,
    search: "",
    // isLoading: true,
    tenantLocformData: {},
    locFormData: {},
    selectedManager: {},
  });
  const { activeId, isOpen } = state;

  // getUserIdDetails
  const userID = JSON.parse(localStorage.getItem("user")).success?.userId;

  // get TableData Data
  const { getData: TenantLocData, isLoading } = useGetApi(
    "assign_tenants_to_location/getAll"
  );

  // console.log("tenantData", TenantLocData?.tenants);

  // delete location
  const { DeleteData } = useFetchData("assign_tenants_to_location/delete", {
    message: "TenantLoc Successfully deleted!",
  });

  // get Location and Tenant data
  const { getData: locationData } = useGetApi("location/getAll");
  const { getData: tenantData } = useGetApi("tenants/getAll");

  // console.log("tenantData", tenantData);
  // console.log("locationData", locationData);
  useEffect(() => {
    setTenLocData(TenantLocData?.tenants || []);
  }, [TenantLocData]);

  // console.log("tenantLocData", tenLocData);

  const handleDelete = async (id) => {
    try {
      // Call the DeleteData function from the hook
      const confirmed = await DeleteData(id);

      if (confirmed) {
        // Update state after deletion
        setTenLocData(tenLocData.filter((item) => item.id !== id));
      } else {
        // Deletion canceled
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // handle go back

  const handleGoBackClick = () => {
    reset();
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isBtnSaveVisible: false,
    }));
    setTenLocData((prevData) =>
      prevData?.map((item) =>
        item.id === activeId?.id
          ? { ...item, ...selectValues, contact_name: activeId?.contact_name }
          : item
      )
    );
    // console.log("active", activeId?.id);
  };

  // console.log("tenant",tenantData?.filter ((item) => item.contact_name));
  // console.log("tenantDataC",tenantData?.filter((item) => item?.contact_name)?.map((item) => item.contact_name));

  // handle Open for edit

  const handleOpenClick = (rowId) => {
    setState((prevState) => ({
      ...prevState,
      isOpen: true,
      activeId: rowId,
      isBtnSaveVisible: true,
    }));
    setSelectValues({
      tenant_id: rowId?.tenant_id,
      location_id: rowId?.location_id,
      tenant_name: rowId?.tenant_name,
      location_name: rowId?.location_name,
    });
    // console.log("rowId", rowId);
  };

  const handleId = (e) => {
    const { name, value } = e.target;
    const idKey = name === "location_name" ? "location_id" : "tenant_id";
    setSelectValues((prevTenant) => ({
      ...prevTenant,
      [name]: value,
      [idKey]: e.target.options[e.target.selectedIndex].getAttribute("data-id"),
    }));
  };

  // console.log("selectValues", selectValues);
  const { PostData: postDataApi, error } = usePutApi(
    `assign_tenants_to_location/update/${activeId?.id}`,
    {
      tenant_id: selectValues?.tenant_id,
      location_id: selectValues?.location_id,
      organization_id: "2",
      created_by: userID,
      fun: () => handleGoBackClick(),
    }
  );

  useEffect(() => {
    setIsError(error?.response?.data?.error);
    console.log("isError", isError);
  }, [error, isError]);

  // console.log("IsError", isError);

  // onSubmit for edit

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await postDataApi();

      if (success) {
        handleGoBackClick();
        // props.closeModal();
        // console.log("props", props);
        // console.log("sachin");
      } else {
        // Update canceled
        handleGoBackClick();
        console.log("Update canceled");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <div>
      <NestedModal
        modalSize="xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5
              id="dropdown-modal-title"
              style={{ marginLeft: "20px", padding: "15px" }}
            >
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "TENANT + LOCATION" && (
            <>
              <>
                {!isOpen && (
                  <CustomTable
                    columns={TenantLocColumns}
                    data={tenLocData}
                    handleOpenClick={handleOpenClick}
                    handleDelete={handleDelete}
                    isLoading={isLoading}
                  />
                )}
                {isOpen && (
                  <>
                    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                      <button
                        onClick={handleGoBackClick}
                        className="user-back-btn"
                      >
                        <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                      </button>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          // minHeight: "40vh",
                        }}
                      >
                        <select
                          {...register("location_name", {
                            required: "Location name is required",
                          })}
                          id="zoomed-input"
                          onChange={handleId}
                          name="location_name"
                          value={selectValues?.location_name}
                        >
                          <option value="" disabled selected>
                            Location Name*
                          </option>
                          {locationData?.map((item) => (
                            <option
                              key={item.id}
                              value={item.location_name}
                              data-id={item.location_id}
                            >
                              {item.location_name}
                            </option>
                          ))}
                        </select>
                        {errors?.location && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {errors.location.message}
                          </p>
                        )}
                        {isError?.location_id && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {isError?.location_id}
                          </p>
                        )}

                        <select
                          {...register("tenant_name", {
                            required: "tenant name is required",
                          })}
                          id="zoomed-input"
                          onChange={handleId}
                          name="tenant_name"
                          value={selectValues?.tenant_name}
                        >
                          <option value="" disabled selected>
                            Tenant Name*
                          </option>
                          {tenantData?.map((item) => (
                            <option
                              key={item.id}
                              value={item.tenant_name}
                              data-id={item?.id}
                            >
                              {item.tenant_name}
                            </option>
                          ))}
                        </select>
                        {errors.tenant_name && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {errors.tenant_name.message}
                          </p>
                        )}

                        {isError?.tenant_id && (
                          <p style={{ color: "red", marginBottom: "0px" }}>
                            {isError?.tenant_id}
                          </p>
                        )}
                      </div>
                    </form>
                  </>
                )}
              </>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "TENANT + LOCATION" && (
                <>
                  {isOpen && (
                    <BtnSave
                      form="my-form"
                      name="SAVE"
                      onClickFunction={handleSubmit(onSubmit)}
                    />
                  )}
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default Location;
