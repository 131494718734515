import React from "react";
import "./Mains.css";

const ToggleButton = ({ mainsStatus }) => {
  return (
    <div className={`toggle-button  ${mainsStatus === "ON" ? "disabled" : ""}`}>
      <div className="slider">
        <div className={`knob ${mainsStatus === "ON" ? "on" : ""}`} />
      </div>
    </div>
  );
};

export default ToggleButton;
