import React from 'react'

const HomePage = () => {
  return (
    <div>
      dashboard + Assets
    </div>
  )
}

export default HomePage
