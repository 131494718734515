import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import ModalComponent from "../../../../../../contexts/homeModal/HomeModal";
import ModalSohComponent from "../../../../../../contexts/homeModal/HomeModal";
import ViewAll from "../../../../../../components/buttons/viewAll/ViewAl";

import "./Cycle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Cycles = ({ battery }) => {
  const [showModal, setShowModal] = useState(false);
  const [showSohModal, setShowSohModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeSohModal = () => {
    setShowSohModal(false);
  };

  useEffect(() => {
    setItemsPerPage(2);
  }, []);

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = battery
    ? battery.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="d-flex" id="cycle-card">
        <div id="cycles-table1">
          <Table bordered style={{ border: "1px solid #6f6f6f" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "none",
                    borderTop: "1px solid white",
                    borderLeft: "1px solid transparent",
                    borderRight: "1px solid transparent",
                  }}
                >
                  Battery
                </th>
                <th
                  style={{
                    border: "none",
                    borderTop: "1px solid white",
                    borderLeft: "1px solid transparent",
                    borderRight: "1px solid transparent",
                  }}
                >
                  Used Cycles
                </th>
                <th
                  style={{
                    border: "none",
                    borderTop: "1px solid transparent",
                    borderRight: "1px solid transparent",
                  }}
                >
                  Remaining Cycles
                </th>
                <th
                  style={{
                    border: "none",
                    borderTop: "1px solid transparent",
                    borderRight: "1px solid transparent",
                  }}
                >
                  SOC
                </th>
                <th
                  style={{
                    border: "none",
                    borderTop: "1px solid transparent",
                    borderRight: "1px solid transparent",
                  }}
                >
                  SOH
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.length === 0 ? (
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      border: "none",
                      borderLeft: "1px solid transparent",
                    }}
                  >
                    No data
                  </td>
                </tr>
              ) : (
                currentItems?.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        border: "none",
                        borderLeft: "1px solid transparent",
                      }}
                    >
                      B{index + 1 + indexOfFirstItem}
                    </td>
                    <td>{item.used_cycles}</td>
                    <td>{item.left_cycles}</td>
                    <td>{item.soc}%</td>
                    <td
                      style={{
                        border: "none",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      {item.soh}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          <div className="FooterCard">
            <ViewAll openModal={openModal} />
            <div>
              <button
                className="card-pagination_button"
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon id="scroll" icon={faChevronLeft} />
              </button>
              {currentItems.length > 0 && (
                <button
                  className="card-pagination_button"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastItem >= battery.length}
                >
                  <FontAwesomeIcon id="scroll" icon={faChevronRight} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <>
        <ModalComponent
          showModal={showModal}
          closeModal={closeModal}
          smSize="lg"
          className="viewAll-modal_xl"
          ModalclassName="modal-dashboard"
          footer={false}
          Mtitle="Battery Data"
          Mbody={
            <>
              <Table bordered style={{ border: "1px solid #6f6f6f" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      Battery
                    </th>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      Used Cycles
                    </th>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      Remaining Cycles
                    </th>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      SOC
                    </th>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      SOH
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {battery?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "none",
                          borderLeft: "1px solid transparent",
                        }}
                      >
                        B{index + 1 }
                      </td>
                      <td>{item.used_cycles}</td>
                      <td>{item.left_cycles}</td>
                      <td>{item.soc}%</td>
                      <td
                        style={{
                          border: "none",
                          borderRight: "1px solid transparent",
                        }}
                      >
                        {item.soh}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          }
        />
        <ModalSohComponent
          showModal={showSohModal}
          closeModal={closeSohModal}
          smSize="lg"
          ModalclassName="modal-dashboard"
          footer={false}
          Mbody={
            <>
              <Table bordered style={{ border: "1px solid #6f6f6f" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderLeft: "1px solid transparent",
                      }}
                    >
                      Battery
                    </th>
                    <th
                      style={{ border: "none", borderTop: "1px solid white" }}
                    >
                      SOH%
                    </th>
                    <th
                      style={{
                        border: "none",
                        borderTop: "1px solid white",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      SOC%
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {battery?.map((item,index) => (
                    <tr key={index}>
                      <td style={{ borderLeft: "1px solid transparent" }}>
                        B{item.battery_id}
                      </td>
                      <td>{item.soh}</td>
                      <td style={{ borderRight: "1px solid transparent" }}>
                        {item.soc}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          }
        />
      </>
    </div>
  );
};

export default Cycles;
