import React, { useRef, useState } from "react";
import "./ReadCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faAnglesLeft,
  // faAnglesRight,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

const Month = [
  { short: "Jan", full: "JANUARY", monthNo: "1" },
  { short: "Feb", full: "FEBRUARY", monthNo: "2" },
  { short: "Mar", full: "MARCH", monthNo: "3" },
  { short: "Apr", full: "APRIL", monthNo: "4" },
  { short: "May", full: "MAY", monthNo: "5" },
  { short: "Jun", full: "JUNE", monthNo: "6" },
  { short: "Jul", full: "JULY", monthNo: "7" },
  { short: "Aug", full: "AUGUST", monthNo: "8" },
  { short: "Sep", full: "SEPTEMBER", monthNo: "9" },
  { short: "Oct", full: "OCTOBER", monthNo: "10" },
  { short: "Nov", full: "NOVEMBER", monthNo: "11" },
  { short: "Dec", full: "DECEMBER", monthNo: "12" },
];

const Card = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [year, setYear] = useState(2024);
  const menuRef = useRef();

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleMonthClick = (month, monthNo) => {
    props.setIsMonth(props.isMonth !== month ? month : null);
    toggleDropdown();
    props.setIsMonthNo(monthNo);
  };

  // year

  // const handleForward = () => {
  //   setYear(year + 1);
  // };

  // const handleBackward = () => {
  //   setYear(year - 1);
  // };

  return (
    <div className="bt-card">
      <div className="bt-card-header">
        <h6>{props.name}</h6>
      </div>
      <div className="bt-card-body">
        <p
          onClick={props.Calendartrue ? toggleDropdown : null}
          style={props.Calendartrue ? { cursor: "pointer" } : {}}
        >
          {props.value}
          {"   "}
          {props.Calendartrue ? (
            <FontAwesomeIcon icon={faCalendar} style={{ marginLeft: "5px" }} />
          ) : null}
        </p>
        {isDropdownOpen && (
          <div className="dropdown-content" ref={menuRef}>
            <div className="custom-calendar_year">
              {/* <FontAwesomeIcon
                onClick={handleBackward}
                id="year-btn"
                icon={faAnglesLeft}
              />
              <p id="year-value">{year}</p>
              <FontAwesomeIcon
                onClick={handleForward}
                id="year-btn"
                icon={faAnglesRight}
              /> */}
            </div>
            <div className="custom-calendar_card">
              {Month.map(({ short, full, monthNo }) => (
                <p
                  key={short}
                  className={
                    props.isMonth === short ? "selected-month" : "month-name"
                  }
                  onClick={() => handleMonthClick(short, monthNo)}
                >
                  {short}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
