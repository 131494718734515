// nested modal

import React from "react";
import { Modal } from "react-bootstrap";

const ModalComponent = (props) => {

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        animation={true}
        dialogClassName={props.className ? props.className : "custom-modal"}
        size={props.modalSize}
        centered
        fullscreen={props.ScreenSize}
        className={props.className}

        // style={{
        //   "@media (min-width: 1080px)": {
        //     ".modal-lg, .modal-xl": { width: "100%" },
        //   },
        // }}
      >
        <Modal.Header className="drop-modal-header">
          {props.Mheader}
        </Modal.Header>
        <Modal.Body className="drop-modal-body">
          <>{props.Mbody}</>
        </Modal.Body>
        <Modal.Footer className="drop-modal-footer">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <p
              style={{
                color: "red",
                textAlign: "left",
                cursor: "pointer",
                marginBottom: "0px",
              }}
              onClick={props.closeModal}
            >
              CLOSE
            </p>
            {/* <BtnSave name="SAVE" /> */}
            {props.modalFooter}
          </div>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .custom-modal .modal-dialog {
          max-width: ${props.customWidth};
        }
        @media (min-width: 1080px) {
          .custom-modal .modal-lg,
          .custom-modal .modal-xl {
            --bs-modal-width: ${props.customWidth};
          }
        }
      `}</style>
    </div>
  );
};

export default ModalComponent;
