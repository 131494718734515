/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FanI from "../../../../../../assets/images/Air-In.svg";
import FanO from "../../../../../../assets/images/Air-Out.svg";
import FanR from "../../../../../../assets/images/Air-Circular.svg";
import "./Cabinet.css";

const CabinetCards = (props) => {
  return (
    <>
      <div className="cabinet-card-container">
        <div style={props.style} className="cabinet-card">
          {props.value}
        </div>
        <div style={props.style} className="cabinet-cardName">
          {props.name}
        </div>
      </div>
    </>
  );
};

const Cabinet = (props) => {
  const [isOn, setOff] = useState(true);
  const [isFanOne, setFanOne] = useState(true);
  const [isIdle, setIdle] = useState(true);

  const handleFan = () => {
    setOff(!isOn);
  };

  const handleIdle = () => {
    setIdle(!isIdle);
    setFanOne(!isFanOne);
  };

  console.log(handleFan, handleIdle);

  useEffect(() => {
    handleIdle();
  }, []);

  return (
    <div className="Cabinet">
      <div className="Cabinet-container">
        {props?.fans?.fanslist?.map((item, index) => (
          <React.Fragment key={index}>
            {item?.description === "Incoming" && (
              <div className="fan-1" key={item?.id}>
                <img
                  // className={isOn ? "rotate" : ""}
                  style={{ width: "40%", marginBottom: "20px" }}
                  src={FanI}
                  alt="fan"
                />
                <div className="fan-status">
                  <p>Fan-1 Incoming</p>
                </div>
              </div>
            )}
            {item?.description === "Outgoing" && (
              <div className="fan-2">
                <img
                  // className={isOn ? "rotate" : ""}
                  style={{ width: "40%", marginBottom: "20px" }}
                  src={FanO}
                  alt="fan"
                />
                <div className="fan-status">
                  <p>Fan-2 Outgoing</p>
                </div>
              </div>
            )}
            {item?.description === "AHU" && (
              <div className="fan-3">
                <img
                  // className={isFanOne ? "rotate" : ""}
                  style={{ width: "40%", marginBottom: "20px" }}
                  src={FanR}
                  alt="fan"
                />
                <div className={isIdle ? "fan-status" : "fan-status__idle"}>
                  <p>{isIdle ? "Fan-3 Running" : "Fan-3 Circular"}</p>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="Cabinet-container__divider">
        <div className="setA">
          <CabinetCards
            value={`${
              props?.fans?.incoming_temp ? props?.fans?.incoming_temp : 0
            }°C`}
            name="Incoming Air Temperature"
            style={{ backgroundColor: " rgb(23, 173, 68)" }}
          />
        </div>
        <div className="setB">
          <CabinetCards
            value={`${
              props?.fans?.lastDayMinTemp ? props?.fans?.lastDayMinTemp : 0
            }°C`}
            name="Lastday min Temperature"
            style={{ backgroundColor: "#1a9c63" }}
          />
        </div>
        <div className="setC">
          <CabinetCards
            value={`${
              props?.fans?.criticalTemp ? props?.fans?.criticalTemp : 0
            }°C`}
            name="Critical Temperature"
            style={{ backgroundColor: " #890604" }}
          />
        </div>
      </div>
      <div className="Cabinet-container__divider">
        <div className="setA">
          <CabinetCards
            value={`${
              props?.fans?.outgoing_temp ? props?.fans?.outgoing_temp : 0
            }°C`}
            name="Outgoing Air Temperature"
            style={{ backgroundColor: "gray" }}
          />
        </div>
        <div className="setB">
          <CabinetCards
            value={`${
              props?.fans?.lastDayMaxTemp ? props?.fans?.lastDayMaxTemp : 0
            }°C`}
            name="Lastday max Temperature"
            style={{ backgroundColor: "#da1610" }}
          />
        </div>
        <div className="setC">
          <CabinetCards
            value={`${props?.fans?.alertTemp ? props?.fans?.alertTemp : 0}°C`}
            name="Alert Temperature"
            style={{ backgroundColor: "#b00300" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Cabinet;
