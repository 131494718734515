// star component
import React, { useState } from "react";
import "./Stars.css";

const StarRating = () => {
  const [rating, setRating] = useState(0);

  const handleStarClick = () => {
    setRating(rating === 0 ? 1 : 0); // Toggle between 0 and 1
  };

  return (
    <div className="star-rating">
      <span
        className={rating === 1 ? "star-filled" : "star-empty"}
        onClick={handleStarClick}
      >
        ★
      </span>
    </div>
  );
};

export default StarRating;
