
import axios from "axios";
import { useState, useEffect } from "react";

export const useGetApi = (apiPara) => {
  const [getData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key

    setIsLoading(true);
    try {
      const userDetails = JSON.parse(localStorage.getItem("user")).success
        .token;
      const response = await axios.get(`${EMS_API_KEY}/${apiPara}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userDetails}`,
        },
      });
      setData(response.data);
    } catch (error) {
      setError(error);
      console.log("errorsGet",error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPara]);

 
  const refetchData = () => {
    fetchData();
  };

  return { getData, isLoading, error, setData, refetchData };
};
