import React from "react";
import { Modal } from "react-bootstrap";
import "./HomeModal.css";

const ModalComponent = (props) => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.closeModal}
      animation={true}
      dialogClassName={props.className ? props.className : "modal-90w"}
      className={props.ModalclassName}
      size={props.smSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={props.size}
    >
      {props.header === false ? null : (
        <Modal.Header style={props.MheadStyle} closeButton>
          <h6
            className="modal-dashboard-title"
            style={{ color: `${props.MtitleColor}` }}
          >
            {props.Mtitle}
          </h6>
        </Modal.Header>
      )}

      <Modal.Body style={props.MbodyStyle}>{props.Mbody}</Modal.Body>
      {props.footer ? <Modal.Footer></Modal.Footer> : null}
    </Modal>
  );
};

export default ModalComponent;
