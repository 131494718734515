import React, { useEffect, useState } from "react";
import NestedModal from "../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../components/buttons/saveContinue/ButtonSave";
import usePutApi from "../../../../components/api/UsePutApi";
import ButtonW from "../../../../components/buttons/indexManage/IndexManage";
import "./User.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../components/api/UseGetApi";
import useFetchData from "../../../../components/api/UseDeleteApi";
import { numberValidation } from "../../../../components/validations/utils";
import { characterValidation } from "../../../../components/validations/utils";
import CustomTable from "../../../../components/table/CustomTable";

const Usercolumns = [
  { id: "id", label: "User ID", key: "sno" },
  {
    id: "firstname",
    label: "Name",
    key: "firstname",
  },
  {
    id: "email",
    label: "Email",
    key: "email",
  },
  {
    id: "phone",
    label: "Phone",
    key: "phone",
  },
  {
    id: "user_type",
    label: "User Type",
    key: "user_type",
  },
];
const InputField = ({ id, label, register, error, errorMessage, ...rest }) => (
  <>
    <input
      id={id ? "zoomed-input-user" : ""}
      style={{ width: "70%" }}
      {...register}
      {...rest}
      placeholder={`${rest.placeholder}*`}
    />
    {error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {errorMessage}
      </p>
    )}
  </>
);
const Location = (props) => {
  const [showButtons, setShowButtons] = useState(true);
  const [showAdminInputs, setShowAdminInputs] = useState(false);
  const [showSiteInputs, setShowSiteInputs] = useState(false);
  const [isUserData, setIsUserData] = useState([]);
  const [isManagerData, setIsManagerData] = useState([]);
  const [isAdminError, setIsAdminError] = useState(false);
  const [adminData, setAdminData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  const [state, setState] = useState({
    userDatas: [],
    tenantLocData: [],
    userName: [],
    locData: [],
    activeId: null,
    isOpen: false,
    isBtnSaveVisible: false,
    search: "",
    tenantLocformData: {},
    locFormData: {},
    selectedManager: {},
  });
  const { activeId, isOpen } = state;
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // for buttons
  const handleAdminInputs = () => {
    setShowAdminInputs(true);
    setShowSiteInputs(false);
    setShowButtons(false);
  };

  const handleSiteInputs = () => {
    setShowAdminInputs(false);
    setShowSiteInputs(true);
    setShowButtons(false);
  };

  // get User Data
  const { getData: UserDataApi, isLoading } = useGetApi(
    "users/getAllAdminByOrganization/2"
  );

  // console.log("UserDataApi", UserDataApi);
  const { getData: UserManagerApi } = useGetApi(
    "users/getAllSiteManagersByOrganization/2"
  );

  // delete location
  const { DeleteData } = useFetchData("users/delete", {
    message: "User Successfully deleted!",
  });
  const handleDelete = async (id) => {
    try {
      // Call the DeleteData function from the hook
      const confirmed = await DeleteData(id);

      if (confirmed) {
        // Update state after deletion
        setIsUserData(isUserData?.filter((item) => item.id !== id));
        setIsManagerData(isManagerData?.filter((item) => item.id !== id));
      } else {
        // Deletion canceled
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // open edit part
  const handleOpenClick = (rowId) => {
    setState((prevState) => ({
      ...prevState,
      isOpen: true,
      activeId: rowId,
      isBtnSaveVisible: true,
    }));
    setAdminData({
      firstname: rowId?.firstname,
      lastname: rowId?.lastname,
      email: rowId?.email,
      phone: rowId?.phone,
    });
  };

  // handle go back

  const handleAdminChange = ({ target: { name, value } }) =>
    setAdminData((prevFormData) => ({ ...prevFormData, [name]: value }));

  // console.log("adminValues", adminData);

  const { PostData: postDataApi, error } = usePutApi(
    `users/updateuserdetails/${activeId?.id}`,
    {
      firstname: adminData?.firstname,
      lastname: adminData?.lastname,
      email: adminData?.email,
      phone: adminData?.phone,
      user_type: "admin",
      organization_id: "2",
      fun: () => handleGoBackClick(),
    }
  );

  useEffect(() => {
    setIsUserData(UserDataApi?.users);
    setIsManagerData(UserManagerApi?.users);
    setIsAdminError(error?.response?.data?.errors);
  }, [UserDataApi?.users, UserManagerApi?.users, error]);

  // console.log("isAdminError", isAdminError);
  const handleGoBackClick = () => {
    reset();
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isBtnSaveVisible: false,
    }));

    // const updateItem = (prevData) => {
    //   if (!Array.isArray(prevData)) return prevData; // If prevData is not an array, return it as is
    //   return prevData.map((item) =>
    //     item.id === activeId?.id ? { ...item, ...adminData } : item
    //   );
    // };

    // setIsUserData(updateItem);
    // setIsManagerData(updateItem);
  };

  const onSubmit = async (data) => {
    try {
      // Call the Posting function from the hook
      const success = await postDataApi();

      if (success) {
        handleGoBackClick();
        // props.closeModal();
        console.log("props", props);
      } else {
        const updateItem = (prevData) => {
          if (!Array.isArray(prevData)) return prevData; // If prevData is not an array, return it as is
          return prevData.map((item) =>
            item.id === activeId?.id ? { ...item, ...adminData } : item
          );
        };

        setIsUserData(updateItem);
        setIsManagerData(updateItem);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const dataToRender = showSiteInputs ? isManagerData : isUserData;

  return (
    <div>
      <NestedModal
        modalSize="xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5
              id="dropdown-modal-title"
              style={{ marginLeft: "20px", padding: "15px" }}
            >
              {/* {props.selectedModal.title} */}
              {showButtons
                ? "SELECT USER TYPE"
                : showAdminInputs
                ? "MANAGE ADMIN"
                : "MANAGE SITE MANAGER"}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "USER" && (
            <>
              <>
                {showButtons && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "45vh",
                    }}
                  >
                    <div onClick={handleAdminInputs}>
                      <ButtonW name="ADMIN" />
                    </div>
                    <div onClick={handleSiteInputs}>
                      <ButtonW name="SITE MANAGER" />
                    </div>
                  </div>
                )}

                {(showAdminInputs || showSiteInputs) && (
                  <>
                    {!isOpen && (
                      <>
                        <CustomTable
                          columns={Usercolumns}
                          data={dataToRender}
                          handleOpenClick={handleOpenClick}
                          handleDeleteClick={handleDelete}
                          isLoading={isLoading}
                        />
                      </>
                    )}
                  </>
                )}
                {isOpen && (
                  <>
                    <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                      <button
                        onClick={handleGoBackClick}
                        className="user-back-btn"
                      >
                        <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                      </button>
                      <div className="user-manage-form">
                        <InputField
                          id="firstname"
                          label="First Name"
                          name="firstname"
                          value={adminData?.firstname || ""}
                          register={register("firstname", {
                            required: "First Name is required",
                          })}
                          error={errors?.firstname || isAdminError?.firstname}
                          errorMessage={
                            errors.firstname?.message || isAdminError?.firstname
                          }
                          onChange={handleAdminChange}
                          placeholder="First Name"
                          onKeyDown={characterValidation}
                        />
                        <InputField
                          id="lastname"
                          label="Last Name"
                          name="lastname"
                          value={adminData?.lastname || ""}
                          register={register("lastname", {
                            required: "Last Name is required",
                          })}
                          error={errors.lastname || isAdminError?.lastname}
                          errorMessage={
                            errors.lastname?.message || isAdminError?.lastname
                          }
                          onChange={handleAdminChange}
                          placeholder="Last Name"
                          onKeyDown={characterValidation}
                        />
                        <InputField
                          id="email"
                          name="email"
                          label="Email"
                          readOnly
                          value={adminData?.email || ""}
                          register={register("email", {
                            required: "Email Address is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email format",
                            },
                          })}
                          error={errors?.email || isAdminError?.email}
                          errorMessage={
                            errors.email?.message || isAdminError?.email
                          }
                          onChange={handleAdminChange}
                          placeholder="Email Id"
                        />
                        <InputField
                          id="phone"
                          label="Phone"
                          name="phone"
                          value={adminData?.phone || ""}
                          type="text"
                          maxLength={10}
                          minLength={10}
                          register={register("phone", {
                            required: "Phone number is required",
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: "Phone number must be exactly 10 digits",
                            },
                          })}
                          error={errors.phone || isAdminError?.phone}
                          errorMessage={
                            errors.phone?.message || isAdminError?.phone
                          }
                          onChange={handleAdminChange}
                          placeholder="Phone Number"
                          onKeyDown={numberValidation}
                        />
                      </div>
                    </form>
                  </>
                )}
              </>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "USER" && (
                <>
                  {isOpen && (
                    <BtnSave
                      form="my-form"
                      name="SAVE"
                      onClickFunction={handleSubmit(onSubmit)}
                    />
                  )}
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default Location;
