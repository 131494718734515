// import React, { useState } from "react";
// import PieChart from "./PieChart";
// import "./test.css";
// const Test = () => {
//   const cardWidth = 200;
//   const cardHeight = 200;
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const [isDragging, setIsDragging] = useState(false);
//   const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });

//   const handleMouseDown = (e) => {
//     setIsDragging(true);
//     setMouseOffset({
//       x: e.clientX - position.x,
//       y: e.clientY - position.y,
//     });
//   };

//   const handleMouseMove = (e) => {
//     if (isDragging) {
//       let newX = e.clientX - mouseOffset.x;
//       let newY = e.clientY - mouseOffset.y;

//       // Ensure the card stays within the screen horizontally
//       if (newX < 0) newX = 0;
//       if (newX > window.innerWidth - cardWidth)
//         newX = window.innerWidth - cardWidth;

//       // Ensure the card stays within the screen vertically
//       if (newY < 0) newY = 0;
//       if (newY > window.innerHeight - cardHeight)
//         newY = window.innerHeight - cardHeight;

//       setPosition({ x: newX, y: newY });
//     }
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);

//     // Determine the nearest vertical edge
//     const screenWidth = window.innerWidth;

//     let newX;

//     if (position.x < screenWidth / 2) {
//       newX = 0;
//     } else {
//       newX = screenWidth - cardWidth;
//     }

//     setPosition({ x: newX, y: position.y });
//   };

//   return (
//     <div
//       className="StatusCard_m1-main"
//       onMouseMove={handleMouseMove}
//       onMouseUp={handleMouseUp}
//     >
//       <div
//         className="StatusCard_m1"
//         style={{ top: position.y, left: position.x }}
//         onMouseDown={handleMouseDown}
//       >
//         <div id="statusCard_m1_card">
//           {" "}
//           <PieChart />
//         </div>
//         <div id="statusCard_m1_card"></div>
//         <div id="statusCard_m1_card"></div>
//         <div id="statusCard_m1_card"></div>
//       </div>
//     </div>
//   );
// };

// export default Test;

//ui for status card

// import React from 'react'
// import './test.css'
// import PieChart from './PieChart'
// import { useGetApi } from '../components/api/UseGetApi';
// const Test = () => {

//   const { getData: LocData } = useGetApi("location/getAll");

//   console.log("LocData", LocData);

//   return (
//     <div className='StatusCard_m1-main'>
//     <div className='Status-card_C1'>
//       <div id='Status-card-s1C1'>
//         <p >4<br/><span>voltVault Units</span></p>
//         <PieChart />
//       </div>
//       <div id='Status-card-s2C1'>
//         <p >NEEDS URGENT ATTENTION</p>
//       </div>
//       <div id='Status-card-s3C1'>
//       <p >NEEDS ATTENTION</p>
//       </div>
//       <div id='Status-card-s4C1'>
//       <p >ALL GOOD</p>
//       </div>
//     </div>
//     </div>
//   )
// }

// export default Test

// cms

// import React, { useState } from 'react';
// // import './App.css';

// const App = () => {
//   const [headings, setHeadings] = useState({
//     heading1: 'Heading 1',
//     heading2: 'Heading 2',
//     heading3: 'Heading 3',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setHeadings({
//       ...headings,
//       [name]: value,
//     });
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>{headings.heading1}</h1>
//         <h2>{headings.heading2}</h2>
//         <h3>{headings.heading3}</h3>
//       </header>
//       <CMS headings={headings} handleInputChange={handleInputChange} />
//     </div>
//   );
// };

// const CMS = ({ headings, handleInputChange }) => {
//   return (
//     <div className="cms">
//       <form>
//         <div>
//           <label>Heading 1:</label>
//           <input
//             type="text"
//             name="heading1"
//             value={headings.heading1}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div>
//           <label>Heading 2:</label>
//           <input
//             type="text"
//             name="heading2"
//             value={headings.heading2}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div>
//           <label>Heading 3:</label>
//           <input
//             type="text"
//             name="heading3"
//             value={headings.heading3}
//             onChange={handleInputChange}
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default App;

// // testing heading utils using api
// import axios from "axios";
// import { useEffect, useState } from "react";

// export const Test = () => {
//   const [getData, setData] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY;
//         const userDetails = JSON.parse(localStorage.getItem("user")).success
//           .token;

//         const response = await axios.get(`${EMS_API_KEY}/cms/getAllHeadings`, {
//           headers: {
//             Accept: "application/json",
//             Authorization: `Bearer ${userDetails}`,
//           },
//         });

//         setData(response.data); // Update state with response.data directly

//         // Log the updated state inside the useEffect or use another useEffect for logging
//         console.log("getData", getData);
//         console.log("getDatamap", getData?.home_page?.status_card);
//       } catch (error) {
//         console.log("errorsGet", error);
//       }
//     };

//     fetchData(); // Call fetchData directly without adding getData to dependency array
//   }, [getData]);

//   return (
//     <>
//       <h1>Headings</h1>
//       {getData?.home_page?.status_card?.map((data) => {
//         return (
//           <>
//             <pre>
//               {data?.heading_name}:{data.headings}
//             </pre>
//           </>
//         );
//       })}
//     </>
//   );
// };

// export default Test;

import React from "react";
import cmsUtils from "../components/utils/CmsUtils";

const Test = () => {
  const { homePageData, detailPageData } = cmsUtils();

  return (
    <div>
      {homePageData && (
        <div>
          <p>Received homePageData:</p>
          {homePageData?.status_card?.map((data) => {
            return (
              <>
                <pre>
                  {data?.heading_name}:{data.headings}
                </pre>
              </>
            );
          })}
          <pre>{JSON.stringify(homePageData, null, 2)}</pre>
        </div>
      )}
      {detailPageData && (
        <div>
          <p>Received detailPageData:</p>
          <pre>{JSON.stringify(detailPageData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default Test;
