// sidebar for mobile view
import React, { useState } from "react";
import "./Sidebar.css";
import BottomReadings from "../cards/FooterReadings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import ModalComponent from "../../pages/dropdown/addPart/Menus";
import ManageComponent from "../../pages/dropdown/managePart/ManageMenus";
import ProfileComponent from "../../pages/dropdown/profile/ProfileMain";

const Sidebar = ({ isExpanded, buttonfn, sidebar, sidebarHome }) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const sidebarClasses = `${sidebar} ${sidebarHome} ${
    isExpanded ? "expanded" : "collapsed"
  } `;

  // const iconStyle = {
  //   marginLeft: "auto",
  //   marginRight: "auto",
  //   width: "40px",
  //   marginTop: "10px",
  // };

  // const shouldDisplayButtonA = location.pathname === "/dashboard";
  const openModal = () => {
    setShowModal(true);
    buttonfn();
  };
  const openManageModal = () => {
    setShowManageModal(true);
    buttonfn();
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeManageModal = () => {
    setShowManageModal(false);
  };
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loggedIn");
    window.location.reload(true);
  };

  const openProfileModal = () => {
    setShowProfileModal(true);
    buttonfn();
  };
  const closeProfileModal = () => {
    setShowProfileModal(false);
  };
  return (
    <div className={sidebarClasses}>
      <div>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={buttonfn}
          style={{ color: "white", padding: "20px" }}
        />
      </div>

      <BottomReadings
        bottomSettings={{ display: "none" }}
        expandCollapseMenu={
          <>
            <FontAwesomeIcon
              // style={iconStyle}
              className="settings-icon_N"
              style={{
                width: "40px",
                transition: "transform 0.3s ease 0s",
                fontSize: "30px",
                color: "white",
                marginLeft: "10px",
              }}
              onClick={() => setOpen(!open)}
              id="icon"
              name="settings"
              icon={faBars}
            />
            <Collapse in={open}>
              <div
                id="example-collapse-text"
                className="sidebar-menu"
                style={{ width: "100%" }}
              >
                <ul className="p-0">
                  <li onClick={openModal}>
                    ADD NEW
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "right" }}
                    />
                  </li>
                  <li onClick={openManageModal}>
                    MANAGE
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "right" }}
                    />
                  </li>
                  <Link
                    onClick={openProfileModal}
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      PROFILE
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ float: "right" }}
                      />
                    </li>
                  </Link>

                  <Link to="billing" style={{ textDecoration: "none" }}>
                    <li>
                      BILLING
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{ float: "right" }}
                      />
                    </li>
                  </Link>
                  <li onClick={logout}>
                    SIGN OUT
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ float: "right" }}
                    />
                  </li>
                </ul>
              </div>
            </Collapse>
          </>
        }
      />

      {showModal && (
        <ModalComponent showModal={showModal} closeModal={closeModal} />
      )}
      {showManageModal && (
        <ManageComponent
          showModal={showManageModal}
          closeModal={closeManageModal}
        />
      )}
      {showProfileModal && (
        <ProfileComponent
          showModal={showProfileModal}
          closeModal={closeProfileModal}
        />
      )}
    </div>
  );
};

export default Sidebar;
