// custom data table 
import React, { useEffect, useState } from "react";
import "./CustomTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../components/expandSearch/Search";
import { ThreeDots } from "react-loader-spinner";

const CustomTable = ({
  columns,
  data,
  handleOpenClick,
  handleDeleteClick,
  isLoading,
  style,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 4; // Number of items to display per page

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Filter data based on search query
  const filteredData = searchQuery
    ? data.filter((item) =>
        Object.values(item).some((value) =>
          value !== null &&
          value !== undefined &&
          typeof value.toString === "function"
            ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      )
    : data;
  // Calculate pagination for filtered data
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    setCurrentPage(1); // Reset current page to 1 when search query changes
  }, [searchQuery]);

  return (
    <div>
      {isLoading ? (
        <div className="loading">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#00a79d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="p-3">
            <SearchBar onSearch={setSearchQuery} />
          </div>
          <table className="Custom_table">
            <thead>
              <tr>
                {columns?.map(({ label, key }, index) => (
                  <th className="custom-table_th" key={index}>
                    {label}
                  </th>
                ))}
                <th key="action" style={{ textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((row, index) => (
                <tr key={index}>
                  {columns?.map(({ key, className, width }) => (
                    <td
                      className={`custom-table_td ${className}`}
                      id={className}
                      key={key}
                      style={{ width: width || "auto" }}
                    >
                      {key === "sno" ? indexOfFirstItem + index + 1 : row[key]}
                    </td>
                  ))}
                  <td
                    key="edit"
                    className="custom-table_td"
                    id="custom-table_action"
                  >
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#356eff"
                      onClick={() => handleOpenClick(row)} // Pass action type and row id
                      style={{ cursor: "pointer", marginRight: "15px" }}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteClick(row.id)} // Pass action type and row id
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="custom-table_pagination_m1 d-flex justify-content-end p-3 pt-4 ">
            <button
              className="cutom-table_pagination"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ cursor: "pointer" }}
              />
            </button>
            {/* <span>
              {currentPage} of {totalPages}
            </span> */}
            <button
              className="cutom-table_pagination"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ cursor: "pointer" }}
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomTable;
