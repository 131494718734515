// usePostData.js
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";


const useFetchData = (apiPara, datas) => {
  const [mainData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const PostData = async () => {
    const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key
    setIsLoading(true);
    try {
      const userDetails = JSON.parse(localStorage.getItem("user"));
      const response = await axios.post(`${EMS_API_KEY}/${apiPara}`, datas, {
        headers: {
          Accept: "application/json",
          "Content-Type": datas?.contentType
            ? datas?.contentType
            : "application/json",
          Authorization: `Bearer ${userDetails.success.token}`,
        },
      });
      setData(response.data);
      setStatus(response);
      if (datas?.swal === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: datas.message ? datas.message : "Added Successfully",

          preConfirm: () => {
            if (datas.fun) {
              datas.fun();
            }
          },
        });
      }
      const toastOptions = {
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      };
      const showToast = datas?.toast === true;
      showToast &&
        Swal.fire({
          ...toastOptions,
          icon: "success",
          title: datas.message || "Added Successfully",
        });

      return true;
    } catch (error) {
      setError(error);
      if (datas?.swal === true) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { mainData, isLoading, error, PostData, status };
};

export default useFetchData;
