import React, { useEffect, useState } from "react";
import "./FooterReadings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "../../pages/dropdown/addPart/Menus";
import ManageComponent from "../../pages/dropdown/managePart/ManageMenus";
import ProfileComponent from "../../pages/dropdown/profile/ProfileMain";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Home } from "../../assets/images/home.svg";
import { Link, useLocation } from "react-router-dom";
import Card from "./navCards/ReadCard";
import { ThreeDots } from "react-loader-spinner";
import useOptimizedGetApi from "../api/useOptimizedGetApi";
import Slider from "../dropdown/Dropdown";

const ReadingCards = (props, displayBottomReadings, homeCenter) => {
  const location = useLocation();
  const today = new Date();
  const month = today.getMonth() + 1;
  const monthName = new Date(today.getFullYear(), month - 1, 1)
    .toLocaleString("default", { month: "short" })
    .replace(/\s\w/g, (match) => match.toUpperCase())
    .replace(/\s/g, "");
  const monthNo = new Date(today.getFullYear(), month - 1).getMonth() + 1;
  const [showModal, setShowModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isError, setIsError] = useState("");
  const [isMonth, setIsMonth] = useState(monthName);
  const [isMonthNo, setIsMonthNo] = useState(monthNo);

  const shouldDisplayButton = location.pathname === "/";
  const shouldDisplayButtonA = location.pathname === "/dashboard";

  const handleIconClick = () => {
    setIsRotated(!isRotated);
  };

  // settings dropdown

  const iconStyle = {
    width: "40px",
    transition: "transform 0.3s ease",
    fontSize: "30px",
  };

  const openModal = () => {
    setShowModal(true);
  };
  const openManageModal = () => {
    setShowManageModal(true);
  };
  const openProfileModal = () => {
    setShowProfileModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeManageModal = () => {
    setShowManageModal(false);
  };
  const closeProfileModal = () => {
    setShowProfileModal(false);
  };

  // logout
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loggedIn");
    window.location.reload(true);
  };

  // api call
  const {
    data: contextData,
    error,
    isLoading: CardLoading,
  } = useOptimizedGetApi(isMonthNo, props.locId);

  useEffect(() => {
    if (error) {
      setIsError(error?.response?.data?.status);
    }
  }, [error]);

  const handleToggle = (isOpen) => setShowDropdown(isOpen);

  return (
    <>
      {shouldDisplayButtonA && <Slider markerId={props} />}

      <div className={displayBottomReadings}>
        <div className="index-bottomCard">
          <div className="menu-icons">
            <Row className="home-dropdown-row">
              <>
                <Col>
                  <Dropdown
                    style={displayBottomReadings}
                    show={showDropdown}
                    onToggle={handleToggle}
                  >
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      name="menu-settings"
                    >
                      <FontAwesomeIcon
                        style={iconStyle}
                        className="settings-icon"
                        onClick={handleIconClick}
                        id="icon"
                        name="settings"
                        icon={faBars}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="dropdown-menu-width">
                      <Dropdown.Item
                        onClick={openModal}
                        id="dropdown-menu-names"
                        className="home-dropdown-menu"
                      >
                        <p id="Menu-names">ADD NEW</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={openManageModal}
                        id="dropdown-menu-names"
                      >
                        <p id="Menu-names"> MANAGE</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={openProfileModal}
                        id="dropdown-menu-names"
                      >
                        <p id="Menu-names"> PROFILE</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Dropdown.Item>

                      <Dropdown.Item
                        id="dropdown-menu-names"
                        as={Link}
                        to="/billing"
                      >
                        <p id="Menu-names"> BILLING</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="dropdown-menu-names"
                        as={Link}
                        to="/viewAll"
                      >
                        <p id="Menu-names"> SUPPORT</p>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <p id="Menu-names" onClick={logout}>
                          SIGN OUT
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </>

              <Col className="home-icon-col">
                <Link to="/">
                  <Home
                    id="home"
                    name="HOME"
                    className="home-icon"
                    style={{ width: "35px", cursor: "pointer" }}
                  />
                </Link>
                {/* )} */}
              </Col>
            </Row>
          </div>
          {shouldDisplayButton && <>{props.expandCollapseMenu}</>}
          {CardLoading ? (
            <div className="Footer-card_loader">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#00a79d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <Card
                name="BILLING PERIOD"
                value={isMonth}
                Calendartrue={true}
                setIsMonth={setIsMonth}
                isMonth={isMonth}
                setIsMonthNo={setIsMonthNo}
              />
              <Card
                name="TOTAL BESS CONSUMPTION"
                value={
                  isError === "failed"
                    ? "0 kWh"
                    : `${
                        contextData?.Total_bess_consumption
                          ? contextData?.Total_bess_consumption
                          : 0
                      } kWh`
                }
              />
              <Card
                name="CURRENT BILL"
                value={
                  isError === "failed"
                    ? "₹ 0"
                    : `₹ ${contextData?.Bill ? contextData?.Bill : 0}`
                }
              />
              <Card
                name="SAVINGS"
                value={
                  isError === "failed"
                    ? "₹ 0"
                    : `₹ ${contextData?.Savings ? contextData?.Savings : 0}`
                }
              />
              <Card
                name="EMISSION REDUCTION"
                value={
                  isError === "failed"
                    ? "0 kgCO2"
                    : `${
                        contextData?.emissionReduction
                          ? contextData?.emissionReduction
                          : 0
                      } kgCO2`
                }
              />
            </>
          )}
        </div>
        {showModal && (
          <ModalComponent showModal={showModal} closeModal={closeModal} />
        )}
        {showManageModal && (
          <ManageComponent
            showModal={showManageModal}
            closeModal={closeManageModal}
          />
        )}
        {showProfileModal && (
          <ProfileComponent
            showModal={showProfileModal}
            closeModal={closeProfileModal}
          />
        )}
      </div>
    </>
  );
};

export default ReadingCards;
