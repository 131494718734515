import React, { useEffect, useState } from "react";
import NestedModal from "../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../components/buttons/saveContinue/ButtonSave";
import usePutApi from "../../../../components/api/UsePutApi";
// import ButtonLoc from "../../../../components/buttons/IndexManage/IndexManage";
import "./Location.css";
import "./Tenant.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../components/api/UseGetApi";
import useFetchData from "../../../../components/api/UseDeleteApi";
import { numberValidation } from "../../../../components/validations/utils";
import { characterValidation } from "../../../../components/validations/utils";
import CustomTable from "../../../../components/table/CustomTable";

const TenantColumns = [
  { id: "id", label: "Tenant ID", key: "sno", width: 80 },
  {
    id: "tenant_name",
    label: "Tenant Name",
    key: "tenant_name",
  },
  {
    id: "contact_name",
    label: "Contact Name",
    key: "contact_name",
  },
  {
    id: "contact_email",
    label: "Contact Email",
    key: "contact_email",
  },
  {
    id: "contact_number",
    label: "Contact Number",
    key: "contact_number",
  },
  {
    id: "contact_designation",
    label: "Contact Designation",
    key: "contact_designation",
  },
];

const InputField = ({ id, label, register, error, errorMessage, ...rest }) => (
  <>
    <input
      //   id="zoomed-input-user"
      className={id ? "zoomed-input" : ""}
      style={{ width: "70%" }}
      {...register}
      aria-invalid={error ? "true" : "false"}
      {...rest}
      placeholder={`${rest.placeholder}*`}
    />
    {error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {errorMessage}
      </p>
    )}
  </>
);

const Location = (props) => {
  const [isTenantData, setIsTenantData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectValues, setSelectValues] = useState({
    tenant_id: "",
    tenant_name: "",
    contact_name: "",
    contact_email: "",
    contact_number: "",
    contact_designation: "",
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [state, setState] = useState({
    userData: [],
    tenantLocData: [],
    userName: [],
    locData: [],
    activeId: null,
    isOpen: false,
    isBtnSaveVisible: false,
    search: "",
    // isLoading: true,
    tenantLocformData: {},
    locFormData: {},
    selectedManager: {},
  });
  const { isOpen, activeId } = state;

  // get Tenants Data

  const { getData: TenantData, isLoading } = useGetApi("tenants/getAll");

  // delete location
  const { DeleteData } = useFetchData("tenants/delete", {
    message: "Tenant Successfully deleted!",
  });

  useEffect(() => {
    setIsTenantData(TenantData);
  }, [TenantData]);

  const handleDelete = async (id) => {
    try {
      // Call the DeleteData function from the hook
      const confirmed = await DeleteData(id);

      if (confirmed) {
        // Update state after deletion
        setIsTenantData(isTenantData?.filter((item) => item.id !== id));
      } else {
        // Deletion canceled
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // handleOpenClick
  const handleOpenClick = (rowId) => {
    setState((prevState) => ({
      ...prevState,
      isOpen: true,
      activeId: rowId,
      isBtnSaveVisible: true,
    }));
    setSelectValues({
      tenant_id: rowId?.id,
      tenant_name: rowId?.tenant_name,
      contact_name: rowId?.contact_name,
      contact_email: rowId?.contact_email,
      contact_number: rowId?.contact_number,
      contact_designation: rowId?.contact_designation,
    });
  };

  const handleGoBackClick = () => {
    reset();
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isBtnSaveVisible: false,
    }));
    // setIsTenantData((prevData) =>
    //   prevData?.map((item) =>
    //     item.id === activeId?.id ? { ...item, ...selectValues } : item
    //   )
    // );
  };

  const { PostData: postDataApi, error } = usePutApi(
    `tenants/update/${activeId?.id}`,
    {
      tenant_name: selectValues?.tenant_name,
      contact_name: selectValues?.contact_name,
      contact_email: selectValues?.contact_email,
      contact_number: selectValues?.contact_number,
      contact_designation: selectValues?.contact_designation,
      organization_id: "2",
      fun: () => handleGoBackClick(),
    }
  );

  useEffect(() => {
    setIsError(error?.response?.data?.error);
    console.log("isError", isError);
  }, [error, isError]);

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await postDataApi();

      console.log("success", success);

      if (success) {
        handleGoBackClick();
        // props.closeModal();
        console.log("props", props);
      } else {
        // Deletion canceled
        setIsTenantData((prevData) =>
          prevData?.map((item) =>
            item.id === activeId?.id ? { ...item, ...selectValues } : item
          )
        );
        // console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleInputChange = ({ target: { name, value } }) =>
    setSelectValues((prevFormData) => ({ ...prevFormData, [name]: value }));

  // console.log("selectValues", selectValues);

  return (
    <div>
      <NestedModal
        modalSize="xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5
              id="dropdown-modal-title"
              style={{ marginLeft: "20px", padding: "15px" }}
            >
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "TENANT" && (
            <>
              <>
                {!isOpen && (
                  <>
                    <CustomTable
                      columns={TenantColumns}
                      data={isTenantData}
                      handleOpenClick={handleOpenClick}
                      handleDeleteClick={handleDelete}
                      isLoading={isLoading}
                    />
                  </>
                )}
                {isOpen && (
                  <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                    <button
                      onClick={handleGoBackClick}
                      className="user-back-btn"
                    >
                      <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                    </button>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        minHeight: "40vh",
                      }}
                    >
                      <InputField
                        id="tenant_name"
                        label="Tenant Name"
                        name="tenant_name"
                        value={selectValues.tenant_name}
                        register={register("tenant_name", {
                          required: "Tenant Name is required",
                        })}
                        error={errors.tenant_name || isError?.tenant_name}
                        errorMessage={
                          errors.tenant_name?.message || isError?.tenant_name
                        }
                        onChange={handleInputChange}
                        placeholder="Tenant Name"
                        onKeyDown={characterValidation}
                      />
                      <InputField
                        id="contact_name"
                        label="contact_name"
                        name="contact_name"
                        value={selectValues.contact_name}
                        register={register("contact_name", {
                          required: "Contact Name is required",
                        })}
                        error={errors.contact_name || isError?.contact_name}
                        errorMessage={
                          errors.contact_name?.message || isError?.contact_name
                        }
                        onChange={handleInputChange}
                        placeholder="Contact Name"
                        onKeyDown={characterValidation}
                      />
                      <InputField
                        id="contact_email"
                        label="contact_email"
                        name="contact_email"
                        value={selectValues.contact_email}
                        register={register("contact_email", {
                          required: "Contact Email is required",
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email format",
                          },
                        })}
                        error={errors.contact_email || isError?.contact_email}
                        errorMessage={
                          errors.contact_email?.message ||
                          isError?.contact_email
                        }
                        onChange={handleInputChange}
                        placeholder="Contact Email"
                      />
                      <InputField
                        id="contact_designation"
                        name="contact_designation"
                        label="Contact Designation"
                        value={selectValues.contact_designation}
                        register={register("contact_designation", {
                          required: "contact_designation is required",
                        })}
                        error={
                          errors.contact_designation ||
                          isError?.contact_designation
                        }
                        errorMessage={
                          errors.contact_designation?.message ||
                          isError?.contact_designation
                        }
                        onChange={handleInputChange}
                        placeholder="Contact Designation"
                      />
                      <InputField
                        id="contact_number"
                        label="contact_number"
                        name="contact_number"
                        value={selectValues.contact_number}
                        // type="text"
                        // maxLength={10}
                        // minLength={10}
                        register={register("contact_number", {
                          required: "Contact Number is required",
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: "Phone number must be exactly 10 digits",
                          },
                        })}
                        error={errors.contact_number || isError?.contact_number}
                        errorMessage={
                          errors.contact_number?.message ||
                          isError?.contact_number
                        }
                        onChange={handleInputChange}
                        placeholder="Contact Number"
                        onKeyDown={numberValidation}
                      />
                    </div>
                  </form>
                )}
              </>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "TENANT" && (
                <>
                  {isOpen && (
                    <BtnSave
                      form="my-form"
                      name="SAVE"
                      onClickFunction={handleSubmit(onSubmit)}
                    />
                  )}
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default Location;
