import React from "react";
import { Button } from "react-bootstrap";

import "./BtnSave.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
const buttonSave = ({ name, onClickFunction, className }) => {
  return (
    <div>
      <Button
        type="submit"
        variant="success"
        className={className}
        id="saveBtn"
        onClick={onClickFunction}
      >
        {name}
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginLeft: "5px" }}
          icon={faChevronRight}
        />
      </Button>
    </div>
  );
};

export default buttonSave;
