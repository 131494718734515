import React from "react";
import "./ProgressBar.css";
const ProgressBar = ({
  label,
  progress,
  progressStyle,
  progressBarWidth,
  progressHeight,
  progressLabel,
}) => {
  return (
    <div className="progress-bar" style={progressBarWidth}>
      <div className="ProgressBar-label">{label}</div>
      <div className="progressBar-main" style={progressHeight}>
        <div // style={{ width: `${progress}%`, backgroundColor: `${progressColor}` }}
          style={progressStyle}
          className="progress-bar-inner-main"
        >
          {/* {`${progress}%`} */}
          {progressLabel}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
