/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import "./ViewAll.css";
import Footer from "../../components/cards/FooterReadings";
import inbox from "../../assets/images/inbox.png";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBell,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faExclamationCircle,
  faInbox,
  faInfoCircle,
  faRotateRight,
  faTicket,
  faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../components/expandSearch/Search";
import StarMain from "../../components/stars/Star";
import usePutAPi from "../../components/api/UsePutApi";
import useFetchData from "../../components/api/UsepostApi";
import { useLocation } from "react-router-dom";
import { Slider } from "@mui/material";
import Modal from "../../contexts/homeModal/HomeModal";
import { useForm } from "react-hook-form";
import { useGetApi } from "../../components/api/UseGetApi";
import { ThreeDots } from "react-loader-spinner";
import TagOthers from "../../components/multiSelect/MultiSelect";
import DetailedPage from "./DetailedPage";

const ViewAllCard = (props) => (
  <div className={props.msgContainer}>
    <div className="msg-img">
      {/* <img src={msgImg} alt="" /> */}
      {props.checkbox}
      <StarMain />
    </div>
    <div className="msg-content" onClick={props.onClick}>
      <div className="msg-head">
        <p style={{ margin: "0px", width: "60%", fontSize: "14px" }}>
          {props.msgHead}
        </p>
      </div>
      <div className="msg-para">
        <p style={{ margin: "0px", fontSize: "small" }}>{props.msgPara}</p>{" "}
      </div>
    </div>
    {props?.type === "notification" ? (
      <div className="msg-action">
        <FontAwesomeIcon
          id="msg-trash"
          onClick={props.onClickTicketId}
          icon={faTicket}
          style={{ marginRight: "10px", color: "red" }}
        />
      </div>
    ) : (
      <div className="msg-action"></div>
    )}

    <div className="msg-action">
      {/* <FontAwesomeIcon id="msg-trash" icon={faTrash} style={{ marginRight: "10px" }} /> */}
      {props.msgTime}
    </div>
  </div>
);

const ViewAll = () => {
  const [showDetailedPage, setShowDetailedPage] = useState(false);
  const [activeMenu, setActiveMenu] = useState("All");
  const [activeId, setActiveId] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [ticketOpen, setTicketOpen] = useState(false);
  const location = useLocation();
  const receivedId = location.state?.id;
  const [showModal, setShowModal] = useState(false);
  const [priority, setPriority] = useState(0);
  const [photo, setPhoto] = useState("");
  const [isTicket, setIsTicket] = useState({
    type: "",
    severity: "",
    message: "",
    subject: "",
    tags: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const userId = JSON.parse(localStorage.getItem("user"));
  // console.log("userId", userId);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    handleReset();
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : filteredData.map((item) => item.id));
  };

  // console.log("selectedItems", selectedItems);
  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const handleCardClick = (item) => {
    setActiveId(item);
    setShowDetailedPage(true);
    // console.log("clicked", item);
    if (item.is_read === 0) {
      notificationStatus({
        // apiPara: `notifications/updateReadStatusByNotificationId/${item.id}`,
        id: item.id,
        showSuccessAlert: false,
      });
    }

    return item;
  };
  const { PostData: notificationStatus } = usePutAPi(
    `support/updateReadStatus`
  );
  // console.log("apiError", apiError);

  const handleReceivedClick = (receivedId) => {
    setActiveId(receivedId);
    setShowDetailedPage(true);
    return receivedId;
  };
  useEffect(() => {
    if (receivedId) {
      handleReceivedClick(receivedId);
    }
  }, [receivedId]);

  const handleBackClick = () => {
    setShowDetailedPage(false);
    refetchData();
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  // getData

  const {
    getData: supportData,
    refetchData,
    isLoading: loading,
  } = useGetApi("support/getAllByOrganization/2");

  // console.log("SupportData", supportData?.support);

  useEffect(() => {
    setData(supportData?.support);
    setItemsPerPage(10);
  }, [supportData]);

  // console.log("data", data);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  // filter data

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth()
    ) {
      // Format the time only
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      // Format the full date
      return date.toLocaleDateString([], {
        month: "short",
        day: "numeric",
      });
    }
  };

  const handleTicket = ({ target: { name, value } }) =>
    setIsTicket((prev) => ({ ...prev, [name]: value }));

  const handleAttach = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleChange = (_, newValue) => {
    setPriority(newValue);
  };

  const handleReset = () => {
    setIsTicket({
      type: "",
      severity: "",
      message: "",
      subject: "",
      tags: "",
    });

    setPriority(0);
  };

  // upload photo in ticket
  const { PostData: PostPhotoTicket } = useFetchData(
    `users/updateuserimage/${userId.success.userId}`,
    {
      image: photo,
      contentType: "multipart/form-data",
      swal: false,
    }
  );

  const filteredData = useMemo(() => {
    setShowDetailedPage(false);
    const lowerCaseSearchTerm = searchTerm?.toLowerCase();
    return data?.filter(
      (item) =>
        (activeMenu === "All" ||
          (activeMenu === "AllNotification" && item.type === "notification") ||
          (activeMenu === "AllTickets" && item.type !== "notification") ||
          (activeMenu === "Critical" && item.status === "Critical") ||
          (activeMenu === "Information" && item.status === "Information") ||
          (activeMenu === "Resolved" && item.status === "Resolved") ||
          (activeMenu === "Open" && item.status === "Open") ||
          (activeMenu === "Close" && item.status === "Close") ||
          (activeMenu === "Pending" && item.status === "Pending")) &&
        (String(item.user_name).toLowerCase().includes(lowerCaseSearchTerm) ||
          String(item.messages).toLowerCase().includes(lowerCaseSearchTerm) ||
          String(item.status).toLowerCase().includes(lowerCaseSearchTerm))
    );
  }, [data, searchTerm, activeMenu]);

  // ticket raise form

  const { PostData } = useFetchData("tickets/raiseTicket", {
    organization_id: "2",
    owner_user_id: userId.success.userId,
    owner_name: userId.success.name,
    owner_email: userId.success.email,
    tags: isTicket.tags?.selectedOptionValues?.join() || "",
    subject: isTicket.subject,
    messages: isTicket.message,
    severity: isTicket.severity,
    priority: priority,
    type: isTicket.type,
    swal: true,
    message: "Ticket raised successfully",
    fun: () => closeModal(),
  });

  // console.log("photos", photo.size);

  const onSubmit = async (data) => {
    try {
      if (photo?.size) {
        PostPhotoTicket();
      }
      // Call the DeleteData function from the hook
      const success = await PostData();

      if (success) {
        setIsTicket({
          type: "",
          severity: "",
          message: "",
          subject: "",
          tags: "",
        });
        reset();

        refetchData().then(() => {});

        // setData((prevData) => ({
        //   ...prevData,
        //   data: prevData?.data?.map((item) =>
        //     item.id !== activeId?.id ? item : { ...item, seen: true }
        //   ),
        // }));
        console.log("filteredData", filteredData);
      } else {
        console.log("Failed to delete data");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div>
      <div className="viewAll-Container">
        {/* <Link to="/home">
        <FontAwesomeIcon className="viewAll-close" icon={faClose} />
      </Link> */}
        {/* <h5 className="viewAll-heading">Support</h5> */}
        <div className="viewAll-sidebar">
          <div className="d-flex flex-column" id="sidebar-menus">
            {/* <h5 className="viewAll-heading">Support</h5> */}
            <button className="menu-ticket_btn" onClick={openModal}>
              <FontAwesomeIcon
                icon={faTicket}
                style={{ marginRight: "10px", color: "#ff0000" }}
              />
              Raise a ticket
            </button>
            <p className="clear-filter" onClick={() => handleMenuClick("All")}>
              Clear Filter
            </p>
            <div
              className={`sidebar-menu-icons`}
              onClick={() => setOpen(!open)}
              aria-controls="collapse-menus"
              aria-expanded={open}
              id="collapse-menus_notification"
            >
              <div>
                <FontAwesomeIcon icon={faBell} className={"sidebarIcon"} />
                <a>Notifications</a>
              </div>
              <span style={{ color: "black", paddingRight: "10px" }}>
                {open ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </span>
            </div>
            <Collapse in={open}>
              <div id="collapse-menus">
                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "AllNotification" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("AllNotification")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faInbox}
                      className={`sidebarIcon ${
                        activeMenu === "AllNotification" ? "active" : ""
                      }`}
                    />
                    <a>All</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {
                      data?.filter((item) => item?.type === "notification")
                        .length
                    }
                  </span>
                </div>

                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Critical" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Critical")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faExclamationCircle}
                      className={`sidebarIcon ${
                        activeMenu === "Critical" ? "active" : ""
                      }`}
                    />
                    <a>Critical</a>
                  </div>{" "}
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {data?.filter((item) => item?.status === "Critical").length}
                  </span>
                </div>

                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Information" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Information")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faInfoCircle}
                      className={`sidebarIcon ${
                        activeMenu === "Information" ? "active" : ""
                      }`}
                    />
                    <a>Information</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {
                      data?.filter((item) => item?.status === "Information")
                        .length
                    }
                  </span>
                </div>
                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Resolved" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Resolved")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faCircleCheck}
                      className={`sidebarIcon ${
                        activeMenu === "Resolved" ? "active" : ""
                      }`}
                    />
                    <a>Resolved</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {data?.filter((item) => item?.status === "Resolved").length}
                  </span>
                </div>
              </div>
            </Collapse>
            <div
              className={`sidebar-menu-icons`}
              onClick={() => setTicketOpen(!ticketOpen)}
              aria-controls="collapse-menus_tickets"
              aria-expanded={open}
              id="collapse-menus_notification"
            >
              <div>
                <FontAwesomeIcon icon={faTicket} className={"sidebarIcon"} />
                <a>Tickets</a>
              </div>
              <span style={{ color: "black", paddingRight: "10px" }}>
                {ticketOpen ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </span>
            </div>
            <Collapse in={ticketOpen}>
              <div id="collapse-menus_tickets">
                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "AllTickets" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("AllTickets")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faInbox}
                      className={`sidebarIcon ${
                        activeMenu === "AllTickets" ? "active" : ""
                      }`}
                    />
                    <a>All</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {
                      data?.filter((item) => item?.type !== "notification")
                        .length
                    }
                  </span>
                </div>

                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Open" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Open")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faTicketSimple}
                      className={`sidebarIcon ${
                        activeMenu === "Open" ? "active" : ""
                      }`}
                    />
                    <a>Open</a>
                  </div>{" "}
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {data?.filter((item) => item?.status === "Open").length}
                  </span>
                </div>

                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Close" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Close")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faCircleXmark}
                      className={`sidebarIcon ${
                        activeMenu === "Close" ? "active" : ""
                      }`}
                    />
                    <a>Close</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {data?.filter((item) => item?.status === "Close").length}
                  </span>
                </div>
                <div
                  className={`sidebar-menu-icons ${
                    activeMenu === "Pending" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("Pending")}
                >
                  <div>
                    <FontAwesomeIcon
                      style={{ marginLeft: "15px" }}
                      icon={faClock}
                      className={`sidebarIcon ${
                        activeMenu === "Pending" ? "active" : ""
                      }`}
                    />
                    <a>Pending</a>
                  </div>
                  <span style={{ color: "black", paddingRight: "10px" }}>
                    {data?.filter((item) => item?.status === "Pending").length}
                  </span>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="viewAll-notification">
          <div className="notification-box">
            <header className="viewAll-header">
              {showDetailedPage ? (
                <>
                  <span>
                    <FontAwesomeIcon
                      onClick={handleBackClick}
                      icon={faArrowLeft}
                      style={{ cursor: "pointer" }}
                      title="back"
                    />
                  </span>

                  {/* <p style={{margin:'0px',marginLeft:'30px'}}>The device has suffered serious damage</p> */}
                </>
              ) : (
                <>
                  <input
                    type="checkbox"
                    id="selectAllCheckbox"
                    className={
                      filteredData?.length === 0
                        ? "viewAll-selectAllCheckbox_disabled"
                        : "viewAll-selectAllCheckbox"
                    }
                    checked={selectAll}
                    onChange={handleSelectAll}
                    title="Select All"
                  />

                  <p className="notification-header-text"> Notification</p>

                  <FontAwesomeIcon
                    className="viewAll-refresh"
                    onClick={refetchData}
                    icon={faRotateRight}
                    title="Refresh"
                  />

                  <div
                    style={{
                      float: "right",
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SearchBar onSearch={handleSearch} />

                    <p
                      className={
                        filteredData?.length === 0
                          ? "msg-page_info_disabled"
                          : "msg-page_info"
                      }
                    >
                      {data?.length
                        ? `${currentPage} - ${data?.length}`
                        : currentPage}{" "}
                      of {""}
                      {data?.length ? data?.length : 0}
                    </p>

                    <button
                      id="msg-backward"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      title="Newer"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    <button
                      id="msg-forward"
                      disabled={
                        currentPage === Math.ceil(data?.length / itemsPerPage)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                      title="Older"
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </div>
                </>
              )}
            </header>
            {showDetailedPage ? (
              <DetailedPage
                userId={userId?.success?.userId}
                message={activeId?.message}
                activeData={activeId}
                onBack={handleBackClick}
                activeDate={formatTimestamp(activeId.created_at)}
                activeId={activeId}
                // userId={id}
              />
            ) : (
              // <div className="viewAll-content">
              <div>
                <>
                  {loading ? (
                    <div className="msg-loading-content">
                      <div className="msg-loading" id="msg-loading">
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#00a79d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {filteredData?.length ? (
                        filteredData
                          .sort(
                            (a, b) =>
                              new Date(a.timestamp) - new Date(b.timestamp)
                          )
                          .slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((item) => {
                            return (
                              <ViewAllCard
                                key={item.id}
                                msgContainer={
                                  item.is_read === 1
                                    ? selectedItems.includes(item.id)
                                      ? "msg-main-card_select"
                                      : "msg-main-card_read"
                                    : selectedItems.includes(item.id)
                                    ? "msg-main-card_select"
                                    : "msg-main-card"
                                }
                                msgHead={
                                  item.type === "notification"
                                    ? item?.status
                                    : item?.user_name
                                }
                                msgPara={item?.messages}
                                msgTime={formatTimestamp(item.created_at)}
                                onClick={() => handleCardClick(item)}
                                type={item.type}
                                onClickTicketId={() => openModal(item.id)}
                                checkbox={
                                  <input
                                    type="checkbox"
                                    className="msg-checkBox_check"
                                    id={`checkbox-${item.id}`}
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                }
                              />
                            );
                          })
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{ width: "50%" }}
                            src={inbox}
                            alt="no message found"
                          />
                        </div>
                      )}
                    </>
                  )}
                  {/* <Neo/> */}
                </>
                <div className="pagination"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <ChatBot /> */}
      <div className="viewAll-footer">
        <Footer />
      </div>
      <Modal
        showModal={showModal}
        MtitleColor="white"
        Mtitle="Raise a Ticket"
        smSize="lg"
        MheadStyle={{
          backgroundColor: "rgb(59 60 60)",
        }}
        closeModal={closeModal}
        Mbody={
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ticket-form__container">
                <div className="ticket_row">
                  <div className="ticket_col">
                    <label htmlFor="type">Type</label>
                    <select
                      name="type"
                      {...register("type", {
                        required: "type is required",
                      })}
                      onChange={handleTicket}
                      defaultValue=""
                      // value={isTicket?.type}
                    >
                      <option value="" disabled>
                        Select Type
                      </option>
                      <option>Infrastructure</option>
                      <option>Battery</option>
                      <option>Software</option>
                    </select>
                    {errors?.type?.message && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors?.type?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="ticket_row">
                  <div className="ticket_col">
                    <label htmlFor="input1">Severity</label>
                    <select
                      name="severity"
                      {...register("severity", {
                        required: "severity is required",
                      })}
                      onChange={handleTicket}
                      defaultValue=""
                      // value={isTicket?.severity}
                    >
                      <option value="" disabled>
                        Select Severity
                      </option>
                      <option>High</option>
                      <option>Good</option>
                      <option>Moderate</option>
                      <option>Low</option>
                      <option>Critical</option>
                    </select>
                    {errors?.severity?.message && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors?.severity?.message}
                      </p>
                    )}
                  </div>
                  <div
                    className="ticket_col"
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <label htmlFor="input1">Priority</label>
                    <Slider
                      step={1}
                      marks
                      min={0}
                      max={10}
                      aria-label="priority"
                      valueLabelDisplay="auto"
                      defaultValue={0}
                      // value={priority}
                      onChange={handleChange}
                      size="medium"
                      name="priority"
                      sx={{
                        width: "80%",
                        color: "black",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
                <div className="ticket_row">
                  <div className="ticket_col">
                    <label htmlFor="input1">Tags</label>
                    {/* <input
                      type="text"
                      name="tags"
                      {...register("tags", {
                        required: "tags is required",
                      })}
                      placeholder="Tag others"
                      onChange={handleTicket}
                      // value={isTicket?.tags}
                    /> */}
                    <TagOthers
                      name="tags"
                      register={register("tags")}
                      setTicket={(tags) => {
                        setIsTicket((prev) => ({
                          ...prev,
                          tags,
                        }));
                      }}
                    />

                    {errors.tags && isTicket.tags && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors.tags.message}
                      </p>
                    )}

                    {/* {errors?.tags?.message && !isTicket.tags && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors?.tags?.message}
                      </p>
                    )} */}
                  </div>
                </div>

                <div className="ticket_row">
                  <div className="ticket_col">
                    <label htmlFor="input1">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      {...register("subject", {
                        required: "subject is required",
                      })}
                      onChange={handleTicket}
                      placeholder="Subject"
                      // value={isTicket.subject}
                    />
                    {errors?.subject?.message && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors?.subject?.message}
                      </p>
                    )}
                  </div>

                  <div className="ticket_col">
                    <label htmlFor="input1">Attachment</label>
                    <input
                      type="file"
                      name="attachment"
                      className="ticket-attachment"
                      {...register("attachment")}
                      placeholder=" Attachment"
                      onChange={handleAttach}
                      // value={isTicket?.attachment}
                    />
                  </div>
                </div>
                <div className="ticket_row">
                  <div className="ticket_col">
                    <label htmlFor="input1">Message</label>
                    <textarea
                      name="message"
                      className="ticket-textarea"
                      {...register("message", {
                        required: "Message is required",
                      })}
                      onChange={handleTicket}
                      placeholder="Enter your message"
                      // value={isTicket?.message}
                    />
                    {errors?.message?.message && (
                      <p style={{ color: "red" }}>{errors?.message?.message}</p>
                    )}
                  </div>
                </div>

                <button
                  style={{ float: "right", marginLeft: "10px" }}
                  className="btn btn-dark"
                  // onClick={PostTicket}
                >
                  Send
                </button>
                {/* <button
                  style={{ float: "right" }}
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Reset
                </button> */}
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default ViewAll;
