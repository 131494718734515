// api.js
import axios from "axios";
const FetchData = async ({ apiPara }) => {
  const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key
  const userDetails = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.get(`${EMS_API_KEY}/${apiPara}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${userDetails.success.token}`,
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export default FetchData;
