// location detail page dropdown component
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DragButton from "../cards/DragBtn";
import { Collapse } from "react-bootstrap";
import "./Dropdown.css";

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setData(props?.markerId);
  }, [props?.markerId]);

  return (
    <div>
      <div
        className={
          props.mainClass ? props.mainClass : "index-bottomCard-location"
        }
      >
        <p
          onClick={() => setOpen(!open)}
          className={
            open ? `locName-button-expand ${props.subClass}` : `locName-button `
          }
        >
          {data?.markerId?.location_name}

          {open ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </p>
        <Collapse in={open}>
          <div id="example-collapse-text" className="index-expan-location">
            {data?.markerId && <hr />}

            <p>{data?.markerId?.address}</p>
            {data?.markerId && <hr />}
            <p className="mb-0">
              {data?.markerId
                ? data?.markerId?.firstname + " " + data?.markerId?.lastname
                : ""}
            </p>
            <a
              id="sitemanager-email_ecclipse"
              href={`mailto:${data?.markerId?.email}`}
              className="mb-0"
            >
              <p className="mb-0">
                {data?.markerId ? data?.markerId?.email : ""}
              </p>
            </a>
            <a
              id="sitemanager-email_ecclipse"
              href={`tel:${data?.markerId?.phone}`}
              className="mb-0"
            >
              <p className="mb-0">
                {data?.markerId ? data?.markerId?.phone : ""}
              </p>
            </a>
            {data?.markerId && <hr />}
            <div className="page-layout_dropdown">
              Page Layout <DragButton />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Dropdown;
