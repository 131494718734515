import React, { useState } from "react";
import "./Search.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBar = ({ onSearch }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleSearchIconClick = () => {
    setExpanded(!isExpanded);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    onSearch(inputValue);
  };
  return (
    <div className={`search-bar__expanded ${isExpanded ? "expanded" : ""}`}>
      <div className="search-icon" onClick={handleSearchIconClick}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
      {isExpanded && (
        <input
          type="text"
          onChange={handleInputChange}
          placeholder="Search..."
          className="search-input"
        />
      )}
    </div>
  );
};

export default SearchBar;
