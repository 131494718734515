import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
// import "./sec1.css";
import "./ConverterMain.css";
import { useDrag } from "../../../../../contexts/drag/Button";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import  HeadingUtils  from "../../../../../components/utils/headingUtils";
import Converters from "./content/Converter";
import { ThreeDots } from "react-loader-spinner";

const ExpandCollapseCard = ({ title, content, headerButtons }) => {
  const [open, setOpen] = useState(false);
  const { drag } = useDrag();

  const toggleCard = () => {
    setOpen(!open);
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        cursor: "pointer",
        marginBottom: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <Card.Header
        className="d-flex justify-content-between align-items-center"
        id={drag ? "abcd-container" : "header"}
      >
        <Card.Title id="title" className="converter-title" onClick={toggleCard}>
          {title}
        </Card.Title>
        <div className="ml-auto">{headerButtons}</div>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-card-content">
          <Card.Body
            id="cardBody"
            style={{
              borderTop: "1px solid black",
              overflow: "scroll",
              padding: "20px 30px",
            }}
          >
            {content}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

const App = ({ locId }) => {
  const { dashboardHeadingUtils } = HeadingUtils();
  const [converters, setConverters] = useState([]);
  const [status, setStatus] = useState("ACTIVE");

  const {
    getData: ConvertersList,
    isLoading,
    error,
  } = useGetApi(`bess/ac-dc-converter-list/${locId}`);


  useEffect(() => {
    setConverters(ConvertersList?.acdcConverterData);
  }, [ConvertersList]);

  const handleActiveClick = () => {
    console.log("Active clicked");
    setStatus("ACTIVE");
    setConverters(
      ConvertersList?.acdcConverterData.filter(
        (item) => item.status === "ACTIVE"
      )
    );
  };
  const handleInActiveClick = () => {
    setStatus("INACTIVE");
    setConverters(
      ConvertersList?.acdcConverterData.filter(
        (item) => item.status === "INACTIVE"
      )
    );
  };

  

  const cardData = [
    {
      title: dashboardHeadingUtils?.CARD_B,
      content: (
        <>
          {error?.response?.data?.status === "failed" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p style={{ color: "black", margin: "0px", fontSize: "12px" }}>
                No data available.
              </p>
            </div>
          ) : (
            <>
              <>
                {isLoading ? (
                  <div className="loading">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#00a79d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <Converters converters={converters} isLoading={isLoading} status={status} />
                )}
              </>
            </>
          )}
        </>
      ),
      headerButtons: (
        <div className="Converters-container">
          <div className="respo-container" onClick={handleActiveClick}>
            <div
              className="responsive-div"
              style={{ paddingRight: "10px", paddingLeft: "15px" }}
            >
              <h6 style={{ fontSize: "11px" }}>
                {ConvertersList?.active ? ConvertersList?.active : 0}
              </h6>
            </div>
            <div
              className="side-div-active"
              style={{ padding: "2px 13px 0px 13px" }}
            >
              <h6 style={{ fontSize: "11px" }}>ACTIVE </h6>
            </div>
          </div>

          <div
            className="respo-container"
            style={{ marginLeft: "10px" }}
            onClick={handleInActiveClick}
          >
            <div
              className="responsive-div"
              style={{ paddingRight: "9px", paddingLeft: "14px" }}
            >
              <h6 style={{ fontSize: "11px" }}>
                {ConvertersList?.inactive ? ConvertersList?.inactive : 0}
              </h6>
            </div>
            <div className="side-div">
              <h6 style={{ fontSize: "11px" }}>INACTIVE </h6>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      {/* <MapWithAddress /> */}
      <div className="card-container">
        {cardData.map((card, index) => (
          <ExpandCollapseCard
            className="resizable-card-content"
            key={index}
            title={card.title}
            content={card.content}
            headerButtons={card.headerButtons}
          />
        ))}
      </div>
    </div>
  );
};

export default App;
