// useDeleteData.js
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const useFetchData = (apiPara, datas) => {
  //   console.log("datas", datas);
  const [mainData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const DeleteData = async (
    rowId,
    setIsTenantData,
    setLocationData,
    setTenLocData,
    setIsUserData
  ) => {
    const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key
  
    const userDetails = JSON.parse(localStorage.getItem("user"));

    if (!userDetails) {
      // Handle user details not found in session storage
      return;
    }

    // Show confirmation
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${EMS_API_KEY}/${apiPara}/${rowId}`,

          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userDetails.success.token}`,
            },
          }
        );
        setData(response?.data);
        setStatus(response);

        // success message

        Swal.fire("Deleted!", `${datas.message}`, "success");
        return true; // Deletion confirmed
      } catch (error) {
        // Show specific error message if available
        console.log("error", error);
        console.log("error12", error.response?.data?.message);
        const errorMessage =
          error.response?.data?.message || "Something went wrong!";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
        return false; // Deletion canceled due to error
      } finally {
        setIsLoading(false);
      }
    } else {
      return false; // Deletion canceled
    }
  };

  return { mainData, isLoading, DeleteData, status };
};

export default useFetchData;
