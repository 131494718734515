// // import React, { useContext } from "react";

// // // Step 1: Create a context
// // const ThemeContext = React.createContext("light");

// // // Step 2: Provide the context value at the top of the component tree
// // function App() {
// //   return (
// //     <ThemeContext.Provider value="dark">
// //       <Toolbar />
// //     </ThemeContext.Provider>
// //   );
// // }

// // // Step 3: Consume the context value
// // function Toolbar() {
// //   return (
// //     <div>
// //       <ThemedButton />
// //     </div>
// //   );
// // }

// // function ThemedButton() {
// //   const theme = useContext(ThemeContext);
// //   return (
// //     <button
// //       style={{
// //         background: theme === "dark" ? "black" : "white",
// //         color: theme === "dark" ? "white" : "black",
// //       }}
// //     >
// //       Button
// //     </button>
// //   );
// // }

// // import React, { useRef, useState } from "react";

// // const NewTest = () => {
// //   const inputRef = useRef();
// //   const [data, setData] = useState();

// //   const handleData = (e) => {
// //     const { name, value } = e.target;
// //     setData((prev) => ({
// //       ...prev,
// //       [name]: value,
// //     }));
// //   };
// //   console.log("data", data);

// //   return (
// //     <div>
// //       <input type="text" name="firstName" onChange={handleData} />
// //       <input type="text" name="lastName" onChange={handleData} ref={inputRef} />
// //       <button onClick={() => inputRef.current.focus()}>focus</button>
// //     </div>
// //   );
// // };

// // export default NewTest;

import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const MapComponent = () => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 21.7679,
    lng: 78.8718,
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={5}
      />
    </LoadScript>
  );
};

export default MapComponent;

