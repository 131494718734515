// import React, { useState } from "react";
import "./App.css";
import Router from "./routes/Router";
import Login from "./pages/auth/Login";
import { useState } from "react";
import Logout from "./routes/Logout";

function App() {
  // checking the login status
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("loggedIn"));

  return (
    <>
      <div className="App">
        {loggedIn ? <Router /> : <><Login setLoggedIn={setLoggedIn} /> <Logout /></>}
      </div>
    </>
  );
}

export default App;
