import React, { useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "./Billing.css";

export default function TopSearch() {
  const actionButton = (
    <>
      <button style={{ backgroundColor: "transparent", border: "none" }}>
        <FontAwesomeIcon icon={faEye} />
      </button>
    </>
  );

  const [datatable] = useState({
    columns: [
      {
        label: "ID Invoice",
        field: "id",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: "Due Date",
        field: "date",
        width: 270,
      },
      {
        label: "Client",
        field: "client",
        width: 200,
      },
      {
        label: "Contact",
        field: "contact",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
        content: actionButton,
      },
    ],
    rows: [
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Pending</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
      {
        id: "#INV-0001234",
        date: "2012/03/29",
        client: "Edinburgh",
        contact: "sachin@gmail.com",
        status: <span>Completed</span>,
        amount: "₹320",
        action: actionButton,
      },
    ],
  });

  return (
    <MDBDataTableV5
      hover
      entriesOptions={[5, 20, 25]}
      entries={5}
      pagesAmount={4}
      data={datatable}
      searchTop
      searchBottom={false}
    />
  );
}
