// render <DragBtn> within a suitable container
import React from "react";
import { useDrag } from "../../contexts/drag/Button";
import { MDBSwitch } from "mdb-react-ui-kit";

const DragBtn = () => {
  const { drag, setDrag } = useDrag();
  const handleSwitchChange = () => {
    setDrag(!drag);
  };
  return (
    <div> 
      <MDBSwitch
        checked={drag}
        onChange={handleSwitchChange}
        title="Drag cards to reorder"
      />
    </div>
  );
};

export default DragBtn;
