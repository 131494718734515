import React from "react";
import GaugeChart from "react-gauge-chart";
import "./Gauge.css";

const GaugeMeter = () => {
  return (
    <div
      id="header"
      style={{ marginRight: "10px", marginBottom: "20px", marginLeft: "10px" }}
    >
      <div style={{ display: "flex" }}>
        <GaugeChart
          id="gauge-chart1"
          nrOfLevels={20}
          percent={0.76}
          arcPadding={0.02}
          textColor="#333"
        />
        <GaugeChart
          id="gauge-chart1"
          nrOfLevels={20}
          percent={0.16}
          arcPadding={0.02}
          textColor="#333"
        />
      </div>
    </div>
  );
};

export default GaugeMeter;
