import React, { useState } from "react";
import "./BillingNew.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faClose,
  faCreditCard,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import Invoice from "./table";
import { Link } from "react-router-dom";

const BillingNew = () => {
  const [activeTab, setActiveTab] = useState(2);

  const Contents = {
    PaymentStatus: "Pending",
    PaymentDate: "02/29/2024",
    AmountDue: "₹25400.00",
    InvoiceId: "#INV-0001234",
    InvoiceDate: "02/25/2024",
    BillingAddress: "Bangalore",
    DueDate: "03/25/2024",
  };

  const tabs = [
    {
      id: 0,
      label: "Usage/Consumption",
      content: "This is the Usage tab.",
      icon: <FontAwesomeIcon fontSize={"25px"} icon={faBolt} />,
    },
    {
      id: 1,
      label: "Accounts",
      content: "This is the Accounts tab.",
      icon: <FontAwesomeIcon fontSize={"25px"} icon={faUserCircle} />,
    },
    {
      id: 2,
      label: "Billing",
      footer: (
        <footer className="Billing-footer-main">
          <>
            <Link to="/">
              {" "}
              <button id="goBack">GO BACK</button>
            </Link>
          </>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: "10px", margin: "0px" }}>
              By clicking Check out button you agree to our terms and
              conditions.
              <br />
              Thank you for trusting our service
            </p>
            <button id="checkOut">CHECK OUT</button>
          </div>
        </footer>
      ),
      content: (
        <div className="billing-container">
          <div className="billing-header">{/* <h3>Billing</h3> */}</div>
          <div className="billing-body">
            <div className="billing-body__card1">
              <h6 style={{ textAlign: "center" }}>Invoice List</h6>
              <Invoice />
            </div>
            <div className="billing-body__card2">
              <div id="billing-card-status">
                <p>Payment Date : {Contents.PaymentDate}</p>
                <h5>
                  Payment Status :{" "}
                  <span style={{ color: "red" }}>{Contents.PaymentStatus}</span>
                </h5>
              </div>
              <div id="billing-card-amount">
                <p>Amount Due</p>
                <h5>{Contents.AmountDue}</h5>
              </div>
              <div id="billing-card-details">
                <div className="InvoiceDetails">
                  <div>
                    <p>Invoice ID</p>
                    <h6>{Contents.InvoiceId}</h6>
                  </div>
                  <div>
                    <p>Invoice Date</p>
                    <h6>{Contents.InvoiceDate}</h6>
                  </div>
                </div>
                <div className="BillingAddress">
                  <div>
                    <p>Billing Address</p>
                    <h6>{Contents.BillingAddress}</h6>
                  </div>
                  <div>
                    <p>Due Date</p>
                    <h6>{Contents.DueDate}</h6>
                  </div>
                </div>
              </div>
              <div className="PaymentDetails">
                {/* <p>Payment Status : Pending</p> */}
                <h6>
                  Amount :{" "}
                  <span style={{ color: "#35d635" }}>{Contents.AmountDue}</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ),
      icon: <FontAwesomeIcon fontSize={"25px"} icon={faCreditCard} />,
    },
  ];
  const billingCard = {
    padding: "20px",
    paddingBottom: "5px",

    // background: 'linear-gradient(24deg, rgb(255 255 255) 0%, rgb(198 200 204) 100%)',
  };
  return (
    <div style={billingCard}>
      <header className="Billing-header-tab">
        <Link to="/">
          <FontAwesomeIcon
            style={{ float: "right", cursor: "pointer", color: "black" }}
            icon={faClose}
          />
        </Link>
        <h3>{tabs[activeTab].label}</h3>
      </header>
      <div className="Billing-Tabs">
        <div className="tab-bar">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`Billing-header__tab ${
                tab.id === activeTab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <p> {tab.icon}</p>
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="Billing-content-main">
        <div className="tab-content">
          {tabs[activeTab].content}
          {tabs[activeTab].footer}
        </div>
      </div>
    </div>
  );
};

export default BillingNew;
