import React from "react";
import ProgressBar from "../../../../../../components/progressbar/skew/Skew";

const progressColor = {
  backgroundColor: "green",
};

const progressBarWidth = {
  width: "135px",
};
const progressHeight = {
  height: "23px",
};

const renderProgressBar = (label, progress, progressAColor) => {

  return (
    <ProgressBar
      label={label}
      progress={progress}
      progressColor={progressAColor ? progressAColor : progressColor}
      progressBarWidth={progressBarWidth}
      progressHeight={progressHeight}
      progressBarInnerAbc="progress-bar-innerB"
    />
  );
};
const renderAProgressBar = (label, progress, progressCColor) => {

  return (
    <ProgressBar
      label={label}
      progress={progress}
      progressColor={progressCColor ? progressCColor : progressColor}
      progressBarWidth={progressBarWidth}
      progressHeight={progressHeight}
      progressBarInnerAbc="progress-bar-innerC"
    />
  );
};

const PowerScheme = (props) => {

  return (
    <div className="w-100 d-flex flex-wrap  ">
      {props?.battery?.map((item, index) => (
        <React.Fragment key={index}>
          {(item.charging_status === 1 ||
            (item.charging_status === 0 && item.discharge_status === 1)) &&
            renderAProgressBar(`B${item?.battery_id}`, item?.soc)}
          {item.charging_status === 0 &&
            item.discharge_status === 0 &&
            renderProgressBar(`B${item?.battery_id}`, item?.soc)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PowerScheme;
