import axios from "axios";
import { useEffect, useState } from "react";

const CmsUtils = () => {
  const [homePageData, setHomePageData] = useState(null);
  const [detailPageData, setDetailPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY;
        const userDetails = JSON.parse(localStorage.getItem("user"))?.success
          ?.token;

        const response = await axios.get(
          `${EMS_API_KEY}/cms/getAllHeadingsById/1`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${userDetails}`,
            },
          }
        );

        setHomePageData(response.data?.home_page);
        setDetailPageData(response.data?.detail_page);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Placeholder for loading indicator
  }

  if (error) {
    return <p>Error: {error.message}</p>; // Placeholder for error handling
  }


  return {
    homePageData,
    detailPageData,
  };
};

export default CmsUtils;
