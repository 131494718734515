import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ChartComponent = (props) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    if (props.clientData?.months) {
      setData(
        Object.values(props.clientData?.months).flatMap((monthDataArray) =>
          monthDataArray?.filter((monthData) => monthData)
        )
      ); // Extract values from the object
    } else {
      console.error("Data is not in the expected format");
    }
    if (props?.filterClient?.months) {
      setFilteredData(
        Object.values(props?.filterClient?.months || {}).filter(
          (monthData) => monthData
        )
      );
    }
  }, [props.clientData, props?.filterClient]);

  // Update color map whenever data changes
  useEffect(() => {
    const allData = [...data, ...filteredData];
    const tenantNames = allData.map((monthData) => monthData.tenantName);
    const uniqueTenantNames = [...new Set(tenantNames)];
    const newColorMap = {};
    uniqueTenantNames.forEach((name, index) => {
      newColorMap[name] =
        chartOptions.colors[index % chartOptions.colors.length];
    });
    setColorMap(newColorMap);
  }, [data, filteredData]);

  const accumulateData = (data, property) => {
    const filter = data;

    const accumulatedData = filter.reduce((acc, monthData, index) => {
      const tenantName = monthData.tenantName;
      if (!acc[tenantName]) {
        acc[tenantName] = {
          currentMonth: 0,
          lastMonth: 0,
          lastToLastMonth: 0,
        };
      }

      const value = filteredData.length ? monthData : monthData[property] || 0;

      if (filteredData?.length && index >= filter.length - 3) {
        const { kvah_grid = 0, kvah_renewable = 0, kvah_bess = 0 } = value; // Use default values to avoid NaN
        const currentMonthValue =
          Math.round((kvah_grid + kvah_renewable + kvah_bess) * 100) / 100;
        acc[tenantName].currentMonth += currentMonthValue;
      } else {
        acc[tenantName].currentMonth += value;
      }
      return acc;
    }, {});

    return accumulatedData;
  };

  // console.log("props?.filterClient", props?.filterClient);
  const renderCharts = () => {
    if (data.length === 0) return null;

    const categories = Array.from(
      new Set(
        filteredData.length
          ? data
              .filter((monthData) => monthData.monthName)
              .map((monthData) => monthData.monthName)
          : data
              .filter((monthData) => monthData.monthName)
              .map((monthData) => monthData.monthName)
      )
    );

    // console.log("filteredData", filteredData);
    // console.log("data", data);
    // console.log("data", data);

    const currentMonth = new Date().toLocaleString("default", {
      month: "long" || "short",
    });

    const currentData = filteredData?.length ? filteredData : data; // Use filteredData if available
    const currentMonthData = currentData.filter(
      (monthData) =>
        monthData.monthName ===
        currentData
          .map((data) => data.monthName)
          .find((monthName) => monthName === currentMonth)
    );
    const lastMonthData = currentData.filter(
      (monthData) => monthData.monthName === categories[categories.length - 1]
    );
    const lastToLastMonthData = currentData.filter(
      (monthData) => monthData.monthName === categories[categories.length - 2]
    );

    // console.log("currentMonthData", currentMonthData);
    // console.log("lastMonthData", lastMonthData);
    // console.log("lastToLastMonthData", lastToLastMonthData);

    // Accumulate data for each month
    const { activeBtn, activeNBtn } = props;
    const accumulationProperty =
      activeBtn === "GRID" && activeNBtn === "GRID"
        ? "kvah_grid"
        : activeBtn === "BESS" && activeNBtn === "BESS"
        ? "kvah_bess"
        : "kvah_renewable";

    const accumulateDataFn = (data) =>
      accumulateData(data, accumulationProperty);

    const currentMonthAccumulated = filteredData.length
      ? accumulateDataFn(currentMonthData)
      : accumulateDataFn(currentMonthData);

    const lastMonthAccumulated = accumulateDataFn(lastMonthData);
    const lastToLastMonthAccumulated = accumulateDataFn(lastToLastMonthData);

    console.log("currentMonthAccumulated", currentMonthAccumulated);
    console.log("lastMonthAccumulated", lastMonthAccumulated);
    console.log("lastToLastMonthAccumulated", lastToLastMonthAccumulated);

    const seriesData = Object.keys(currentMonthAccumulated).map(
      (tenantName) => ({
        name: tenantName,
        data: [
          currentMonthAccumulated[tenantName]?.currentMonth || 0,
          lastToLastMonthAccumulated[tenantName]?.currentMonth || 0,
          lastMonthAccumulated[tenantName]?.currentMonth || 0,
        ],
      })
    );

    // console.log("seriesData", seriesData);
    const seriesWithColors = seriesData.map((series) => ({
      ...series,
      color: colorMap[series.name],
    }));
    // console.log("activeNBtn", props.activeBtn);
    // console.log("activeNewBtn", props.activeNBtn);
    return (
      <div className="chart-container">
        <div className="chart-card">
          <ReactApexChart
            options={{
              ...chartOptions,
              xaxis: { categories },
            }}
            series={seriesWithColors}
            type="bar"
            height={200}
          />
        </div>
      </div>
    );
  };

  return <div className="chart-container">{renderCharts()}</div>;
};

const chartOptions = {
  colors: ["#23a4d9", "#1074bc", "#27378d", "#11a097"],

  dataLabels: { enabled: false },
  chart: {
    type: "bar",
    height: 250,
    stacked: true,
    toolbar: { show: true },
    zoom: { enabled: true },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: { position: "bottom", offsetX: -10, offsetY: 0 },
      },
    },
  ],
  grid: { show: false },
  yaxis: { show: false },
  fill: { opacity: 1 },
  legend: { show: false, position: "right", offsetX: 0, offsetY: 50 },
};

export default ChartComponent;
