export function numberValidation(e) {
  if (e.key === "Backspace" || e.key === "Delete") {
    // Allow backspace and delete
    return;
  }
  if (e.key === "ArrowLeft") {
    // Code to handle the left arrow key
    return;
  } else if (e.key === "ArrowUp") {
    // Code to handle the up arrow key
    return; 
  } else if (e.key === "ArrowRight") {
    // Code to handle the right arrow key
    return;
  } else if (e.key === "ArrowDown") {
    // Code to handle the down arrow key
    return;
  } else if (e.key === "Tab") {
    // Code to handle the Tab key
    return;
  }
  // Prevent non-numeric characters from being entered
  if (!/^[0-9]+$/.test(e.key)) {
    e.preventDefault();
  }
}
export function characterValidation(e) {
  if (e.ctrlKey || e.metaKey || e.shiftKey) {
    // Allow control and shift keys
    return;
  } else if (/[^a-zA-Z\s]/.test(e.key)) {
    e.preventDefault();
  }
}
// allows - and _ in input
export function SpecialCharacterValidation(e) {
  if (e.ctrlKey || e.metaKey) {
    e.preventDefault();
  } else if (!/^([a-zA-Z\s-\s_])+$/.test(e.key)) {
    e.preventDefault();
  }
}
