import React, { useEffect, useState } from 'react';
import axios from 'axios';

const api = axios.create({
  baseURL: 'http://localhost:1337/admin', // Ensure this is the correct URL
});

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await api.get('/Article'); // Adjust the endpoint as needed
        setArticles(response.data.data); // Strapi v4 returns data in `data.data`
        setLoading(false);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Articles</h1>
      {articles?.length > 0 ? (
        <ul>
          {articles?.map((article) => (
            <li key={article.id}>
              <h2>{article.attributes.title}</h2>
              <p>{article.attributes.content}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No articles found.</p>
      )}
    </div>
  );
};

export default Articles;
