import React, { useState } from "react";
import "./Login.css";
import Box from "@mui/material/Box";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; // API key

function Login({ setLoggedIn }) {
  const [NewPassword, setNewPassword] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  // Input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  // Login function
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const { email, password } = credentials;

    try {
      setLoading(true);
      const response = await axios.post(
        `${EMS_API_KEY}/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to log in");
      }

      const data = response.data;

      if (data) {
        setLoading(false);
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("loggedIn", JSON.stringify(data));
        setLoggedIn(true);

        window.location.pathname = "/";
      }
    } catch (error) {
      setLoading(false);
      setLoggedIn(false);
      setError(error?.response?.data?.error || "An error occurred");
    }
  };

  // Toggle password visibility
  const handleClickShowPasswordA = () => setNewPassword((show) => !show);

  // Handle "Enter" key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin(e); // Call handleLogin and pass event to prevent default
    }
  };

  return (
    <div className="LoginContainer">
      <div className="login-card-main">
        <div>
          <h4
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
              marginBottom: "30px",
            }}
          >
            LOGIN
          </h4>
          <form onSubmit={handleLogin}>
            <Box component="form" noValidate autoComplete="off">
              <div className="group">
                <input
                  value={credentials.email}
                  onChange={handleInputChange}
                  name="email"
                  className="floating-input"
                  type="text"
                  required
                  onKeyPress={handleKeyPress} // Add this handler
                />
                <label id="floating-label">Email</label>
              </div>
              <p
                style={{ color: "red", marginBottom: "25px", fontSize: "12px" }}
              >
                {error ? <label>{error?.email}</label> : null}
              </p>
            </Box>
            <div className="group">
              <input
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                className="floating-input"
                required
                type={NewPassword ? "text" : "password"}
                onKeyPress={handleKeyPress} // Add this handler
              />
              <span className="highlight"></span>
              <span className="bar">
                <InputAdornment
                  position="end"
                  style={{ color: "rgba(0,0,0,.54)" }}
                >
                  <IconButton
                    style={{ marginLeft: "auto", marginBottom: "36px" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordA}
                    edge="end"
                  >
                    {NewPassword ? (
                      <VisibilityOff style={{ color: "rgba(0,0,0,.54)" }} />
                    ) : (
                      <Visibility style={{ color: "rgba(0,0,0,.54)" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              </span>
              <label id="floating-label">Password</label>
              <p
                style={{ color: "red", marginBottom: "0px", fontSize: "12px" }}
              >
                {error && <label>{error.password || error}</label>}
              </p>
              <a
                href="*"
                style={{
                  float: "right",
                  color: "#524d4d",
                  textDecoration: "none",
                  margin: "10px",
                }}
              >
                {/* I forgot password */}
              </a>
            </div>
            <button type="submit" className="Login-btn">
              {loading ? (
                <ThreeDots
                  height="25"
                  width="30"
                  radius="9"
                  color="#00a79d"
                  ariaLabel="three-dots-loading"
                  visible={true}
                />
              ) : (
                <>LOGIN</>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
