// import useCmsData from "./CmsUtils";

const HeadingUtils = () => {
  // const { homePageData, detailPageData } = cmsUtils();

  // Define constants for dashboard heading and status card utils
  const dashboardHeadingUtils = {
    CARD_A: "MAINS",
    CARD_A_SUB: (value) => `Main Meter - ${value} kVAh`,
    CARD_B: "AC/DC CONVERTERS",
    CARD_C: "CABINET (AIR TEMPERATURE)",
    CARD_D: "Battery Data",
    CARD_D_SUB: (value) => `TOTAL AVAILABLE ENERGY - ${value}%`,
    CARD_E: "CLIENT CHANNELS",
    CARD_F: "CONSUMPTION",
    CARD_G: "POWER SCHEME",
    CARD_H: "BESS Energy Status",
  };

  const statusCardUtils = {
    TOTAL: "ALL TOWERS",
    URGENT: "NEEDS URGENT ATTENTION",
    ATTENTION: "NEEDS ATTENTION",
    GOOD: "ALL GOOD",
    SUB_TOTAL: "TOWERS",
  };

  return { dashboardHeadingUtils, statusCardUtils };
};

export default HeadingUtils;
