import React, { useState } from "react";

// // step one
const StepOne = ({ nextStep, handleChange, values }) => {
  const { firstName, lastName } = values;

  return (
    <div>
      <h2>Step 1: Personal Information</h2>
      <label>First Name:</label>
      <input
        type="text"
        name="firstName"
        value={firstName}
        onChange={handleChange}
      />
      <br />
      <label>Last Name:</label>
      <input
        type="text"
        name="lastName"
        value={lastName}
        onChange={handleChange}
      />
      <br />
      <button onClick={nextStep}>Next</button>
    </div>
  );
};
const StepTwo = ({ nextStep, handleChange, values, prevStep }) => {
  const { firstName, lastName } = values;

  return (
    <div>
      <h2>Step 2: Contact Details</h2>
      <label>First Name:</label>
      <input
        type="text"
        name="firstName"
        value={firstName}
        onChange={handleChange}
      />
      <br />
      <label>Last Name:</label>
      <input
        type="text"
        name="lastName"
        value={lastName}
        onChange={handleChange}
      />
      <br />
      <button onClick={nextStep}>Next</button>
      <button onClick={prevStep}>Previous</button>
    </div>
  );
};
const StepThree = ({ handleChange, values, prevStep }) => {
  const { firstName, lastName } = values;

  return (
    <div>
      <h2>Step 3 : Account Details</h2>
      <label>First Name:</label>
      <input
        type="text"
        name="firstName"
        value={firstName}
        onChange={handleChange}
      />
      <br />
      <label>Last Name:</label>
      <input
        type="text"
        name="lastName"
        value={lastName}
        onChange={handleChange}
      />
      <br />
      <button>Submit</button>
      <button onClick={prevStep}>Previous</button>
    </div>
  );
};

const Form = () => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  switch (step) {
    case 1:
      return (
        <StepOne
          nextStep={nextStep}
          handleChange={handleChange}
          values={values}
        />
      );
    case 2:
      return (
        <StepTwo
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
        />
      );
    case 3:
      return <StepThree prevStep={prevStep} values={values} />;
    default:
      return null;
  }
};

export default Form;


// push notification
// import React, { useEffect } from 'react';

// const PushNotificationExample = () => {
//   useEffect(() => {
//     // Request permission for notifications on component mount
//     requestNotificationPermission();
//   }, []);

//   const requestNotificationPermission = () => {
//     if (!('Notification' in window)) {
//       console.log('This browser does not support notifications.');
//       return;
//     }

//     // Check current notification permission status
//     if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
//       Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') {
//           showNotification();
//         }
//       });
//     } else if (Notification.permission === 'granted') {
//       showNotification();
//     }
//   };

//   const showNotification = () => {
//     // Create a notification
//     new Notification('Hello!', {
//       body: 'This is a sample notification from React.js',
//     });
//   };

//   const handleButtonClick = () => {
//     // Trigger notification on button click
//     if (Notification.permission === 'granted') {
//       showNotification();
//     } else {
//       console.log('Permission not granted for notifications.');
//     }
//   };

//   return (
//     <div>
//       <h1>Push Notification Example</h1>
//       <p>Click the button to trigger a notification.</p>
//       <button onClick={handleButtonClick}>Show Notification</button>
//     </div>
//   );
// };

// export default PushNotificationExample;

