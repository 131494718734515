// chart filter modal

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Settings.css";
import usePostApi from "../api/UsepostApi";

const Settings = ({
  displayHead,
  DisplayData,
  locId,
  setFilterData,
  setFilterClient,
  filterMode,
}) => {
  const [form, setform] = useState([]);
  const [dateTo, setDateTo] = useState({
    from: "",
    to: "",
  });

  const handleForm = (e) => {
    const value = e.target.value;
    setform(value);
  };

  const handleDateTo = (e) => {
    const { name, value } = e.target;
    setDateTo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setDateTo({
      from: "",
      to: "",
    });
    setform("");
  };

  const { PostData: consumptionFilter, mainData } = usePostApi(
    `bess/filterConsumptionDataByLocation/${locId}`,
    {
      from: dateTo.from,
      to: dateTo.to,
      source: form,
    }
  );

  useEffect(() => {
    if (mainData) {
      setFilterData(mainData);
    }
  }, [mainData, setFilterData]);

  const { PostData: clientFilter, mainData: mainClient } = usePostApi(
    `bess/filterClientDataByLocation/${locId}`,
    {
      from: dateTo.from,
      to: dateTo.to,
      client_id: form,
    }
  );

  useEffect(() => {
    if (mainClient) {
      setFilterClient(mainClient);
    }
  }, [mainClient, setFilterClient]);

  const onSubmit = () => {
    if (!filterMode) {
      consumptionFilter();
    } else clientFilter();
  };

  return (
    <div className="settings-Container">
      <div className="settings-view">
        <div className="Display-graph-data">
          <div className="Display-dynamic-graph">{DisplayData}</div>
        </div>
        <div className="Display-filter-data">
          <form className="w-100">
            {!filterMode ? (
              <>
                <label id="calendar-label">Source</label>
                <select
                  id="calendar-inputs_select"
                  onChange={handleForm}
                  value={form}
                >
                  <option value="" disabled>
                    Source
                  </option>
                  <option value="grid">Grid</option>
                  <option value="Renewable">Renewable</option>
                  <option value="bess">Bess</option>
                </select>
              </>
            ) : (
              <>
                <label id="calendar-label">Client</label>
                <select
                  id="calendar-inputs_select"
                  onChange={handleForm}
                  value={form}
                >
                  <option value="" disabled>
                    Client
                  </option>
                  <option value="1">Airtel</option>
                  <option value="2">Vodafone</option>
                  <option value="3">Jio</option>
                  <option value="4">BSNL</option>
                </select>
              </>
            )}

            <div className="Display-filter_form_date">
              <p>
                <label id="calendar-label">From</label>
                <input
                  id="calendar-inputs"
                  name="from"
                  value={dateTo.from}
                  onChange={handleDateTo}
                  type="date"
                  max={new Date().toISOString().slice(0, 10)}
                />
              </p>
              <p>
                <label id="calendar-label">To</label>
                <input
                  id="calendar-inputs"
                  name="to"
                  value={dateTo.to}
                  onChange={handleDateTo}
                  type="date"
                />
              </p>
            </div>
            <div className="Display-filter_form_New">
              <p>
                <button
                  id="filter-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  Filter
                </button>
              </p>
              <p>
                <button
                  id="filter-reset-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                  }}
                >
                  Reset
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
