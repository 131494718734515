import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import ModalComponent from "../../../../../contexts/homeModal/HomeModal";
import SettingsPage from "../../../../../components/filter/Settings";
import NewConsumption from "./content/NewConsumption";
import { useDrag } from "../../../../../contexts/drag/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import { ThreeDots } from "react-loader-spinner";
import  HeadingUtils  from "../../../../../components/utils/headingUtils";
const ExpandCollapseCard = ({
  title,
  content,
  headerButtons,
  Consreadings,
}) => {
  const [open, setOpen] = useState(false);
  const { drag } = useDrag();

  const toggleCard = () => {
    setOpen(!open);
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        cursor: "pointer",
        marginBottom: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <Card.Header
        className="d-flex justify-content-between align-items-center"
        id={drag ? "abcd-container" : "header"}
      >
        <Card.Title
          onClick={toggleCard}
          id="title"
          style={{
            width: "97%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {title}
          <h6 style={{ margin: "0px" }}>{Consreadings}</h6>
        </Card.Title>
        <div className="ml-auto">{headerButtons}</div>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-card-content">
          <Card.Body
            id="cardBody"
            style={{ borderTop: "1px solid black", overflow: "scroll" }}
          >
            {content}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

const App = (props) => {
  const { dashboardHeadingUtils } = HeadingUtils();
  const {
    getData: ConsumptionData,
    isLoading,
    error,
  } = useGetApi(`bess/consumptionDataByLocation/${props.locId}`);
  const [showModal, setShowModal] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const MbodyStyle = { padding: "0px" };
  const MheadStyle = {
  };

  const cardData = [
    {
      title: dashboardHeadingUtils?.CARD_F,
      Consreadings: (
        <>
          {`${
            ConsumptionData?.totalConsumption
              ? ConsumptionData?.totalConsumption
              : 0
          } kVAh`}{" "}
          <FontAwesomeIcon
            icon={faFilter}
            onClick={openModal}
            style={{ color: "Gray" }}
          />
        </>
      ),
      content: (
        <>
          {/* <Consumption /> */}
          {error?.response?.data?.status === "failed" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p style={{ color: "black", margin: "0px", fontSize: "12px" }}>
                No data available.
              </p>
            </div>
          ) : (
            <>
              {isLoading ? (
                <div className="loading">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#00a79d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <NewConsumption
                  consumption={ConsumptionData}
                  locId={props.locId}
                />
              )}
            </>
          )}
        </>
      ),
      headerButtons: null,
      footer: null,
    },
  ];

  return (
    <>
      <div className="container">
        {/* <MapWithAddress /> */}
        <div className="card-container">
          {cardData.map((card, index) => (
            <ExpandCollapseCard
              className="resizable-card-content"
              key={index}
              title={card.title}
              Consreadings={card.Consreadings}
              content={card.content}
              headerButtons={card.headerButtons}
            />
          ))}
        </div>
      </div>
      {/* settings modal */}
      <ModalComponent
        header={true}
        // size={true} //fullscreen
        smSize={"xl"}
        ModalclassName="modal-chart"
        className="viewAll-modal_xl"
        showModal={showModal}
        closeModal={closeModal}
        MbodyStyle={MbodyStyle}
        MheadStyle={MheadStyle}
        Mtitle="CONSUMPTION"
        Mbody={
          <>
            <SettingsPage
              locId={props.locId}
              setFilterData={setFilterData}
              displayHead="CONSUMPTION"
              DisplayData={
                <NewConsumption
                  consumption={ConsumptionData}
                  locId={props.locId}
                  filterData={filterData}
                />
              }
            />
          </>
        }
      />
    </>
  );
};

export default App;
