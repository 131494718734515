import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import "./CycleMain.css";
import CyclesAndSoh from "./content/Cycle";
import { useDrag } from "../../../../../contexts/drag/Button";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import { ThreeDots } from "react-loader-spinner";
import  HeadingUtils  from "../../../../../components/utils/headingUtils";

const ExpandCollapseCard = ({ title, content, headerButtons }) => {
  const [open, setOpen] = useState(false);
  const { drag } = useDrag();

  const toggleCard = () => {
    setOpen(!open);
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        cursor: "pointer",
        marginBottom: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <Card.Header
        onClick={toggleCard}
        className="d-flex justify-content-between align-items-center"
        id={drag ? "abcd-container" : "header"}
      >
        <Card.Title id="title">{title}</Card.Title>
        <div className="ml-auto">{headerButtons}</div>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-card-content">
          <Card.Body
            id="cardBody"
            style={{ borderTop: "1px solid black", overflow: "scroll" }}
          >
            {content}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

const App = ({ locId }) => {
  const { dashboardHeadingUtils } = HeadingUtils();
  const [battery, setBattery] = useState([]);

  const {
    getData: BatteryList,
    isLoading,
    error,
  } = useGetApi(`bess/battery-list/${locId}`);

  useEffect(() => {
    setBattery(BatteryList?.batterylist);
  }, [BatteryList]);

  // console.log("battery", battery);

  const cardData = [
    {
      title: dashboardHeadingUtils?.CARD_D,
      content: (
        <>
          {error?.response?.data?.status === "failed" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p style={{ color: "black", margin: "0px", fontSize: "12px" }}>
                No data available.
              </p>
            </div>
          ) : (
            <>
              <>
                {isLoading ? (
                  <div className="loading">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#00a79d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <CyclesAndSoh battery={battery} />
                )}
              </>
            </>
          )}
        </>
      ),
      headerButtons: (
        <div id="totalAvailableEnergy">
          <span id="totalAvailableEnergy-title">
            {dashboardHeadingUtils.CARD_D_SUB(
              BatteryList?.totalbessEnergy ? BatteryList?.totalbessEnergy : 0
            )}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      {/* <MapWithAddress /> */}
      <div className="card-container">
        {cardData.map((card, index) => (
          <ExpandCollapseCard
            className="resizable-card-content"
            key={index}
            title={card.title}
            content={card.content}
            headerButtons={card.headerButtons}
          />
        ))}
      </div>
    </div>
  );
};

export default App;
