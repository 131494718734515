// import React, { useEffect, useState } from "react";
// import mqtt from "mqtt";

// const MQTTSubscriber = () => {
//  const [data, setData] = useState({});
//   useEffect(() => {
//     // MQTT broker configuration for WebSocket
//     const mqttBroker = "ws://mqtt.eclipseprojects.io/mqtt";
//     const client = mqtt.connect(mqttBroker);

//     // Callback function to handle incoming messages
//     const onMessage = (topic, message) => {
//       const jsonMessage = JSON.parse(message.toString());
//       const { temperature, humidity, pressure, voltage } = jsonMessage;

//       setData({ temperature, humidity, pressure, voltage });

//       // console.log("data",data);
//       console.log(
//         `Temperature: ${temperature}, Humidity: ${humidity}, Pressure: ${pressure}, Voltage: ${voltage}`
//       );
//     };

//     // Subscribe to MQTT topic
//     client.on("connect", () => {
//       console.log("Connected to MQTT broker");
//       client.subscribe("sensor_data");
//     });

//     // Handle incoming MQTT messages
//     client.on("message", onMessage);

//     // Clean up function to unsubscribe and disconnect
//     return () => {
//       client.unsubscribe("sensor_data"); //topic
//       client.end();
//       console.log("Disconnected from MQTT broker");
//     };
//   }, []);

//   return <div>

//     MQTT Subscriber

//    {
//     data &&(
//       <table>
//         <thead>
//           <tr>
//             <th>Parameter</th>
//             <th>Value</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>Temperature</td>
//             <td>{data.temperature}</td>
//           </tr>
//           <tr>
//             <td>Humidity</td>
//             <td>{data.humidity}</td>
//           </tr>
//           <tr>
//             <td>Pressure</td>
//             <td>{data.pressure}</td>
//           </tr>
//           <tr>
//             <td>Voltage</td>
//             <td>{data.voltage}</td>
//           </tr>
//         </tbody>
//       </table>
//     )
//    }
//     </div>;
// };

// export default MQTTSubscriber;

// working

// import React, { useEffect, useState } from "react";
// import mqtt from "mqtt";

// const MQTTSubscriber = () => {
//   const [data, setData] = useState({});
//   const [mqttClient, setMqttClient] = useState(null);

//   useEffect(() => {
//     // MQTT broker configuration for WebSocket
//     const mqttBroker = "192.168.0.126:1883";
//     const client = mqtt.connect(mqttBroker , { clientId: "sachin" });

//     // Set MQTT client
//     setMqttClient(client);

//     // Callback function to handle incoming messages
//     const onMessage = (topic, message) => {
//       const jsonMessage = JSON.parse(message.toString());
//       const { temperature, humidity, pressure, voltage } = jsonMessage;

//       setData({ temperature, humidity, pressure, voltage });

//       console.log(
//         `Temperature: ${temperature}, Humidity: ${humidity}, Pressure: ${pressure}, Voltage: ${voltage}`
//       );
//     };

//     // Subscribe to MQTT topic
//     client.on("connect", () => {
//       console.log("Connected to MQTT broker");
//       client.subscribe(" batteryVoltage");
//     });

//     // Handle incoming MQTT messages
//     client.on("message", onMessage);

//     // Clean up function to unsubscribe and disconnect
//     return () => {
//       client.unsubscribe(" batteryVoltage"); //topic
//       client.end();
//       console.log("Disconnected from MQTT broker");
//     };
//   }, []);

//   const publishData = () => {
//     if (mqttClient) {
//       const message = {
//         Temperature: 21,
//         humidity: 1,
//         pressure: 1013,
//         voltage: 5.0,
//       };
//       mqttClient.publish("sensor_data", JSON.stringify(message));
//       console.log("Published data to MQTT broker");
//     }
//   };
//   // if button 1 pressed {ch:1}//charging, if button2 pressed: {dh:1}//discharging
//   return (
//     <div>
//       MQTT Subscriber
//       <button onClick={publishData}>Publish Data</button>
//       <button onClick={publishData}>Ch</button>
//       <button onClick={publishData}>Dh</button>
//       {data && (
//         <table>
//           <thead>
//             <tr>
//               <th>Parameter</th>
//               <th>Value</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Temperature</td>
//               <td>{data.temperature}</td>
//             </tr>
//             <tr>
//               <td>Humidity</td>
//               <td>{data.humidity}</td>
//             </tr>
//             <tr>
//               <td>Pressure</td>
//               <td>{data.pressure}</td>
//             </tr>
//             <tr>
//               <td>Voltage</td>
//               <td>{data.voltage}</td>
//             </tr>
//           </tbody>
//         </table>
//       )}
//     </div>
//   );
// };

// export default MQTTSubscriber;

// import React, { useEffect } from 'react';
// import mqtt from 'mqtt';

// const MQTTComponent = () => {
//   useEffect(() => {
//     // MQTT broker details
//     const brokerUrl = 'mqtt://192.168.0.126:1883';  // WebSocket URL
//     const client = mqtt.connect(brokerUrl, { client_id: 'sachin' });

//     // Event handlers
//     client.on('connect', () => {
//       console.log('Connected to MQTT broker');
//       client.subscribe('batteryVoltage', (err) => {
//         if (!err) {
//           console.log('Subscribed to topic: batteryVoltage');
//         } else {
//           console.error('Subscription failed', err);
//         }
//       });
//     });

//     client.on('message', (topic, message) => {
//       // Handle incoming messages
//       console.log(`Received message on topic ${topic}: ${message.toString()}`);
//       // You can update state or perform other actions here
//     });

//     // Cleanup on component unmount
//     return () => {
//       client.end();  // Disconnect MQTT client
//     };
//   }, []); // Empty dependency array ensures this effect runs only once

//   return (
//     <div>
//       <h1>MQTT Component</h1>
//       {/* Your component UI goes here */}
//     </div>
//   );
// };

// export default MQTTComponent;
import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';

const MqttComponent = () => {
  const [message, setMessage] = useState('');
  
  useEffect(() => {
    // Replace with your MQTT host and port
    const client = mqtt.connect('ws://192.168.0.126:1883');

    console.log("client", client);
    
    
    client.on('connect', () => {
      console.log('Connected to MQTT broker');
      // Subscribe to the desired topic
      client.subscribe('your/topic', (err) => {
        if (!err) {
          console.log('Subscribed to topic');
        }
      });
    });

    client.on('message', (topic, message) => {
      // message is a Buffer, so we need to convert it to a string
      setMessage(message.toString());
    });

    // Cleanup on unmount
    return () => {
      client.end();
    };
  }, []);

  return (
    <div>
      <h1>MQTT Test</h1>
      <p>Received Message: {message}</p>
    </div>
  );
};

export default MqttComponent;




