import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";

function EditableInput({
  label,
  setFormData,
  formData,
  name,
  onSubmit,
  error,
  activeButton,
  value,
  onChange,
  inputRef,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //   const { register, handleSubmit, error } = useForm();
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    setIsEditing(false);
  };

  useEffect(() => {
    if (error) return setIsEditing(true);
  }, [error]);

  useEffect(() => {
    if (activeButton === false) {
      setIsEditing(true);
    }
  }, [activeButton]);

  return (
    <div className="profilePage_card_main">
      <label>{label}</label>
      {isEditing ? (
        <div className="d-flex align-items-center justify-content-between">
          <div className="flex-column">
            <form>
              <input
                type={props.type}
                name={props.name}
                endAdornment={props.endAdornment}
                value={value}
                ref={inputRef}
                {...props}
                {...(props.register
                  ? props.register(name, {
                      required: `${label} is required`,
                      ...(props.pattern && {
                        pattern: {
                          value: props.pattern,
                          message: `Invalid ${label} format`,
                        },
                      }),
                    })
                  : {})}
                onChange={onChange}
                autoFocus
                required={props?.required ? props?.required : false}
                placeholder={props?.placeholder}
                {...props}
                className="profilePage_card_input"
              />

              {/* {error && (
                  <p
                    role="alert"
                    style={{
                      color: "red",
                      marginBottom: "0px",
                      textAlign: "left",
                      fontSize: "11px",
                    }}
                  >
                    {error || props?.errors[name]?.message}
                  </p>
                )} */}
              {props?.errors && (
                <p
                  role="alert"
                  style={{
                    color: "red",
                    marginBottom: "0px",
                    textAlign: "left",
                    fontSize: "11px",
                    textWrap: "wrap",
                    width: "200px",
                  }}
                >
                  {props?.errors}
                </p>
              )}
            </form>
          </div>
          {activeButton !== false && (
            <>
              {isLoading ? (
                <ThreeDots height="30" width="30" radius="9" color="#00a79d" />
              ) : (
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    marginLeft: "10px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={handleSave}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <span>{formData}</span>
          {label !== "Email" && !isLoading && (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  marginLeft: "10px",
                  color: "rgb(35 184 169)",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default EditableInput;
