import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ChartComponent = ({ consumptionData, chartType, filterData }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (consumptionData?.monthsData) {
      setData(Object?.values(consumptionData?.monthsData)); // Extract values from the object
    } else {
      console.error("Data is not in the expected format");
    }

    if (filterData?.months) {
      setFilteredData(Object.values(filterData?.months));
    }
  }, [consumptionData, filterData]);

  console.log("consumptionData", data?.map((monthData) => monthData?.monthName));

  const renderCharts = () => {
    if (data?.length === 0) return null;

    const categories = data?.map((monthData) => monthData?.monthName);
    console.log("consumptionCategories", categories);

    if (chartType === "importExport") {
      const importExportChargeSeries = [
        {
          name: "export",
          // data: data.map((monthData) => monthData.export),
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.export)
            : data?.map((monthData) => monthData?.export),
        },
        {
          name: "import",
          // data: data.map((monthData) => monthData.import),
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.import)
            : data?.map((monthData) => monthData?.import),
        },
        {
          name: "charge",
          // data: data.map((monthData) => monthData.charge),
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.charge)
            : data?.map((monthData) => monthData?.charge),
        },
      ];

      const chartOptions = {
        colors: ["#283890", "#1074bc", "#24aae1", "#ffc107"],
        dataLabels: { enabled: false },
        chart: {
          type: "bar",
          height: 250,
          stacked: true,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            position: "topRight",
          },
          zoom: { enabled: true },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        grid: { show: false },
        yaxis: { show: false },
        fill: { opacity: 1 },
        legend: { show: false, position: "right", offsetX: 0, offsetY: 50 },
        xaxis: { categories },
      };

      // console.log(
      //   "filterData.kvah_bess",
      //   filteredData?.map((monthData) => monthData?.kvah_bess)
      // );
      // console.log(
      //   "filterData.kvah_grid",
      //   filteredData?.map((monthData) => monthData?.kvah_grid)
      // );

      return (
        <div className="chart-container">
          <div className="chart-card">
            <ReactApexChart
              options={chartOptions}
              series={importExportChargeSeries}
              type="bar"
              height={200}
            />
          </div>
        </div>
      );
    } else {
      // Render the default chart (kVAh BESS, kVAh Grid, kVAh Renewable)

      const seriesData = [
        {
          name: "BESS",
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.kvah_bess ?? 0)
            : data.map((monthData) => monthData?.kvah_bess ?? 0),
        },

        {
          name: "Renewable",
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.kvah_Renewable ?? 0)
            : data.map((monthData) => monthData?.kvah_renewable ?? 0),
        },
        {
          name: "Grid",
          data: filteredData?.length
            ? filteredData?.map((monthData) => monthData?.kvah_grid ?? 0)
            : data.map((monthData) => monthData?.kvah_grid ?? 0),
        },
      ];

      const chartOptions = {
        colors: ["#283890", "#1074bc", "#24aae1", "#ffc107"],
        dataLabels: { enabled: false },
        chart: {
          type: "bar",
          height: 250,
          stacked: true,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            position: "topRight",
          },
          zoom: { enabled: true },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: { position: "bottom", offsetX: -10, offsetY: 0 },
            },
          },
        ],
        grid: { show: false },
        yaxis: { show: false },
        fill: { opacity: 1 },
        legend: { show: false, position: "right", offsetX: 0, offsetY: 50 },
        xaxis: { categories },
      };

      return (
        <div className="chart-container">
          <div className="chart-card">
            <ReactApexChart
              options={chartOptions}
              series={seriesData}
              type="bar"
              height={200}
            />
          </div>
        </div>
      );
    }
  };

  return <>{renderCharts()}</>;
};

export default ChartComponent;
