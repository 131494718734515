import React, { useEffect, useState } from "react";
import "./New.css";
import Channel from "../../../../graph/channel-graph";
import ProgressBar from "../../../../../../components/progressbar/normal/ProgressBar";
import { useGetApi } from "../../../../../../components/api/UseGetApi";

const NewClient = (props) => {
  const [filteredData, setFilteredData] = useState();
  const [activeNBtn, setActiveNBtn] = useState();

  useEffect(() => {
    setActiveNBtn("GRID");
  }, []);

  const progressBarWidth = {
    width: "75%",
  };
  const progressHeight = {
    height: "20px",
  };
  const progressStyleAirtel = {
    backgroundColor: "#23a4d9",
    width: "50%",
  };
  const progressStyleVodafone = {
    backgroundColor: "#1074bc",
    width: "50%",
  };
  const progressStyleJio = {
    backgroundColor: "#27378d",
    width: "50%",
  };
  const progressStyleBsnl = {
    backgroundColor: "#11a097",
    width: "50%",
  };
  const progressStyleUtilities = {
    backgroundColor: "#e41c21",
    width: "30%",
  };

  const tenantColor = (tenant_name) => {
    switch (tenant_name) {
      case "Airtel":
        return progressStyleAirtel;
      case "Vodafone":
        return progressStyleVodafone;
      case "Jio":
        return progressStyleJio;
      case "BSNL":
        return progressStyleBsnl;
      case "Utilities":
        return progressStyleUtilities;
      default:
        return null;
    }
  };

  const { getData: clientData } = useGetApi(
    `bess/tenantsDataByLocation/${props.locId}`
  );

  useEffect(() => {
    if (props.filterClient?.tenants) {
      setFilteredData(props.filterClient?.tenants);
    }
  }, [props?.filterClient?.tenants]);

  return (
    <div>
      <>
        <div className="NewClient-Main">
          <div className="NewClient-Progress">
            {props.clients?.map((item, index) => (
              <ProgressBar
                key={index}
                label={`${item?.tenant_name}`}
                progressBarWidth={progressBarWidth}
                progressHeight={progressHeight}
                progressStyle={tenantColor(item?.tenant_name)}
                progressLabel={
                  !props.filterClient ? (
                    <>
                      {props.filterClient?.totalConsumption ||
                        (props.activeNBtn === "GRID"
                          ? item?.grid_consumption
                          : props.activeNBtn === "BESS"
                          ? item?.bess_consumption
                          : item?.renewable_consumption)}{" "}
                      Units
                    </>
                  ) : (
                    <>
                      {filteredData?.tenant_name &&
                      item?.tenant_name.includes(filteredData?.tenant_name)
                        ? props.filterClient?.totalConsumption ||
                          (props.activeNBtn === "GRID"
                            ? item?.grid_consumption
                            : props.activeNBtn === "BESS"
                            ? item?.bess_consumption
                            : item?.renewable_consumption)
                        : "0"}{" "}
                      Units
                    </>
                  )
                }
              />
            ))}
          </div>
          <div className="NewClient-Graph">
            <Channel
              clientData={clientData}
              activeBtn={props.activeBtn}
              locId={props.locId}
              filterClient={props.filterClient}
              activeNBtn={activeNBtn}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default NewClient;
