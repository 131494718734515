import React, { useEffect, useState } from "react";
import "./BessBms.css";
import Table from "react-bootstrap/Table";
import ModalComponent from "../../../../../../contexts/homeModal/HomeModal";
import ViewAll from "../../../../../../components/buttons/viewAll/ViewAl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Bessbms = ({ locId, isLoading, battery }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Initialize items per page
    setItemsPerPage(2);
  }, []);

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = battery
    ? battery.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Calculate count for Table 1 based on current page and items per page
  let countTableone = (currentPage - 1) * itemsPerPage + 1;

  return (
    <div>
      <div style={{ padding: "10px 20px 10px 20px" }}>
        <Table bordered style={{ border: "1px solid #6f6f6f" }}>
          <thead>
            <tr
              style={{
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
              }}
            >
              <th style={{ border: "none", borderTop: "1px solid white" }}>
                Battery
              </th>
              <th style={{ border: "none", borderTop: "1px solid white" }}>
                Voltage
              </th>
              <th style={{ border: "none", borderTop: "1px solid white" }}>
                Capacity
              </th>
              <th style={{ border: "none", borderTop: "1px solid white" }}>
                Temperature
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.length === 0 ? (
              <tr>
                <td
                  colSpan={3}
                  style={{
                    border: "none",
                    borderLeft: "1px solid transparent",
                  }}
                >
                  No data
                </td>
              </tr>
            ) : (
              currentItems?.map((item, index) => (
                <tr key={index}>
                  <td style={{ borderLeft: "1px solid transparent" }}>
                    B{countTableone + index}
                  </td>
                  <td>{item.output_voltage}V</td>
                  <td>{item.capacity} Ah</td>
                  <td style={{ borderRight: "1px solid transparent" }}>
                    {item.temp}°C
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <div className="FooterCard">
          <ViewAll openBess={openModal} />
          <div>
            <button
              className="card-pagination_button"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon id="scroll" icon={faChevronLeft} />
            </button>

            {currentItems.length > 0 && (
              <button
                className="card-pagination_button"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={indexOfLastItem >= battery.length}
              >
                <FontAwesomeIcon id="scroll" icon={faChevronRight} />
              </button>
            )}
          </div>
        </div>
      </div>

      <>
        <ModalComponent
          showModal={showModal}
          closeModal={closeModal}
          smSize="lg"
          className="viewAll-modal_xl"
          ModalclassName="modal-dashboard"
          Mtitle="BESS Energy Status"
          footer={false}
          Mbody={
            <>
              <Table bordered style={{ border: "1px solid #6f6f6f" }}>
                <thead>
                  <tr
                    style={{
                      borderLeft: "1px solid transparent",
                      borderRight: "1px solid transparent",
                    }}
                  >
                    <th
                      style={{ border: "none", borderTop: "1px solid white" }}
                    >
                      Battery
                    </th>
                    <th
                      style={{ border: "none", borderTop: "1px solid white" }}
                    >
                      Voltage
                    </th>
                    <th
                      style={{ border: "none", borderTop: "1px solid white" }}
                    >
                      Capacity
                    </th>
                    <th
                      style={{ border: "none", borderTop: "1px solid white" }}
                    >
                      Temperature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {battery?.map((item, index) => (
                    <tr key={index}>
                      <td className="leftBorder">B{index + 1}</td>
                      <td>{item.output_voltage}V</td>
                      <td>{item.capacity} Ah</td>
                      <td className="rightBorder">{item.temp}°C</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          }
        />
      </>
    </div>
  );
};

export default Bessbms;
