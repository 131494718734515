import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "neoenrg-chat.firebaseapp.com",
  projectId: "neoenrg-chat",
  storageBucket: "neoenrg-chat.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // apiKey: "AIzaSyDjpytnP7FLVkylL_50ZpYbS8Yvu3UvRgM",
  // authDomain: "neoenrg-support.firebaseapp.com",
  // projectId: "neoenrg-support",
  // storageBucket: "neoenrg-support.appspot.com",
  // messagingSenderId: "458455132291",
  // appId: "1:458455132291:web:6da514eeb477aba7a313b6",
  // measurementId: "G-QVJ6DHEG84",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const database = getFirestore(app);

// const firebaseConfig = {
//   apiKey: "AIzaSyDjpytnP7FLVkylL_50ZpYbS8Yvu3UvRgM",
//   authDomain: "neoenrg-support.firebaseapp.com",
//   projectId: "neoenrg-support",
//   storageBucket: "neoenrg-support.appspot.com",
//   messagingSenderId: "458455132291",
//   appId: "1:458455132291:web:6da514eeb477aba7a313b6",
//   measurementId: "G-QVJ6DHEG84"
// };
