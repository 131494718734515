const PasswordInput = ({
  label,
  value,
  onChange,
  error,
  placeholder,
  type = "text",
  name,
}) => {
  return (
    <div className="profilePage_card_main">
      <label>{label}</label>

      <div className="d-flex align-items-center justify-content-between">
        <div className="flex-column">
          <form>
            <input
              type={type}
              value={value}
              name={name}
              onChange={onChange}
              //   className="form-control"
              placeholder={placeholder}
              className="profilePage_card_input"
            />
            {error && (
              <p
                style={{
                  color: "red",
                  marginBottom: "0px",
                  textAlign: "left",
                  fontSize: "11px",
                  textWrap: "wrap",
                  width: "200px",
                }}
                className="text-danger"
              >
                {error}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
