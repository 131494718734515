import axios from "axios";
import { useState, useEffect } from "react";
import { useData } from "../../contexts/data/Context";

export const useGetApi = (apiPara) => {
  const { data, setIsData } = useData();
  const [getData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApiData = async () => {
      const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key
      setIsLoading(true);
      try {
        const userDetails = JSON.parse(localStorage.getItem("user")).success
          .token;
        const response = await axios.get(`${EMS_API_KEY}/${apiPara}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userDetails}`,
          },
        });
        setData(response.data);
        setIsData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchApiData();
  }, [apiPara, setIsData]);

  return { getData, isLoading, error, setData, data };
};
