// Dropdown.js
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonW from "../../../components/buttons/indexAdd/IndexAddBt";
import User from "./content/user/User";
import Tenant from "./content/tenant/Tenant";
import TenantLocation from "./content/tenantLoc/TenantLoc";
import Location from "./content/location/Location";

const modalData = [
  {
    name: "LOCATION",
    title: "ADD LOCATION DETAILS",
    body: <>{/* <Loc /> */}</>,
    footer: <></>,
  },
  {
    name: "USER",
    title: "ADD USER DETAILS",
    body: <></>,
    footer: <></>,
  },
  {
    name: "TENANT",
    title: "ADD TENANT DETAILS",
    body: <>{/* <Tenant /> */}</>,
    footer: <></>,
  },
  {
    name: "TENANT + LOCATION",
    title: "ADD TENANT + LOCATION DETAILS",
    body: <>{/* <TenantLoc /> */}</>,
    footer: <></>,
  },
];

const Dropdown = ({ showModal, closeModal }) => {
  // const [selectedModal, setSelectedModal] = useState(null);
  const [nestedModalShow, setNestedModalShow] = useState(false);
  const [nestedModalType, setNestedModalType] = useState(null);
  const showNestedModal = (type) => {
    setNestedModalShow(true);
    setNestedModalType(type);
  };

  const closeNestedModal = () => {
    setNestedModalShow(false);
    setNestedModalType(null);
  };

  // console.log("modalData", modalData);

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        animation={true}
        dialogClassName="dropdown"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="drop-down-header"
          id="drop-modal-header"
        >
          <p id="dropdown-modal-title" style={{ color: "white" }}>
            WHAT WOULD YOU LIKE TO ADD?
          </p>
        </Modal.Header>
        <Modal.Body className="drop-down-body">
          <div id="dropdown-body-buttons">
            {modalData.map((modal) => (
              <div key={modal.name}>
                <ButtonW
                  name={modal.name}
                  NestedMenu={() => showNestedModal(modal.name)}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      {/* nested modal content */}

      {nestedModalType === "USER" && (
        <User
          showModal={nestedModalShow}
          closeModal={closeNestedModal}
          selectedModal={modalData[1]}
        />
      )}
      {nestedModalType === "TENANT" && (
        <Tenant
          showModal={nestedModalShow}
          closeModal={closeNestedModal}
          selectedModal={modalData[2]}
        />
      )}
      {nestedModalType === "TENANT + LOCATION" && (
        <TenantLocation
          showModal={nestedModalShow}
          closeModal={closeNestedModal}
          selectedModal={modalData[3]}
        />
      )}
      {nestedModalType === "LOCATION" && (
        <Location
          showModal={nestedModalShow}
          closeModal={closeNestedModal}
          selectedModal={modalData[0]}
        />
      )}
    </>
  );
};

export default Dropdown;
