// hooks/useOptimizedGetApi.js
import { useMemo, useState, useEffect } from "react";
import { useGetApi } from "./UseGetApiContext";

const fetchData = (isMonthNo, locId) => {
  let endpoint;

  if (isMonthNo && locId) {
    endpoint = `bess/filterConsumptionOfLocationByMonth/${isMonthNo}/${locId}`;
  } else if (isMonthNo) {
    endpoint = `bess/filterConsumptionOfLocationByMonth/${isMonthNo}/getAll`;
  } else if (locId) {
    endpoint = `bess/getConsumptionByLocation/${locId}`;
  } else {
    endpoint = "bess/total-consumption";
  }

  return endpoint;
};

const useOptimizedGetApi = (isMonthNo, locId) => {
  const [isLoadingEndpointChange, setIsLoadingEndpointChange] = useState(false);
  const [prevEndpoint, setPrevEndpoint] = useState(null);
  const endpoint = useMemo(() => fetchData(isMonthNo, locId), [isMonthNo, locId]);

  useEffect(() => {
    if (prevEndpoint && endpoint !== prevEndpoint) {
      setIsLoadingEndpointChange(true);
      setPrevEndpoint(endpoint);
      setTimeout(() => {
        setIsLoadingEndpointChange(false);
      }, 500); // Adjust the timeout as needed
    } else {
      setPrevEndpoint(endpoint);
    }
  }, [endpoint, prevEndpoint]);

  const { data,error } = useGetApi(endpoint);

  return { data, isLoading: isLoadingEndpointChange, error };
};

export default useOptimizedGetApi;
