import React from "react";
import "./Skew.css";

const ProgressBar = ({
  label,
  progress,
  progressColor,
  progressBarWidth,
  progressHeight,
  progressBarInnerAbc,
}) => {
  return (
    <div className="progress-barA" style={progressBarWidth}>
      <div className="labelA">{label}</div>
      <div className="progressA" style={progressHeight}>
        <div
          style={{
            // width: `${progress}%`,
            width: "100%",
            backgroundColor: `${progressColor}`,
          }}
          // style={progressStyle}
          //   className="progress-bar-innerA"
          className={progressBarInnerAbc}
        >
          {`${progress}%`}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
