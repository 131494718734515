import React, { useEffect, useState } from "react";
import NestedModal from "../../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../../components/buttons/saveContinue/ButtonSave";
import usePostApi from "../../../../../components/api/UsepostApi";
// import ButtonLoc from "../../../../../components/buttons/IndexAdd/IndexAddBt";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import LocMap from "./LocationMap";
import "./Location.css";
import { numberValidation } from "../../../../../components/validations/utils";
import { SpecialCharacterValidation } from "../../../../../components/validations/utils";

const InputField = (props) => (
  <>
    <input
      //   id="zoomed-input-user"
      className={props.id ? "zoomed-input" : ""}
      style={{ width: "100%" }}
      {...props.register}
      aria-invalid={props.error ? "true" : "false"}
      {...props}
      placeholder={`${props.placeholder}*`}
    />
    {props.error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {props.errorMessage}
      </p>
    )}
  </>
);

const Location = (props) => {
  // getting userData
  const { getData: userData } = useGetApi("users/getAllByOrganization/2");
  const [isError, setIsError] = useState(""); // State to track API errors
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isAddress, setIsAddress] = useState();
  const [position, setPosition] = useState();
  const [selectedManager, setSelectedManager] = useState({
    email: "",
    phone: "",
  });
  const [formData, setFormData] = useState({
    pincode: "",
    location_name: "",
    managerName: userData?.map((user) => user.firstname),
    site_manager_id: "",
    organization_id: "",
    address: "",
  });

  // console.log("Manager", selectedManager);
  // console.log("FormData", formData);
  // console.log("isAddress", isAddress);
  // Tenant section   -----------------------------------------------

  // input values

  const handleChange = (e) => {
    const { name, value } = e.target;
    let idValue;

    if (name === "managerName") {
      const selectedManager = userData?.find(
        (user) => user.firstname === value
      );

      setSelectedManager({
        id: selectedManager?.id || "",
        email: selectedManager?.email || "",
        phone: selectedManager?.phone || "",
      });

      // Store the manager id for form data
      idValue = selectedManager?.id || "";
    }

    // Update the formData state
    setFormData((prevTenant) => ({
      ...prevTenant,
      [name]: value,
      ...(idValue && { site_manager_id: idValue }),
    }));
  };

  // submit function

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await locationSubmit();

      if (success) {
        setFormData({
          pincode: "",
          location_name: "",
          managerName: "",
          site_manager_id: "",
          organization_id: "",
        });
        setPosition(null);
        setIsAddress("");
        setSelectedManager({
          email: "",
          phone: "",
        });
      } else {
        // Location Adding canceled
        console.log("Location Adding canceled");
      }
    } catch (error) {
      console.error("Error Adding data:", error);
    }
  };

  useEffect(() => {
    if (isAddress)
      setFormData({
        ...formData,
        address: isAddress || "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddress]);

  // custom hook

  const { PostData: locationSubmit, error: apiError } = usePostApi(
    "location/add",
    {
      organization_id: "2",
      address: isAddress || formData.address,
      pincode: formData.pincode,
      latitude: position?.lat,
      longitude: position?.lng,
      site_manager_id: formData.site_manager_id,
      location_name: formData.location_name,
      swal: true,
      message: "Location Added Successfully",
      fun: () => props.closeModal(),
    }
  );

  // for error

  useEffect(() => {
    if (apiError) {
      console.log("Error:", apiError?.response?.data?.error);
      setIsError(apiError?.response?.data?.error);
    }
  }, [apiError]);

  const [isXL, setIsXL] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1580px)");
    const checkMediaQuery = () => {
      setIsXL(mediaQuery.matches);
    };

    mediaQuery.addListener(checkMediaQuery);
    checkMediaQuery();

    return () => {
      mediaQuery.removeListener(checkMediaQuery);
    };
  }, []);

  const modalSize = isXL ? "lg" : "lg";

  return (
    <div>
      <NestedModal
        modalSize={modalSize}
        className="location-modal_xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5
              id="dropdown-modal-title"
              style={{ marginLeft: "20px", padding: "15px" }}
            >
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "LOCATION" && (
            <>
              <div className="Location-container_main">
                <div className="location-input_container">
                  <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                      id="pincode"
                      label="pincode"
                      name="pincode"
                      type="text"
                      maxLength={6}
                      minLength={6}
                      value={formData.pincode}
                      register={register("pincode", {
                        required: "Pincode is required",
                      })}
                      error={errors.pincode || isError.pincode}
                      errorMessage={errors.pincode?.message || isError?.pincode}
                      onChange={handleChange}
                      placeholder="Pincode"
                      onKeyDown={numberValidation}
                    />
                    <textarea
                      rows={
                        window.matchMedia("(min-width: 1580px)").matches
                          ? "6"
                          : "4"
                      }
                      cols="40"
                      id="address"
                      name="address"
                      {...register("address", {
                        required: isAddress ? false : "Address is required",
                        // shouldUnregister: true,
                      })}
                      value={formData.address || ""}
                      className="zoomed-input"
                      onChange={handleChange}
                      placeholder="Address*"
                    />
                    {errors.address && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors?.address?.message || "Address is required"}
                      </p>
                    )}
                    {isError?.address && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {isError?.address || "Address is required"}
                      </p>
                    )}

                    <InputField
                      id="location_name"
                      label="location_name"
                      name="location_name"
                      value={formData.location_name || ""}
                      register={register("location_name", {
                        required: "Location name is required",
                      })}
                      error={errors.location_name || isError.location_name}
                      errorMessage={
                        errors.location_name?.message || isError?.location_name
                      }
                      onChange={handleChange}
                      placeholder="Location Name"
                      onKeyDown={SpecialCharacterValidation}
                    />

                    <select
                      {...register("managerName", {
                        required: "Manager name is required",
                      })}
                      name="managerName"
                      onChange={handleChange}
                      className="zoomed-input"
                      id="zoomed-input_select"
                      // style={{
                      //   fontSize: "12px",
                      //   paddingTop: "13px",
                      //   paddingBottom: "13px",
                      //   width: "-webkit-fill-available",
                      // }}
                      value={formData.managerName || ""}
                    >
                      <option value="">Site Manager*</option>
                      {userData?.map((item) => (
                        <option
                          key={item.id}
                          value={item.firstname}
                          data-id={item?.userDataId}
                        >
                          {item.firstname}
                        </option>
                      ))}
                    </select>
                    {errors.managerName && (
                      <p style={{ color: "red", marginBottom: "0px" }}>
                        {errors.managerName.message ||
                          "Manager name is required"}
                      </p>
                    )}

                    <InputField
                      id="email"
                      label="email"
                      name="email"
                      style={{ backgroundColor: "rgb(204 198 198)" }}
                      value={selectedManager.email}
                      // {...register("email", {
                      //   required: "email is required",
                      //   // shouldUnregister: true,
                      // })}
                      error={errors.email || isError.email}
                      errorMessage={errors.email?.message || isError?.email}
                      onChange={handleChange}
                      placeholder="Email Id"
                      readOnly
                    />
                    <InputField
                      id="phone"
                      label="phone"
                      name="phone"
                      type="number"
                      style={{ backgroundColor: "rgb(204 198 198)" }}
                      value={selectedManager.phone}
                      // {...register("phone", {
                      //   required: "phone is required",
                      //   // shouldUnregister: true,
                      // })}
                      error={errors.phone || isError.phone}
                      errorMessage={errors.phone?.message || isError?.phone}
                      onChange={handleChange}
                      placeholder="Phone Number"
                      readOnly
                    />
                  </form>
                </div>
                <div id="location-map_container">
                  <LocMap
                    NewPin={formData.pincode}
                    setIsAddress={setIsAddress}
                    setPosition={setPosition}
                  />
                </div>
              </div>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "LOCATION" && (
                <>
                  <BtnSave
                    form="my-form"
                    name="SAVE"
                    onClickFunction={handleSubmit(onSubmit)}
                  />
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default Location;
