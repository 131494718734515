import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import "./Client.css";
import ModalComponent from "../../../../../contexts/homeModal/HomeModal";
import NewClient from "./content/NewClient";
import SettingsPage from "../../../../../components/filter/Settings";
import { useDrag } from "../../../../../contexts/drag/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import { ThreeDots } from "react-loader-spinner";
import  HeadingUtils  from "../../../../../components/utils/headingUtils";

const ExpandCollapseCard = ({ title, content, headerButtons, footer }) => {
  const [open, setOpen] = useState(false);
  const { drag } = useDrag();

  const toggleCard = () => {
    setOpen(!open);
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        cursor: "pointer",
        marginBottom: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <Card.Header
        className="d-flex justify-content-between align-items-center"
        id={drag ? "abcd-container" : "header"}
      >
        <Card.Title
          onClick={toggleCard}
          className="client-heading-m1"
          id="title"
        >
          {title}
        </Card.Title>
        <div className="ml-auto">{headerButtons}</div>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-card-content">
          <Card.Body
            id="cardBody"
            style={{ borderTop: "1px solid black", overflow: "scroll" }}
          >
            {content}
          </Card.Body>
          {footer === false ? null : (
            <Card.Footer id="footer">{footer}</Card.Footer>
          )}
        </div>
      </Collapse>
    </Card>
  );
};

const App = (props) => {
  const { dashboardHeadingUtils } = HeadingUtils();
  const [showModal, setShowModal] = useState(false);
  const [btn1Active, setBtn1Active] = useState(false);
  const [btn2Active, setBtn2Active] = useState(true);
  const [btn3Active, setBtn3Active] = useState(false);
  const [client, setclient] = useState([]);
  const [activeBtn, setActiveBtn] = useState("GRID");
  const [filterClient, setFilterClient] = useState(null);

  const {
    getData: ClientList,
    isLoading,
    error,
  } = useGetApi(`bess/tenantsDataByLocation/${props.locId}`);
  // console.log("isLoadingMain", isLoading);
  useEffect(() => {
    setclient(ClientList?.tenants);
  }, [ClientList]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const MbodyStyle = { padding: "0px" };
  const MheadStyle = {
    color: "black",
  };

  // activeButton

  const handleBtn1Active = () => {
    setBtn1Active((prev) => !prev);
    setBtn2Active(false);
    setBtn3Active(false);
    setActiveBtn("RENEWABLE");
  };
  const handleBtn2Active = () => {
    setBtn2Active((prev) => !prev);
    setBtn1Active(false);
    setBtn3Active(false);
    setActiveBtn("GRID");
  };
  const handleBtn3Active = () => {
    setBtn3Active((prev) => !prev);
    setBtn2Active(false);
    setBtn1Active(false);
    setActiveBtn("BESS");
  };


  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  const cardData = [
    {
      title: dashboardHeadingUtils?.CARD_E,
      content: (
        <>
          {error?.response?.data?.status === "failed" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "black", margin: "0px", fontSize: "12px" }}>
                No data available.
              </p>
            </div>
          ) : (
            <>
              <>
                {isLoading ? (
                  <div className="loading">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#00a79d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <NewClient
                    clients={client}
                    activeBtn={activeBtn}
                    locId={props.locId}
                  />
                )}
              </>
            </>
          )}
        </>
      ),
      footer: (
        <div>
          <>
            <p id="channel-bottom-s">
              {ClientList?.totalConsumption} Units Consumed In {currentMonth}
            </p>
          </>
        </div>
      ),
      headerButtons: (
        <div className="clent-btn">
          {/* <div id="renewable">BESS</div> */}
          <div
            className={btn1Active ? "renewable_active" : "renewable"}
            onClick={handleBtn1Active}
          >
            RENEWABLE
          </div>
          <div
            className={btn2Active ? "grid_active" : "grid"}
            onClick={handleBtn2Active}
          >
            GRID
          </div>
          <div
            className={btn3Active ? "bess_active" : "bess"}
            onClick={handleBtn3Active}
          >
            BESS
          </div>
          <FontAwesomeIcon
            icon={faFilter}
            onClick={openModal}
            style={{ color: "Gray" }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="container">
        {/* <MapWithAddress /> */}
        <div className="card-container">
          {cardData.map((card, index) => (
            <ExpandCollapseCard
              className="resizable-card-content"
              key={index}
              title={card.title}
              content={card.content}
              headerButtons={card.headerButtons}
              footer={
                error?.response?.data?.status === "failed" || isLoading
                  ? false
                  : card.footer
              }
            />
          ))}
        </div>
      </div>

      <ModalComponent
        header={true}
        // size={true} //fullscreen
        smSize={"xl"}
        ModalclassName="modal-chart"
        showModal={showModal}
        closeModal={closeModal}
        MbodyStyle={MbodyStyle}
        MheadStyle={MheadStyle}
        Mtitle="CLIENT CHANNELS"
        Mbody={
          <>
            <SettingsPage
              displayHead="CLIENT CHANNELS"
              filterMode={true}
              setFilterClient={setFilterClient}
              locId={props.locId}
              DisplayData={
                <NewClient
                  clients={client}
                  filterMode={true}
                  isLoading={isLoading}
                  locId={props.locId}
                  filterClient={filterClient}
                  activeBtn={activeBtn}
                />
              }
            />
          </>
        }
      />
    </>
  );
};

export default App;
