import React, { useEffect, useState } from "react";
import NestedModal from "../../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../../components/buttons/saveContinue/ButtonSave";
import usePostApi from "../../../../../components/api/UsepostApi";
// import ButtonLoc from "../../../../../components/buttons/IndexAdd/IndexAddBt";
import { numberValidation } from "../../../../../components/validations/utils";
import { characterValidation } from "../../../../../components/validations/utils";

const InputField = ({ id, label, register, error, errorMessage, ...rest }) => (
  <>
    <input
      //   id="zoomed-input-user"
      id={id ? "zoomed-input-user" : ""}
      style={{ width: "70%" }}
      {...register}
      aria-invalid={error ? "true" : "false"}
      {...rest}
      placeholder={`${rest.placeholder}*`}
    />
    {error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {errorMessage}
      </p>
    )}
  </>
);

const User = (props) => {
  const [isError, setIsError] = useState(""); // State to track API errors
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [tenantData, setTenantData] = useState({
    tenant_name: "",
    contact_name: "",
    contact_designation: "",
    contact_number: "",
    organization_id: "",
    contact_email: "",
  });

  // Tenant section   -----------------------------------------------

  // input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTenantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // submit function

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await tenantSubmit();

      if (success) {
        setTenantData({
          tenant_name: "",
          contact_name: "",
          contact_designation: "",
          contact_number: "",
          organization_id: "",
          contact_email: "",
        });
        // props.closeModal();
        console.log("props", props);
      } else {
        // Deletion canceled
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  // const onSubmit = async (data) => {
  //   try {
  //     await tenantSubmit();
  //     setTenantData({
  //       tenant_name: "",
  //       contact_name: "",
  //       contact_designation: "",
  //       contact_number: "",
  //       organization_id: "",
  //       contact_email: "",
  //     });
  //     console.log("Data submitted successfully!", data);
  //   } catch (error) {
  //     console.error("Error posting data:", error);
  //   }
  // };

  // custom hook

  const { PostData: tenantSubmit, error: apiError } = usePostApi(
    "tenants/add",
    {
      tenant_name: tenantData.tenant_name,
      contact_name: tenantData.contact_name,
      contact_designation: tenantData.contact_designation,
      contact_number: tenantData.contact_number,
      contact_email: tenantData.contact_email,
      organization_id: "2",
      swal: true,
      message: "Tenant Added Successfully",
      fun: () => props.closeModal(),
    }
  );

  // for error

  useEffect(() => {
    if (apiError) {
      console.log("Error:", apiError?.response?.data?.error);
      setIsError(apiError?.response?.data?.error);
    }
  }, [apiError]);

  console.log("adminData", tenantData);
  console.log("isError", isError);
  console.log("error", errors);

  return (
    <div>
      <NestedModal
        modalSize="lg"
        className="location-modal_xl"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5 id="dropdown-modal-title" style={{ marginLeft: "20px",padding: "15px" }}>
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "TENANT" && (
            <>
              <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    minHeight: "40vh",
                  }}
                >
                  <InputField
                    id="tenant_name"
                    label="tenant_name"
                    value={tenantData.tenant_name}
                    register={register("tenant_name", {
                      required: "Tenant name is required",
                    })}
                    error={errors.tenant_name || isError.tenant_name}
                    errorMessage={
                      errors.tenant_name?.message || isError?.tenant_name
                    }
                    onChange={handleInputChange}
                    placeholder="Tenant Name"
                    onKeyDown={characterValidation}
                  />
                  <InputField
                    id="contact_name"
                    label="contact_name"
                    value={tenantData.contact_name}
                    register={register("contact_name", {
                      required: "Contact name is required",
                    })}
                    error={errors?.contact_name || isError?.contact_name}
                    errorMessage={
                      errors.contact_name?.message || isError?.contact_name
                    }
                    onChange={handleInputChange}
                    placeholder="Contact Name"
                    onKeyDown={characterValidation}
                  />
                  <InputField
                    id="contact_email"
                    label="contact_email"
                    value={tenantData.contact_email}
                    register={register("contact_email", {
                      required: "email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    error={errors.contact_email || isError.contact_email}
                    errorMessage={
                      errors.contact_email?.message || isError?.contact_email
                    }
                    onChange={handleInputChange}
                    placeholder="Email Id"
                  />
                  <InputField
                    id="contact_designation"
                    label="contact_designation"
                    value={tenantData.contact_designation}
                    register={register("contact_designation", {
                      required: "designation is required",
                    })}
                    error={
                      errors.contact_designation || isError.contact_designation
                    }
                    errorMessage={
                      errors.contact_designation?.message ||
                      isError?.contact_designation
                    }
                    onChange={handleInputChange}
                    placeholder="Contact Designation"
                  />
                  <InputField
                    id="contact_number"
                    label="contact_number"
                    value={tenantData.contact_number}
                    type="text"
                    maxLength={10}
                    minLength={10}
                    register={register("contact_number", {
                      required: "number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Phone number must be exactly 10 digits",
                      },
                    })}
                    error={errors.contact_number || isError?.contact_number}
                    errorMessage={
                      errors.contact_number?.message || isError?.contact_number
                    }
                    onChange={handleInputChange}
                    placeholder="Phone Number"
                    onKeyDown={numberValidation}
                  />
                </div>
              </form>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "TENANT" && (
                <>
                  <BtnSave
                    form="my-form"
                    name="SAVE"
                    onClickFunction={handleSubmit(onSubmit)}
                  />
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default User;
