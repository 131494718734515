import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
// import "./sec1.css";
// import SubSwitch from "../../../../../Components/buttons/toggle/subSwitch";
import Mains from "../../../../../components/buttons/toggle/Mains";
import "./Mains.css";
import { Table } from "react-bootstrap";
import { useDrag } from "../../../../../contexts/drag/Button";
import { useGetApi } from "../../../../../components/api/UseGetApi";
import { ThreeDots } from "react-loader-spinner";
import  HeadingUtils  from "../../../../../components/utils/headingUtils";

const ExpandCollapseCard = ({ title, content, headerButtons }) => {
  const [open, setOpen] = useState(false);
  const { drag } = useDrag();

  const toggleCard = () => {
    setOpen(!open);
  };

  return (
    <Card
      className="shadow-sm"
      style={{
        cursor: "pointer",
        marginBottom: "20px",
        borderRadius: "18px",
        border: "none",
      }}
    >
      <Card.Header
        onClick={toggleCard}
        className="d-flex justify-content-between align-items-center"
        // id="abcd-container"
        id={drag ? "abcd-container" : "header"}
      >
        <Card.Title id="title">{title}</Card.Title>
        <div className="ml-auto">{headerButtons}</div>
      </Card.Header>
      <Collapse in={open}>
        <div id="collapse-card-content">
          <Card.Body
            id="cardBody"
            style={{ borderTop: "1px solid black", overflow: "scroll" }}
          >
            {content}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
};

const App = ({ locId }) => {
  const { dashboardHeadingUtils } = HeadingUtils();
  const [mainsData, setMainsData] = useState([]);
  const [voltageTableData, setVoltageTableData] = useState([]);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [powerTableData, setPowerTableData] = useState([]);
  const [dcPowerBus, setdcPowerBus] = useState([]);
  const [meterData, setMeterData] = useState([]);
  const [mainsStatus, setMainsStatus] = useState(false);

  const {
    getData: mainData,
    isLoading,
    error,
  } = useGetApi(`bess/mains-data/${locId}`);

  useEffect(() => {
    setMainsData(mainData);
  }, [mainData]);

  useEffect(() => {
    if (mainsData?.data?.power_data?.voltage) {
      const voltageData = mainsData.data.power_data.voltage;
      const tableData = Object.entries(voltageData).map(([key, value]) => ({
        key,
        value,
      }));
      setVoltageTableData(tableData);
    }
    if (mainsData?.data?.power_data?.current) {
      const currentData = mainsData.data.power_data.current;
      const tableData = Object.entries(currentData).map(([key, value]) => ({
        key,
        value,
      }));
      setCurrentTableData(tableData);
    }
    if (mainsData?.data?.power_data?.power_factor) {
      const powerData = mainsData.data.power_data.power_factor;
      const tableData = Object.entries(powerData).map(([key, value]) => ({
        key,
        value,
      }));
      setPowerTableData(tableData);
    }
    if (mainsData?.data?.dc_power_bus) {
      const dc_power_bus = mainsData.data.dc_power_bus;
      const tableData = Object.entries(dc_power_bus).map(([key, value]) => ({
        key,
        value,
      }));
      setdcPowerBus(tableData);
    }

    setMeterData(mainsData?.data?.kva_h);
    setMainsStatus(mainsData?.data?.mains_status);
  }, [mainsData]);

  const cardData = [
    {
      title: (
        <div className="d-flex" id="mains-head">
          {dashboardHeadingUtils?.CARD_A}
          <Mains mainsStatus={mainsStatus} />
        </div>
      ),
      content: (
        <>
          {error?.response?.data?.status === "failed" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p style={{ color: "black", margin: "0px", fontSize: "12px" }}>
                No data available.
              </p>
            </div>
          ) : (
            <>
              <div
                id="mains-card"
                className="d-flex"
                style={{
                  padding: "10px 20px 10px 20px",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                {isLoading ? (
                  <div className="loading">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#00a79d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <>
                    <div id="mains-table1">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p style={{ fontWeight: "500" }}>Mains Status</p>
                      </div>

                      <Table
                        bordered
                        style={{
                          fontSize: "14px",
                          border: "1px solid #6f6f6f",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="headBorder">Phase</th>
                            <th className="headBorder">R</th>
                            <th className="headBorder">Y</th>
                            <th className="headBorder">B</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="leftBorder">Voltage</td>
                            {voltageTableData.map(({ key, value }) =>
                              key.startsWith("v_r") ? (
                                <td key={key}>{value}V</td>
                              ) : key.startsWith("v_y") ? (
                                <td key={key}>{value}V</td>
                              ) : key.startsWith("v_b") ? (
                                <td key={key} className="rightBorder">
                                  {value}V
                                </td>
                              ) : null
                            )}
                          </tr>
                          <tr>
                            <td className="leftBorder">Current</td>
                            {currentTableData.map(({ key, value }) =>
                              key.startsWith("i_r") ? (
                                <td key={key}>{value}A</td>
                              ) : key.startsWith("i_y") ? (
                                <td key={key}>{value}A</td>
                              ) : key.startsWith("i_b") ? (
                                <td key={key} className="rightBorder">{value}A</td>
                              ) : null
                            )}
                          </tr>
                          <tr>
                            <td className="leftBorder">Power Factor</td>
                            {powerTableData.map(({ key, value }) =>
                              key.startsWith("pf_r") ? (
                                <td key={key}>{value}</td>
                              ) : key.startsWith("pf_y") ? (
                                <td key={key}>{value}</td>
                              ) : key.startsWith("pf_b") ? (
                                <td key={key} className="rightBorder">{value}</td>
                              ) : null
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div id="mains-table2">
                      <div style={{ marginBottom: "20px" }}>
                        <p style={{ fontWeight: "500" }}>DC Bus Status</p>
                      </div>
                      <Table
                        bordered
                        style={{
                          fontSize: "14px",
                          border: "1px solid #6f6f6f",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td className="leftBorder">Bus Voltage</td>
                            {dcPowerBus.map(({ key, value }) =>
                              key.startsWith("bus_voltage") ? (
                                <td className="rightBorder" key={key}>{value}V</td>
                              ) : null
                            )}
                          </tr>
                          <tr>
                            <td className="leftBorder">Bus Current</td>
                            {dcPowerBus.map(({ key, value }) =>
                              key.startsWith("bus_current") ? (
                                <td className="rightBorder" key={key}>{value}A</td>
                              ) : null
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      ),
      headerButtons: (
        <div className="mains-head_btn">
          <h5 id="mains-head2">
            {dashboardHeadingUtils.CARD_A_SUB(meterData ? meterData : 0)}
          </h5>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      {/* <MapWithAddress /> */}
      <div className="card-container">
        {cardData.map((card, index) => (
          <ExpandCollapseCard
            className="resizable-card-content"
            key={index}
            title={card.title}
            content={card.content}
            headerButtons={card.headerButtons}
          />
        ))}
      </div>
    </div>
  );
};

export default App;
