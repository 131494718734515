import React, { useEffect, useState } from "react";
import "./NewConsumption.css";
import Chart from "../../../../graph/consumptionChart";
import ProgressBar from "../../../../../../components/progressbar/normal/ProgressBar";
import { useGetApi } from "../../../../../../components/api/UseGetApi";

const NewConsumption = ({ consumption, locId, filterData }) => {
  const [filteredData, setFilteredData] = useState([]);
  const progressBarWidth = {
    width: "100%",
  };
  const progressHeight = {
    height: "20px",
  };
  const progressStyleGrid = {
    backgroundColor: "rgb(36, 170, 225)",
    width: "50%",
  };
  const progressStyleGreen = {
    backgroundColor: "rgb(16, 116, 188)",
    width: "50%",
  };
  const progressStyleBess = {
    backgroundColor: "rgb(40, 56, 144)",
    width: "50%",
  };
  const progressStyleExport = {
    backgroundColor: "rgb(36, 170, 225)",
    width: "50%",
  };
  const progressStyleImport = {
    backgroundColor: "rgb(16, 116, 188)",
    width: "50%",
  };
  const progressStyleCharge = {
    backgroundColor: "rgb(40, 56, 144)",
    width: "50%",
  };

  const { getData: consumptionData } = useGetApi(
    `bess/consumptionDataByLocation/${locId}`
  );

  useEffect(() => {
    if (filterData?.months) {
      setFilteredData(Object.values(filterData?.months));
    }
  }, [filterData]);

  const kvah_bess = filteredData.map((monthData) => monthData?.kvah_bess);
  const kvah_grid = filteredData.map((monthData) => monthData?.kvah_grid);
  const kvah_green = filteredData.map((monthData) => monthData?.kvah_Renewable);
  const importValue = filteredData.map((monthData) => monthData?.import);

  return (
    <div>
      <div className="NewConsumption-container">
        <div className="NewConsumptionSec-1">
          <div>
            <Chart
              consumptionData={consumptionData}
              chartType={"Bess"}
              filterData={filterData}
            />
          </div>
          <div>
            <ProgressBar
              label="Grid"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleGrid}
              progressLabel={
                kvah_green[1]
                  ? `0 kVAh`
                  : kvah_bess[1]
                  ? "0 kVAh"
                  : kvah_grid[1]
                  ? kvah_grid[1] + " kVAh"
                  : `${consumption?.totalGrid || 0} kVAh`
              }
            />
            <ProgressBar
              label="Renewable"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleGreen}
              progressLabel={
                kvah_bess[1]
                  ? `0 kVAh`
                  : kvah_grid[1]
                  ? "0 kVAh"
                  : kvah_green[1]
                  ? kvah_green[1] + " kVAh"
                  : `${consumption?.totalGreen || 0} kVAh`
              }
            />
            <ProgressBar
              label="Bess"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleBess}
              progressLabel={
                kvah_green[1]
                  ? `0 kVAh`
                  : kvah_bess[1]
                  ? kvah_bess[1] + " kVAh"
                  : kvah_grid[1]
                  ? "0 kVAh"
                  : `${consumption?.totalBess || 0} kVAh`
              }
            />
          </div>
        </div>
        <div className="NewConsumptionSec-2">
          <div>
            <Chart
              consumptionData={consumptionData}
              chartType={"importExport"}
              filterData={filterData}
            />
          </div>
          <div>
            <ProgressBar
              label="Export"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleExport}
              progressLabel={`${
                consumption?.export ? consumption?.export : 0
              } kVAh`}
            />
            <ProgressBar
              label="Import"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleImport}
              progressLabel={
                importValue[1]
                  ? `${importValue[1]} kVAh`
                  : `${consumption?.import} kVAh`
              }
            />
            <ProgressBar
              label="Charge"
              progressBarWidth={progressBarWidth}
              progressHeight={progressHeight}
              progressStyle={progressStyleCharge}
              progressLabel={`${
                consumption?.charge ? consumption?.charge : 0
              } kVAh`}
            />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default NewConsumption;
