import { useGetApi } from "../../components/api/UseGetApi";
import Chat from "./chat/Chats";

const DetailedPage = ({ message, activeDate, activeId, userId }) => {
  const useDetails = JSON.parse(localStorage.getItem("user"));

  const { getData: userPhoto } = useGetApi(`users/getUserById/${userId}`);

  const profilePic1 =
    userPhoto?.image ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

  return (
    <div>
      <div className="detailed-page">
        <>
          <div className="Detailed-head">
            <div className="detailed-profile-head">
              <img
                src={profilePic1}
                alt=""
                style={{ marginRight: "15px" }}
                className="message-pp"
              />
              <div className="msg-profile-head">
                <div>
                  <h5
                    className="m-0 p-0"
                    style={{
                      fontSize: "13px",
                      whiteSpace: "nowrap",
                      padding: "9px",
                    }}
                  >
                    {useDetails.success.name}
                  </h5>
                  <p style={{ fontSize: "11px", margin: "0px" }}>
                    {useDetails.success.email}
                  </p>
                </div>
                <>
                  <p
                    style={{
                      fontSize: "11px",
                      margin: "0px",
                      textAlign: "right",
                    }}
                  >
                    {activeDate}
                  </p>
                </>
              </div>
            </div>

            <div>
              <div className="msg-box">
                <h4 style={{ color: "black", fontSize: "small" }}>
                  {activeId?.messages}
                </h4>
                <p className="m-0" style={{ fontSize: "13px" }}>
                  {message}
                </p>
              </div>
            </div>
          </div>
        </>
        <div className="Detailed-chatBox">
          <Chat activeId={activeId.id} userId={useDetails.success.userId} />
        </div>
      </div>
    </div>
  );
};

export default DetailedPage;
