import React, { useState } from "react";
import "../../../modal/MainModal.css";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useEffect } from "react";

const defaultCenter = {
  lat: 21.7679,
  lng: 78.8718,
};

function MyComponent({ setIsAddress, NewPin, setPosition, position }) {
  const [mapZoom, setMapZoom] = useState(5);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState();

  console.log("newPin", NewPin);
  console.log("positionIN", position);

  // Google Maps API key
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  // console.log("position", position);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
  });

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  // search location by pinCode
  const handleInputKeyPress = (event) => {
    if (NewPin.length >= 6) {
      geocodeByAddress(NewPin)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setMapZoom(13);
          setMapCenter(latLng);
          setMarkerPosition(latLng); // Set the marker position
        })
        .catch((error) => {
          console.error("Geocoding error: " + error);
          alert("Location not found.");
        });
    }
  };
  useEffect(() => {
    if (NewPin) {
      handleInputKeyPress();
    }
    if (position) {
      handlePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NewPin]);

  const handlePosition = () => {
    setMapZoom(12);
    setMapCenter({
      lat: parseFloat(position.latitude),
      lng: parseFloat(position.longitude),
    });
    setMarkerPosition({
      lat: parseFloat(position.latitude),
      lng: parseFloat(position.longitude),
    });
  };

  // drag and drop marks
  const handleMarkerDragEnd = (event) => {
    const newMarkerPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(newMarkerPosition);
  };

  useEffect(() => {
    if (markerPosition) {
      setPosition(markerPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPosition]);

  // getting mark location
  const handleMarkLocation = (props, e) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: markerPosition }, (results, status) => {
      if (status === "OK" && results[0]) {
        const formattedAddress = results[0].formatted_address;
        setIsAddress(formattedAddress);
      } else {
        setIsAddress("Error fetching address");
      }
    });
  };
  // loading
  if (!isLoaded) return <div>Loading...</div>;

  // console.log("markerPosition", markerPosition);

  return (
    <div>
      <GoogleMap
        // mapContainerStyle={mobileContainerStyle}
        mapContainerClassName="map-container"
        center={mapCenter}
        zoom={mapZoom}
        options={options}
      >
        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
            onClick={handleMarkLocation}
          />
        )}
      </GoogleMap>
    </div>
  );
}

export default React.memo(MyComponent);
