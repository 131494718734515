import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Converter.css";

const ConverterColumn = (props) => (
  <Col style={{ paddingRight: "3px", paddingLeft: "3px" }}>
    <div
      className={props.className ? "respo-container_m10" : "respo-container"}
    >
      <div className="responsive-div">
        <h6 style={{ fontSize: "11px" }}>{props.label}</h6>
      </div>
      <div
        className={props.status === "ACTIVE" ? "side-div-active" : "side-div"}
      >
        <h6 style={{ fontSize: "11px" }}>{props.value}</h6>
      </div>
    </div>
  </Col>
);

const converters = (props) => {
  // console.log("props", props.converters);
  return (
    <div>
      <Row style={{ marginBottom: "10px" }} id="converter-row">
        {props.converters?.length === 0 ? (
          <p className="mb-0 text-center font-weight-500">
            {props?.status === "ACTIVE"
              ? "0 Active Converters"
              : " 0 Inactive Converters"}
          </p>
        ) : (
          props.converters?.map((item, index) => (
            <ConverterColumn
              key={index}
              className="respo-container_m10"
              label={`R${item?.converterId}`}
              status={item.status}
              value={`${item?.load}%`}
            />
          ))
        )}
      </Row>
    </div>
  );
};

export default converters;
