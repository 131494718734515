// DragContext.js
import { createContext, useContext, useState } from "react";

const DragContext = createContext();

export function DragProvider({ children }) {
  const [drag, setDrag] = useState(false);

  return (
    <DragContext.Provider value={{ drag, setDrag }}>
      {children}
    </DragContext.Provider>
  );
}

export function useDrag() {
  return useContext(DragContext);
}
