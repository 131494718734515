import React from 'react'
import './ViewAll.css'
import { ReactComponent as CrossArrow } from "../../../assets/images/whiteCrossArrow.svg";

const viewAll = ({ openModal, openSohModal, openBess }) => {
  const handleClick = () => {
    if (openSohModal) {
      openSohModal();
    }
    if (openModal) {
      openModal();
    }
    if (openBess) {
      openBess();
    }
  };
  return (
    <div className='viewAll_wrap'>
      <span id="viewAll" onClick={handleClick} >
        VIEW ALL
        <CrossArrow className="crossArrow" />
      </span>
    </div>
  )
}

export default viewAll

