import React, { useEffect, useRef, useState } from "react";
import "./Chat.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalContent } from "./imgModal";
import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { database } from "../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useGetApi } from "../../../components/api/UseGetApi";

const Chat = ({ activeId }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem("user"));

  // clearing input value
  const inputRef = useRef(null);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = onSnapshot(
      collection(database, "messagesAuth"),
      (querySnapshot) => {
        const messagesArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoading(false);
        setMessages(messagesArray);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const HandleInput = (e) => {
    setMessage(e.target.value);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImage(URL.createObjectURL(selectedFile));
  };

  const timeStamp = Date.now();

  const sendMessage = async () => {
    if (message.trim() !== "" || file) {
      try {
        let fileURL = null;
        if (file) {
          const storage = getStorage();
          const storageRef = ref(storage, `files/${timeStamp}`);
          await uploadBytes(storageRef, file);
          fileURL = await getDownloadURL(storageRef);
        }

        await addDoc(collection(database, "messagesAuth"), {
          text: message,
          sender: userDetails.success.email,
          timestamp: serverTimestamp(),
          fileURL: fileURL,
          ...(file && { fileType: file.type, fileName: timeStamp }),
          notificationId: activeId,
        });

        setMessage("");
        setFile(null);
        setImage(null);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const { getData: userPhoto } = useGetApi(
    `users/getUserById/${userDetails?.success?.userId}`
  );

  const profilePic2 =
    userPhoto?.image ||
    "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80";

  const profilePic1 =
    userPhoto?.image ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
  // console.log("file", file);
  return (
    <div id="body">
      <div className="app-container">
        <div className="app-main">
          <div
            style={{ overflow: "scroll", overflowX: "auto" }}
            ref={(el) => {
              if (el) {
                el.scrollTop = el.scrollHeight;
              }
            }}
          >
            <div className="chat-wrapper">
             

              {loading ? (
                <>
                  <div className="msg-loading" id="msg-loading">
                    <h6 style={{ fontSize: "8px" }}>Loading msg.....</h6>
                  </div>
                </>
              ) : (
                <>
                  {messages
                    .filter((msg) => msg.notificationId === activeId)
                    .slice()
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map((msg) => (
                      <Message
                        key={msg.id}
                        imgSrc={
                          msg.sender === userDetails.success.email
                            ? profilePic1
                            : profilePic2
                        }
                        content={msg.text}
                        timestamp={msg.timestamp}
                        user={msg.sender}
                        reverse={msg.sender === userDetails.success.email}
                        file={msg.fileURL}
                        pdf={msg}
                      />
                    ))}
                </>
              )}
            </div>
          </div>

          {image && (
            <>
              <div className="image-view-wrapper">
                <button
                  onClick={() => setImage(null)}
                  className="close-btn__chat"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                {file.type === "application/pdf" ? (
                  <div className="pdf-wrapper">
                    <span className="pdf-icon"></span>
                    <p style={{ cursor: "pointer" }}>{file.name}</p>
                  </div>
                ) : (
                  <img
                    src={image}
                    style={{ width: "30%" }}
                    className="chat-image"
                    alt="trail-image_view"
                  />
                )}
              </div>
            </>
          )}

          <div
            className="chat-input-wrapper"
            style={{
              position: "sticky",
              bottom: "0",
            }}
          >
            <button className="chat-attachment-btn" onClick={HandleInput}>
              <label htmlFor="file-input">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faPaperclip}
                />
              </label>
              <input id="file-input" onChange={handleFileChange} type="file" />
            </button>

            <div className="input-wrapper">
              <input
                type="text"
                className="chat-input"
                placeholder="Enter your message here"
                onChange={HandleInput}
                value={message}
                ref={inputRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && message.trim().length > 0) {
                    // HandleSend();
                    sendMessage();
                  }
                }}
              />
            </div>
            {/* <button onClick={HandleSend} className="chat-send-btn"> */}
            <button onClick={sendMessage} className="chat-send-btn">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Message = ({ imgSrc, content, timestamp, reverse, user, file, pdf }) => {
  const [isOpen, setIsopen] = useState(false);
  const [image, setImage] = useState(null);
  const showModal = () => setIsopen((prev) => !prev);

  const formattedDate = new Date(
    (timestamp?.seconds || 0) * 1000 + (timestamp?.nanoseconds || 0) / 1000000
  ).toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  // console.log("pdf", pdf);

  const downloadImage = () => {
    const a = document.createElement("a");
    a.href = image;
    a.download = Date.now();
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    setImage(null);
  };

  return (
    <>
      <div className={`message-wrapper ${reverse ? "reverse" : ""}`}>
        <img className="message-pp" src={imgSrc} alt="profile-pic" />
        <div className="message-box-wrapper d-flex flex-column">
          <div>
            {file ? (
              pdf.fileType === "application/pdf" ? (
                <div className="d-flex flex-column align-items-center p-2">
                  <a
                    href={pdf.fileURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="pdf-icon"></span>
                  </a>
                  <p
                    style={{
                      cursor: "pointer",
                      margin: "0px",
                      padding: "3px",
                      fontSize: "small",
                    }}
                  >
                    {pdf.fileName}
                  </p>
                </div>
              ) : (
                <>
                  <Modal onOpen={showModal}>
                    <div className={`holder ${reverse ? "reverse" : ""}`}>
                      <img
                        className="img-attachment"
                        src={file}
                        alt="images"
                        style={{ border: "2px solid black", cursor: "pointer" }}
                        onClick={() => setImage(file)}
                      />
                    </div>
                  </Modal>
                </>
              )
            ) : (
              <div className="message-box d-flex flex-column align-items-start">
                <p
                  className="m-0 p-0 "
                  style={{
                    overflowWrap: "anywhere",
                    fontSize: "10px",
                    color: "darkgray",
                  }}
                >
                  {user}
                </p>
                <p className="m-0 p-0" style={{ overflowWrap: "anywhere" }}>
                  {content}
                </p>
              </div>
            )}
          </div>
          <span className="message-time">{formattedDate}</span>
        </div>
      </div>

      {isOpen && (
        <ModalContent
          onClose={() => setIsopen(false)}
          onDownload={downloadImage}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "60%" }} src={file} alt="" />
          </div>
        </ModalContent>
      )}
    </>
  );
};

export default Chat;
  