// custom tooltip for location 
import React from "react";
import { useNavigate } from "react-router-dom";
import CrossArrow from "../../assets/images/topCrossArrow.svg";
import eye from "../../assets/images/visibility.svg";
import GreenCircle from "../../assets/images/greenCircle.svg";
import redCircle from "../../assets/images/red.svg";
import warningCircle from "../../assets/images/warningCircle.svg";
import { ReactComponent as Border } from "../../assets/images/tooltipBorder.svg";
import { ReactComponent as Close } from "../../assets/images/cross.svg";
import "./Search.css";

const CustomInfoWindow = ({ selectedMarker, markerId, closeButton }) => {
  const getStatusIcon = () => {
    switch (selectedMarker.currentStatus) {
      case "All Good":
        return GreenCircle;
      case "Needs Attention":
        return warningCircle;
      default:
        return redCircle;
    }
  };

  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    const data = { markerId };
    sessionStorage.setItem("markerId", JSON.stringify(data));
    navigate("/dashboard", { state: { markerId } });
  };

  return (
    <div>
      <div>
        <p className="infoWindow-Status-Name">
          <img
            src={CrossArrow}
            className="infoWindow-ArrowButton"
            alt="CrossArrow"
            onClick={handleNavigateToDashboard}
          />
          <img
            src={getStatusIcon()}
            className="infoWindow-StatusIcon"
            alt="StatusIcon"
          />
          {selectedMarker.currentStatus.toUpperCase()}
        </p>
        <p className="infoWindow-LocationName">
          <img src={eye} className="infoWindow-eye" alt="EyeIcon" />
          {selectedMarker.location_name ? selectedMarker.location_name : "..."}
          <br />
        </p>
        <Close
          onClick={() => closeButton(null)}
          className="infoWindow-InfoIcon_xmark"
          // icon={faXmark}
        />
      </div>
      <Border className="corner-border-icon" />
    </div>
  );
};

export default CustomInfoWindow;
