// This is the main home page container for all the cards and containers
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import BottomReadings from "../../components/cards/FooterReadings";
import SortableItem from "./Sortable";
import Noti from "../notification/Notification";
import Sidebar from "../../components/sidebar/Sidebar";
import "./css/app.css";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Container from "./Containers";
import { Item } from "./Sortable";
import { useLocation } from "react-router";

const defaultAnnouncements = {
  onDragStart(id) {
    // console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      // console.log(
      //   `Draggable item ${id} was moved over droppable area ${overId}.`
      // );
      return;
    }
  },
  onDragEnd(id, overId) {
    if (overId) {
      // console.log(
      //   `Draggable item ${id} was dropped over droppable area ${overId}`
      // );
      return;
    }

    // console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    // console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  },
};
export default function App() {
  const location = useLocation();
  const markerId = location.state?.markerId;
  const [isExpanded, setIsExpanded] = useState(false);

  // Load component order from local storage on initial render
  const initialRootOrder = JSON.parse(localStorage.getItem("rootOrder")) || [
    // "map",
    "mains",
    "converters",
    "cabinet",
    "bess",
    "cycles",
  ];
  const initialContainerOrder = JSON.parse(
    localStorage.getItem("containerOrder")
  ) || [
    // "gauge",
    "client",
    "consumption",
    "power",

    // "support",
  ];

  // Update component order in local storage whenever it changes
  const [items, setItems] = useState({
    root: initialRootOrder,
    container1: initialContainerOrder,
  });
  const [activeId, setActiveId] = useState();

  // Use DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Update local storage whenever root or container order changes
  useEffect(() => {
    localStorage.setItem("rootOrder", JSON.stringify(items.root));
    localStorage.setItem("containerOrder", JSON.stringify(items.container1));
  }, [items.root, items.container1]);

  // Load component data from local storage on initial render
  const initialComponentData =
    JSON.parse(localStorage.getItem("componentData")) || {};

  // Loop through components and set initial order and transform
  const initialItems = Object.keys(initialComponentData).map((id) => ({
    id,
    order: initialComponentData[id]?.order || 0,
    transform: initialComponentData[id]?.transform || null,
  }));

  // Toggle sidebar for small screens
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        {initialItems.map((item) => (
          <SortableItem key={item.id} id={item.id} />
        ))}
      </div>
      <div className="bodyStyle">
        <div id="searchbar-card_m10">
          <div className="search-bar-container_m10">
            {/* <Slider mainClass="index-custom-bottomCard-location" subClass="locName-custom-button-expand" markerId={location.state}/> */}
            <Sidebar
              isExpanded={isExpanded}
              buttonfn={toggleSidebar}
              sidebar="sidebar"
              sidebarHome="sidebarHome"
            />
            <div className="sidebarButton__home">
              <FontAwesomeIcon
                className="expand-btn_home"
                icon={faBars}
                onClick={toggleSidebar}
              />
            </div>
            <input
              type="text"
              placeholder="Search..."
              // value={searchInput}
              // onChange={handleInputChange}
              className="location-search-input_home"
            />

            <div id="search-notification">
              <Noti />
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          {/* <SortableItem drag={drag} /> */}
        </div>
        <div className="home-container-drag">
          <DndContext
            announcements={defaultAnnouncements}
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            // onSingle={handleSingleClick}
          >
            <Container id="root" items={items.root} markerId={markerId?.id} />
            <Container
              id="container1"
              items={items.container1}
              markerId={markerId?.id}
            />
            <DragOverlay>{activeId && <Item id={activeId} />}</DragOverlay>
          </DndContext>
        </div>
      </div>

      <div
        className="homeScreen-bottom__cardDIv"
        style={{ position: "sticky", bottom: "0px" }}
      >
        <BottomReadings markerId={markerId} locId={markerId?.id} />
      </div>
    </>
  );

  // Helpers
  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  // Event handlers
  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  // Event handlers
  function handleDragOver(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    // Insert the item
    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over && overIndex === overItems.length - 1;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  // Event handlers
  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;

    const activeContainer = findContainer(id);

    if (!over) {
      setActiveId(null);
      return;
    }

    const { id: overId } = over;
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      setActiveId(null);
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveId(null);
  }
}
