import { useEffect } from "react";

const useLoggedInToken = () => {
  console.log("logging out");
  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.removeItem("loggedIn");
      window.location.reload();
    }, 86400000); // 1 day
    return () => clearTimeout(timer);
  }, []);
};

export default useLoggedInToken;









// import { useEffect } from "react";

// const useLoggedInToken = () => {
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       const remainingTime = 76400000 - performance.now();
//       console.log(`Logging out in ${remainingTime}ms`);
//       localStorage.removeItem("loggedIn");
//       window.location.reload();
//     }, 76400000); // 1 day
//     return () => clearTimeout(timer);
//   }, []);
// };
// export default useLoggedInToken;

