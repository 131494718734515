// usePutData.js
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const useFetchData = (apiPara, datas, showSuccessAlert) => {
  const [mainData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState("");

  const PostData = async (id) => {
    const EMS_API_KEY = process.env.REACT_APP_EMS_API_KEY; //API key


    const userDetails = JSON.parse(localStorage.getItem("user"));
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${EMS_API_KEY}${apiPara ? `/${apiPara}` : ""}${
          id?.id ? `/${id?.id}` : ""
        }`,
        datas,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userDetails.success.token}`,
          },
        }
      );
      setData(response.data);
      setStatus(response);
      const showSuccessAlert =
        (id?.showSuccessAlert || datas.showSuccessAlert) !== "false";
      if (showSuccessAlert) {
        Swal.fire({
          icon: "success",
          title: "Success",

          text: datas.message ? datas.message : "Updated Successfully",
          preConfirm: () => {
            if (datas.fun) {
              datas.fun();
            }
          },
        });

        setUpdate("updated");
      }
      const toastOptions = {
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      };
      const showToast = datas?.toast === true;
      showToast &&
        Swal.fire({
          ...toastOptions,
          icon: "success",
          title: datas.message || "Updated Successfully",
        });
    } catch (error) {
      // setError(error);
      // const showSuccessAlert =
      //   (id?.showSuccessAlert || datas.showSuccessAlert) !== "false";
      // if (showSuccessAlert) {
      if (showSuccessAlert) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }

      // }
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { mainData, isLoading, error, PostData, status, update };
};

export default useFetchData;
