import React, { useEffect, useState } from "react";
import NestedModal from "../../modal/MainModal";
import { useForm } from "react-hook-form";
import BtnSave from "../../../../components/buttons/saveContinue/ButtonSave";
import LocMap from "../../addPart/content/location/LocationMap";
import "./Location.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useGetApi } from "../../../../components/api/UseGetApi";
import usePutApi from "../../../../components/api/UsePutApi";
import useFetchData from "../../../../components/api/UseDeleteApi";
import { numberValidation } from "../../../../components/validations/utils";
import { SpecialCharacterValidation } from "../../../../components/validations/utils";
import CustomTable from "../../../../components/table/CustomTable";

const locationColumns = [
  { id: "id", label: "Location Id", key: "sno" },
  {
    id: "location_name",
    label: "Location Name",
    key: "location_name",
  },
  {
    id: "address",
    label: "Address",
    key: "address",
    className: "LocAddress",
  },
  {
    id: "pincode",
    label: "Pin Code",
    key: "pincode",
  },

  {
    id: "currentStatus",
    label: "Current Status",
    key: "currentStatus",
  },
];
const InputField = ({ id, label, register, error, errorMessage, ...rest }) => (
  <>
    <input
      //   id="zoomed-input-user"
      className={id ? "zoomed-input" : ""}
      style={{ width: "100%" }}
      {...register}
      {...rest}
      placeholder={`${rest.placeholder}*`}
    />
    {error && (
      <p
        role="alert"
        style={{ color: "red", marginBottom: "0px", textAlign: "left" }}
      >
        {errorMessage}
      </p>
    )}
  </>
);

const Location = (props) => {
  const [locationData, setLocationData] = useState([]);
  const [isUserName, setUserName] = useState([]);
  const [isAddress, setIsAddress] = useState("");
  const [position, setPosition] = useState();
  const [locationError, setLocationError] = useState({});
  const [locationValue, setLocationValue] = useState({
    location_name: "",
    address: "",
    pincode: "",
    site_manager_id: "",
    email: "",
    phone: "",
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [state, setState] = useState({
    tenantData: [],
    userData: [],
    tenantLocData: [],
    userName: [],
    locData: [],
    activeId: null,
    isOpen: false,
    isBtnSaveVisible: false,
    search: "",
    tenantLocformData: {},
    locFormData: {},
    selectedManager: {},
  });
  const { activeId, isOpen, selectedManager } = state;

  // get Location Data
  const { getData: LocDatas, isLoading } = useGetApi("location/getAll");
  const { getData: userNames } = useGetApi("users/getAllByOrganization/2");

  // delete location
  const { DeleteData } = useFetchData("location/delete", {
    // rowId: rowId,
    message: "location Successfully deleted!",
  });

  useEffect(() => {
    setLocationData(LocDatas);
    setUserName(userNames);
  }, [LocDatas, userNames]);

  const handleDelete = async (id) => {
    try {
      // Call the DeleteData function from the hook
      const confirmed = await DeleteData(id);

      if (confirmed) {
        // Update state after deletion
        setLocationData(locationData.filter((item) => item.id !== id));
      } else {
        // Deletion canceled
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleOpenClick = (rowId) => {
    setState((prevState) => ({
      ...prevState,
      isOpen: true,
      activeId: rowId,
      isBtnSaveVisible: true,
    }));
    setLocationValue({
      pincode: rowId?.pincode,
      address: rowId?.address,
      location_name: rowId?.location_name,
      siteManagerName: rowId?.firstname,
      email: rowId?.email,
      phone: rowId?.phone,
      site_manager_id: rowId?.site_manager_id,
    });

    setPosition(rowId);
  };

  const handleLocChange = (e) => {
    const { name, value } = e.target;
    const idKey = name === "id";
    let selectedManager = null;

    let updates = {
      [name]: value,
    };

    if (name === "managerName") {
      selectedManager = isUserName.find((user) => user.firstname === value);
      if (selectedManager) {
        updates = {
          ...updates,
          email: selectedManager.email,
          phone: selectedManager.phone,
          [idKey]:
            e.target.options[e.target.selectedIndex].getAttribute("data-id"),
          site_manager_id:
            e.target.options[e.target.selectedIndex].getAttribute("data-id"),
          siteManagerName: selectedManager?.firstname || "",
        };
      }
    }

    setLocationValue((prevState) => ({
      ...prevState,
      ...prevState.selectedManager,
      // siteManagerName: selectedManager?.firstname || "",
      ...updates,
    }));
  };

  useEffect(() => {
    if (isAddress)
      setLocationValue({
        ...locationValue,
        address: isAddress,
        // siteManagerName: selectedManager?.firstname || "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddress]);

  const { PostData: postDataApi, error } = usePutApi(
    `location/update/${activeId?.id}`,
    {
      site_manager_id: locationValue?.site_manager_id,
      address: locationValue?.address,
      pincode: locationValue?.pincode,
      latitude: position && parseFloat(position.lat),
      longitude: position && parseFloat(position.lng),
      managerName: locationValue?.managerName,
      location_name: locationValue?.location_name,
      organization_id: "2",
      fun: () => handleGoBackClick(),
    }
  );

  useEffect(() => {
    setLocationError(error?.response?.data?.error);
    console.log("isError", locationError);
  }, [error, locationError]);

  const handleGoBackClick = () => {
    reset();
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      isBtnSaveVisible: false,
    }));
    // setLocationData((prevData) =>
    //   prevData?.map((item) =>
    //     item.id === activeId?.id
    //       ? { ...item, ...locationValue, ...position, ...selectedManager }
    //       : item
    //   )
    // );
  };

  const onSubmit = async (data) => {
    try {
      // Call the DeleteData function from the hook
      const success = await postDataApi();

      if (success) {
        handleGoBackClick();
        // props.closeModal();
        console.log("props", props);
      } else {
        setLocationData((prevData) =>
          prevData?.map((item) =>
            item.id === activeId?.id
              ? { ...item, ...locationValue, ...position, ...selectedManager }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div>
      <NestedModal
        modalSize="xl"
        customWidth="50%"
        Mheader={
          <>
            {/* <ButtonLoc name={props.selectedModal.name} /> */}
            <h5
              id="dropdown-modal-title"
              style={{ marginLeft: "20px", padding: "15px" }}
            >
              {props.selectedModal.title}
            </h5>
          </>
        }
        showModal={props.showModal}
        closeModal={props.closeModal}
        Mbody={
          props.selectedModal.name === "LOCATION" && (
            <>
              <>
                {!isOpen && (
                  <CustomTable
                    columns={locationColumns}
                    data={locationData}
                    handleOpenClick={handleOpenClick}
                    handleDeleteClick={handleDelete}
                    isLoading={isLoading}
                  />
                )}
                {isOpen && (
                  <>
                    <div className="Location-container_main">
                      <div className="location-input_container">
                        <button
                          onClick={handleGoBackClick}
                          className="user-back-btn"
                          style={{ top: "10px", left: "10px" }}
                        >
                          <FontAwesomeIcon title="Go Back" icon={faArrowLeft} />
                        </button>
                        <form id="my-form" onSubmit={handleSubmit(onSubmit)}>
                          <InputField
                            id="pincode"
                            label="pincode"
                            name="pincode"
                            type="text"
                            maxLength={6}
                            minLength={6}
                            value={locationValue?.pincode}
                            onChange={handleLocChange}
                            register={register("pincode", {
                              required: "pincode is required",
                            })}
                            error={errors.pincode || locationError?.pincode}
                            errorMessage={
                              errors.pincode?.message || locationError?.pincode
                            }
                            placeholder="Pincode"
                            onKeyDown={numberValidation}
                          />
                          <textarea
                            rows="4"
                            cols="40"
                            id="address"
                            name="address"
                            {...register("address", {
                              required: isAddress
                                ? false
                                : "Address is required",
                              // shouldUnregister: true,
                            })}
                            value={locationValue?.address}
                            className="zoomed-input"
                            // onChange={(e) => setIsAddress(e.target.value)}
                            onChange={handleLocChange}
                            placeholder="Address*"
                          />
                          {errors.address && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {errors?.address?.message ||
                                "Address is required"}
                            </p>
                          )}
                          {locationError?.address && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {errors?.address}
                            </p>
                          )}
                          <InputField
                            id="location_name"
                            label="location_name"
                            name="location_name"
                            value={locationValue.location_name}
                            register={register("location_name", {
                              required: "location_name is required",
                            })}
                            error={
                              errors.location_name ||
                              locationError?.location_name
                            }
                            errorMessage={
                              errors.location_name?.message ||
                              locationError?.location_name
                            }
                            onChange={handleLocChange}
                            placeholder="Location Name"
                            onKeyDown={SpecialCharacterValidation}
                          />

                          <select
                            {...register("managerName", {
                              required: "manager name is required",
                            })}
                            name="managerName"
                            onChange={handleLocChange}
                            className="zoomed-input"
                            style={{
                              fontSize: "16px",
                              paddingTop: "11px",
                              paddingBottom: "11px",
                              width: "-webkit-fill-available",
                            }}
                          >
                            <option value="" disabled>
                              Site Manager*
                            </option>
                            <option>{locationValue?.siteManagerName}</option>

                            {isUserName?.map((item) => (
                              <option
                                key={item.id}
                                value={item.firstname}
                                data-id={item?.id}
                              >
                                {item.firstname}
                              </option>
                            ))}
                          </select>
                          {errors.managerName && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {errors.managerName.message ||
                                "manager name is required"}
                            </p>
                          )}
                          {locationError?.site_manager_id && (
                            <p style={{ color: "red", marginBottom: "0px" }}>
                              {locationError?.site_manager_id
                                ? "manager name is required"
                                : ""}
                            </p>
                          )}

                          <InputField
                            id="email"
                            label="email"
                            name="email"
                            style={{ backgroundColor: "rgb(204 198 198)" }}
                            value={locationValue?.email}
                            // {...register("email", {
                            //   required: "email is required",
                            //   // shouldUnregister: true,
                            // })}
                            error={errors.email || locationError?.email}
                            errorMessage={
                              errors.email?.message || locationError?.email
                            }
                            onChange={handleLocChange}
                            placeholder="Email Id"
                            readOnly
                          />
                          <InputField
                            id="phone"
                            label="phone"
                            name="phone"
                            type="number"
                            style={{ backgroundColor: "rgb(204 198 198)" }}
                            value={locationValue?.phone}
                            // {...register("phone", {
                            //   required: "phone is required",
                            //   // shouldUnregister: true,
                            // })}
                            error={errors.phone || locationError?.phone}
                            errorMessage={
                              errors.phone?.message || locationError?.phone
                            }
                            onChange={handleLocChange}
                            placeholder="Phone Number"
                            readOnly
                          />
                        </form>
                      </div>
                      <div id="location-map_container">
                        <LocMap
                          position={position}
                          NewPin={locationValue.pincode}
                          setIsAddress={setIsAddress}
                          setPosition={setPosition}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            </>
          )
        }
        modalFooter={
          props.selectedModal.footer && (
            <>
              {props.selectedModal.name === "LOCATION" && (
                <>
                  {isOpen && (
                    <BtnSave
                      form="my-form"
                      name="SAVE"
                      onClickFunction={handleSubmit(onSubmit)}
                    />
                  )}
                </>
              )}
            </>
          )
        }
      />
    </div>
  );
};

export default Location;
